import React, { Component } from "react";
import { Button } from "antd";

import {
  DownOutlined,
  AppstoreOutlined,
  OneToOneOutlined,
  BankOutlined,
  UserOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
type Props = {
  exportToPNG: Function;
};

export default function ExportButton(props: Props) {
  return (
    <Button
      type="primary"
      size="large"
      onClick={() => {
        const imgBuff = props.exportToPNG();
        if (imgBuff !== undefined && imgBuff !== null) {
          var blob = new Blob([imgBuff], { type: "image/png" });
          var url = URL.createObjectURL(blob);
          var aDownloadLink = document.createElement("a");
          aDownloadLink.download = "download_" + Date.now() + ".png";
          aDownloadLink.href = url;
          aDownloadLink.click();
        }
      }}
    >
      <DownloadOutlined /> Export
    </Button>
  );
}
