import { Layer, LayerType } from "./Layer";
import Image, { ImageFile } from "../Graphic/Image";

class Dropdown extends Layer {
  type: LayerType = LayerType.Dropdown;

  constructor(docId: string, newName: string = "New Dropdown") {
    super(docId, newName);
  }

  // selectedId(newSelectedId: string) {
  //   for (let i = 0; i < this.graphics.length; i++) {
  //     if (newSelectedId === this.graphics[i].id) {
  //       this.graphics[i].visible = true;
  //       this.selectedId = newSelectedId;
  //     } else {
  //       this.graphics[i].visible = false;
  //     }
  //   }
  // }
  // get selectedId(): string {
  //   return this._selectedId;
  // }
}

type ImageData = { url: string; width: number; height: number };
export function createDropdownListFromFileList(
  newFileList: Array<File>,
  docId: string,
  useFullPath: Boolean = false,
  data: ImageData[] = []
): Array<Dropdown> {
  let dropList: Array<Dropdown> = [];
  for (let i = 0; i < newFileList.length; i++) {
    let fileName: Array<string> = [];

    //Load Filepath
    if (useFullPath) {
      fileName = (newFileList[i] as any).fullPath.split("/");
      if (fileName[0] === "") {
        fileName.shift();
      }
    } else {
      fileName = (newFileList[i] as any).webkitRelativePath.split("/");
    }

    //If is an image w/ no folder
    if (fileName.length === 1) {
      const child = new ImageFile(newFileList[i], 0, 0, true);
      add(child, data[i]);
      const parent = new Dropdown(docId, fileName[0]);
      parent.addGraphic(child);
      dropList.push(parent);
    }
    //If is an image w/ folder
    if (fileName.length > 1) {
      const findIndex = dropList.findIndex(
        (obj) => obj.name === fileName[fileName.length - 2]
      );
      if (findIndex >= 0) {
        // Folder/Layer exists
        const child = new ImageFile(newFileList[i], 0, 0);
        add(child, data[i]);
        dropList[findIndex].addGraphic(child);
      } else {
        //New Folder
        const child = new ImageFile(newFileList[i], 0, 0, true);
        add(child, data[i]);
        const parent = new Dropdown(docId, fileName[fileName.length - 2]);
        parent.addGraphic(child);
        dropList.push(parent);
      }
    }
  }
  function add(graphic: Image, data: ImageData) {
    if (data) {
      Object.assign(graphic, {
        ...data,
        srcWidth: data.width,
        srcHeight: data.height,
      });
    }
  }
  return dropList;
}
export default Dropdown;
