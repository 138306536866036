import Graphic from "./Graphic";

class Text extends Graphic {
  fontSize: number;
  fontFamily: string;
  vAlign: string;
  hAlign: string;
  lineHeight: number;
  letterSpacing: number;
  topOffset: number = 0;
  color: number[] = [0, 0, 0, 1];
  type: string = "text";
  bold: boolean = false;
  italic: boolean = false;

  constructor(
    value: string = "Text",
    x: number = 100,
    y: number = 100,
    fontSize: number = 100,
    fontFamily: string = "Roboto Medium",
    width: number = 400,
    height: number = 250,
    vAlign: string = "top",
    hAlign: string = "left",
    lineHeight: number = 100,
    letterSpacing: number = 0
  ) {
    super();
    this.value = value;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.fontSize = fontSize;
    this.fontFamily = fontFamily;
    this.vAlign = vAlign;
    this.hAlign = hAlign;
    this.lineHeight = lineHeight;
    this.letterSpacing = letterSpacing;
  }

  setColor(r: number, g: number, b: number, a: number) {
    this.color = [r, g, b, a];
  }
}

export default Text;
