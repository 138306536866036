import React, { Component, useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { Button, Tooltip, Typography, Modal, Input } from "antd";
import {
  UserOutlined,
  PlusOutlined,
  KeyOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

import { addUser } from "../../redux/userSlice";
import { v4 as uuidv4 } from "uuid";

export default function AddNewAccount() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [password, setPassword] = useState(generatePassword());
  const [username, setUsername] = useState("New User");
  const [notes, setNotes] = useState("");

  const dispatch = useAppDispatch();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(
      addUser({
        id: uuidv4(), //uuidv4
        username: username,
        password: password,
        notes: notes,
        documents: [],
      })
    );
    setIsModalVisible(false);
    setUsername("New User");
    setPassword(generatePassword());
    setNotes("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setUsername("New User");
    setPassword(generatePassword());
    setNotes("");
  };
  return (
    <>
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        onClick={(e) => {
          e.currentTarget.blur();
          showModal();
        }}
      >
        Add new Account
      </Button>

      <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Typography.Title level={5}>Account Name</Typography.Title>
        <Input
          value={username}
          placeholder="Account Name"
          addonBefore={<UserOutlined />}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <Typography.Title level={5}>Password</Typography.Title>
        <Input
          value={password}
          placeholder="Password"
          addonBefore={<KeyOutlined />}
          addonAfter={
            <Tooltip title="Generate New Password">
              <ReloadOutlined
                onClick={() => {
                  setPassword(generatePassword());
                }}
              />
            </Tooltip>
          }
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <Typography.Title level={5}>Account Notes</Typography.Title>
        <Input.TextArea
          value={notes}
          placeholder="Notes go here"
          rows={4}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
      </Modal>
    </>
  );
}

function generatePassword() {
  return Array(10)
    .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
    .map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join("");
}
