import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { ILayer } from "../core/Layer";
import { displayError, getTokenAndId, handleResponseErrors } from "./helpers";

type LayerState = {
  [index: string]: ILayer;
};

const initialState: LayerState = {};

var baseUrl = "/.netlify/functions/";

/*
 * Async
 */

export const addLayer = createAsyncThunk<any, any>(
  "layers/addLayer",
  async (layerToAdd, thunkApi) => {
    const userData = await getTokenAndId();

    const data = {
      ...layerToAdd,
      accountId: userData.id,
      created: Date.now().toString(),
      updated: Date.now().toString(),
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "layer-create", requestOptions);

    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

export const addMultipleLayers = createAsyncThunk<any, any>(
  "layers/addMultipleLayers",
  async (layersToAdd, thunkApi) => {
    const userData = await getTokenAndId();

    const data = {
      layers: layersToAdd,
      accountId: userData.id,
      created: Date.now().toString(),
      updated: Date.now().toString(),
    };

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "layer-create", requestOptions);

    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

export const getLayers = createAsyncThunk<any, string>(
  "layers/getLayers",
  async (docId, thunkApi) => {
    const userData = await getTokenAndId();

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    const response = await await fetch(
      baseUrl + `layer-read?id=${docId}`,
      requestOptions
    );
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

export const deleteLayer = createAsyncThunk<any, [string, string]>(
  "layers/deleteLayer",
  async ([docId, deleteId], thunkApi) => {
    const userData = await getTokenAndId();

    const data = {
      id: deleteId,
      docId,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "layer-delete", requestOptions);

    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return data.id;
  }
);

export const updateLayer = createAsyncThunk<
  any,
  [string, string, keyof ILayer, any]
>(
  "layers/updateLayer",
  async ([docId, layerId, propertyName, propertyValue], thunkApi) => {
    const userData = await getTokenAndId();

    const data = {
      id: layerId,
      docId: docId,
      propertyName,
      propertyValue,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(baseUrl + "layer-update", requestOptions).then(async (response) => {
      if (response.status !== 200) {
        return await handleResponseErrors(thunkApi, response);
      }
    });
    return data;
  }
);

export const updateLayerMultiParams = createAsyncThunk<
  any,
  [string, string, Array<keyof ILayer>, Array<any>]
>(
  "layers/updateLayerMultiParams",
  async ([docId, layerId, propertyName, propertyValue], thunkApi) => {
    const userData = await getTokenAndId();

    const data = {
      id: layerId,
      docId: docId,
      propertyName,
      propertyValue,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(baseUrl + "layer-update", requestOptions).then(async (response) => {
      if (response.status !== 200) {
        return await handleResponseErrors(thunkApi, response);
      }
    });
    return data;
  }
);

/*
 * Reducer
 */
const layersSlice = createSlice({
  name: "layers",
  initialState,
  reducers: {
    updateLayerLocal(state, { payload }) {
      const key = payload.propertyName;
      const value = payload.propertyValue;
      Object.assign(state[payload.id], { [key]: value });
    },
    addLayerLocal(state, { payload }) {
      state[payload.id] = payload;
    },
    addMultipleLayersLocal(state, { payload }) {
      state = payload.map((layer: any) => {
        return (state[layer.id] = layer);
      });
    },
    deleteLayerLocal(state, { payload }) {
      delete state[payload];
    },
    updateLayerMultiParamsLocal(state, { payload }) {
      payload.propertyName.map((name: string, i: number) => {
        Object.assign(state[payload.id], { [name]: payload.propertyValue[i] });
      });
    },
    undoRedo(state, { payload }) {
      Object.assign(state, payload.state);
    },
  },
  extraReducers: (builder) => {
    /*
     * FulFilled
     */
    builder.addCase(addLayer.fulfilled, (state, { payload }) => {
      state[payload.id] = payload;
    });
    builder.addCase(addMultipleLayers.fulfilled, (state, { payload }) => {
      state = payload.map((layer: any) => {
        return (state[layer.id] = layer);
      });
    });
    builder.addCase(getLayers.fulfilled, (state, { payload }) => {
      state = payload.map((layer: any) => {
        return (state[layer.id] = layer);
      });
    });
    builder.addCase(deleteLayer.fulfilled, (state, { payload }) => {
      delete state[payload];
    });
    builder.addCase(updateLayer.fulfilled, (state, { payload }) => {
      const key = payload.propertyName;
      const value = payload.propertyValue;
      Object.assign(state[payload.id], { [key]: value });
    });
    builder.addCase(updateLayerMultiParams.fulfilled, (state, { payload }) => {
      payload.propertyName.map((name: string, i: number) => {
        Object.assign(state[payload.id], { [name]: payload.propertyValue[i] });
      });
    });
    /*
     * Rejected
     */
    builder.addCase(addLayer.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(addMultipleLayers.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(getLayers.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(deleteLayer.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(updateLayer.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(updateLayerMultiParams.rejected, (state, action) => {
      displayError(action.payload as string);
    });
  },
});

/*
 * Selectors
 */

//Select all?
const selectLayers = (state: any) => state.layers;
export const selectAllLayers = createSelector(selectLayers, (entities) =>
  Object.values(entities)
);

export const selectLayerById = createSelector(
  [(state) => state.layers, (state: any, layerId: string) => layerId],
  (items, layerId) => {
    return items[layerId];
  }
);

export const selectLayersById = createSelector(
  [(state) => state.layers, (state: any, layerIds: string[]) => layerIds],
  (layers, layerIds) => {
    return layerIds
      .filter((id) => {
        if (layers[id] !== undefined) {
          return layers[id];
        }
      })
      .map((id) => layers[id]);
  }
);
/*
 * Exports
 */

export const { updateLayerLocal, undoRedo } = layersSlice.actions;
export default layersSlice.reducer;
