import {
  Modal,
  Input,
  InputNumber,
  Typography,
  Divider,
  Row,
  Col,
  Tooltip,
  Button,
  message,
  Space,
  Popconfirm,
  Switch,
  Radio,
} from "antd";
import { useAppDispatch } from "../../redux/hooks";
import { useHistory } from "react-router-dom";
import {
  updateDocumentMultiParams,
  deleteDocument,
  getDocuments,
} from "../../redux/documentSlice";
import {
  updateTemplateMultiParams,
  deleteTemplate,
} from "../../redux/templateSlice";

import { unassignDocument } from "../../redux/userSlice";
import { useState, useEffect } from "react";
import {
  SettingOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { IDocument } from "../../core/Document";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { getTokenAndId } from "../../redux/helpers";

const { Title } = Typography;

const Spacer = styled.div`
  width: 100%;
  height: 40px;
`;

const SpacerSmall = styled.div`
  width: 100%;
  height: 10px;
`;
const TitleHolder = styled.div`
  width: 100%;
  height: 50px;
  text-align: center;
`;

export default function EditDocumentModal(props: {
  selector: IDocument;
  id: string;
  isTemplate?: boolean;
}) {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [isModalVisible, showModal] = useState(false);
  const [doc, setDoc] = useState({
    name: "",
    width: 0,
    height: 0,
    visibility: true,
  });
  const dispatch = useAppDispatch();
  const history = useHistory();
  useEffect(() => {
    if (props.selector) {
      const visible =
        props.selector.visible !== undefined
          ? props.selector.visible
          : !props.isTemplate;
      setDoc({
        name: props.selector.name,
        width: props.selector.width,
        height: props.selector.height,
        visibility: visible,
      });
    }
  }, [props.selector]);
  return (
    <>
      <Tooltip title="Settings">
        <Button
          type="text"
          shape="circle"
          icon={<SettingOutlined />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget.blur();
            showModal(true);
          }}
        />
      </Tooltip>
      <Modal
        closable={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TitleHolder>
          <Title level={3}>Layout Settings</Title>
        </TitleHolder>

        <Title level={5}>Name</Title>
        <Input
          value={doc.name}
          onChange={(e) => setDoc({ ...doc, name: e.target.value })}
        ></Input>
        <Spacer />
        <Row>
          <Col span={6}>
            <Title level={5}>Width</Title>
            <Input.Group>
              <InputNumber
                value={doc.width}
                onChange={(e) => setDoc({ ...doc, width: e })}
              />
              <span
                className="ant-input-group-addon"
                style={{ padding: "0 12px" }}
              >
                px
              </span>
            </Input.Group>
          </Col>
          <Col span={2}></Col>
          <Col span={6}>
            <Title level={5}>Height</Title>
            <Input.Group>
              <InputNumber
                value={doc.height}
                onChange={(e) => setDoc({ ...doc, height: e })}
              />
              <span
                className="ant-input-group-addon"
                style={{ padding: "0 12px" }}
              >
                px
              </span>
            </Input.Group>
          </Col>
        </Row>
        <Spacer />
        {props.isTemplate ? (
          <>
            <Row>
              <Title level={5}>Show Layout to user?</Title>
              <Col span={2}></Col>
              <Radio.Group
                value={doc.visibility}
                onChange={(e) => setDoc({ ...doc, visibility: e.target.value })}
                options={[
                  { label: "Show", value: true },
                  { label: "Hide", value: false },
                ]}
              />
            </Row>
            <Spacer />
          </>
        ) : (
          <></>
        )}
        <Row>
          <Space size="middle" align="center">
            {!props.isTemplate ? (
              <Button
                loading={duplicateLoading}
                type="primary"
                icon={<CopyOutlined />}
                onClick={async (e) => {
                  await duplicateLayout();
                }}
              >
                Duplicate Layout
              </Button>
            ) : (
              <></>
            )}
            <Popconfirm
              title="Are you sure to delete this Layout? (this is irreversible)"
              cancelText="Cancel"
              okButtonProps={{ danger: true, loading: deleteLoading }}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={async () => {
                setDeleteLoading(true);
                try {
                  if (props.isTemplate) {
                    await dispatch(deleteTemplate(props.id));
                    message.success("Template Deleted");
                    showModal(false);
                    history.push("/templates");
                  } else {
                    await dispatch(unassignDocument(props.id));
                    await dispatch(deleteDocument(props.id));
                    message.success("Layout Deleted");
                    showModal(false);
                    history.push("/dashboard");
                  }
                  setDeleteLoading(false);
                } catch (e) {
                  message.error("Error");
                  setDeleteLoading(false);
                }
              }}
            >
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                Delete Layout
              </Button>
            </Popconfirm>
          </Space>
          <SpacerSmall />
        </Row>
      </Modal>
    </>
  );
  function handleOk() {
    if (props.isTemplate) {
      dispatch(
        updateTemplateMultiParams([
          props.id,
          ["name", "width", "height", "visible"],
          [doc.name, doc.width, doc.height, doc.visibility],
        ])
      );
    } else {
      dispatch(
        updateDocumentMultiParams([
          props.id,
          ["name", "width", "height", "visible"],
          [doc.name, doc.width, doc.height, doc.visibility],
        ])
      );
    }

    showModal(false);
  }
  function handleCancel() {
    showModal(false);
  }

  async function duplicateLayout() {
    try {
      setDuplicateLoading(true);
      const userData = await getTokenAndId();

      /* Screenshot */
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + userData.token);

      /*
       * RequestData
       */
      var raw: string = JSON.stringify({
        source: props.id,
        accountId: userData.id,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      /*
       * Fetch
       */

      const response = await fetch(
        "/.netlify/functions/document-duplicate",
        requestOptions
      );
      if (response.status !== 200) {
        message.error("Error while duplicating layout");
      } else {
        dispatch(getDocuments());
        showModal(false);
        message.success("Layout Duplicated");
      }
      setDuplicateLoading(false);
    } catch (e) {
      setDuplicateLoading(false);
      message.error("Error while duplicating layout");
    }
  }
}
