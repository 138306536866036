import netlifyIdentity from "netlify-identity-widget";

export function isAdmin(): boolean {
  const user = netlifyIdentity.currentUser();
  if (user !== null) {
    if (user.app_metadata) {
      if (Array.isArray(user.app_metadata.roles)) {
        if (user.app_metadata.roles.includes("admin")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}
