import styled from "styled-components";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { Link } from "react-router-dom";
import netlifyIdentity from "netlify-identity-widget";
import {
  Layout,
  Button,
  Space,
  Dropdown,
  Menu,
  Avatar,
  Result,
  Typography,
} from "antd";

const { Title, Text } = Typography;
const { Content } = Layout;

const GroupContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: center;
  gap: 70px;
  padding: 50px;
`;

const Scrollable = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
`;

export default function Profile() {
  const user = netlifyIdentity.currentUser();
  return (
    <>
      <Layout>
        <DashboardHeader />
        <Layout>
          <Content>
            <Scrollable data-simplebar>
              <GroupContainer>
                <Title> Edit Profile</Title>

                <Text
                  editable={{
                    tooltip: "click to edit Name",
                    onChange: editableName,
                  }}
                ></Text>
                <Text></Text>
                <Button> Update Email </Button>
                <Button> Update Password </Button>
              </GroupContainer>
            </Scrollable>
          </Content>
        </Layout>
      </Layout>
    </>
  );
  async function editableName(newName: string) {
    console.log(newName);
  }
}
