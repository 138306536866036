import React, { useEffect, useState } from "react";
import "simplebar";
import "simplebar/dist/simplebar.css";
import { DocumentCard } from "./DocumentCard";
import styled from "styled-components";
import {
  Layout,
  Button,
  PageHeader,
  message,
  Alert,
  Space,
  Popover,
  Avatar,
  Tooltip,
} from "antd";
import { selectAllDocuments } from "../../redux/documentSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { IDocument } from "../../core/Document";
import netlifyIdentity from "netlify-identity-widget";
import { getUserDocuments } from "../../redux/documentSlice";
import { useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import {
  DownOutlined,
  AppstoreOutlined,
  OneToOneOutlined,
  BankOutlined,
  UserOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { selectAllUsers } from "../../redux/userSlice";
const { Header, Content } = Layout;

const Logo = styled.div`
  float: left;
  width: 160px;
  height: 64px;
  background-image: url("/img/IGNITE_branding.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const HeaderLine = styled(Header)`
  border-bottom: 1px #fff solid;
`;

const AvatarContainer = styled.div`
  float: right;
  width: fit-content;
  height: 64px;
  cursor: pointer;
`;
const CustomAvatar = styled(Avatar)`
  border: 1px gray solid;
  background-color: transparent;
  width: 30px;
  height: 30px;
`;

const IconContainer = styled.div`
  color: #4e4e4e;
  font-size: 30px;
  text-align: center;
`;
const IconTitle = styled.div`
  font-size: 12px;
  text-align: center;
`;

const GroupContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: space-around;
`;

const SubMenuContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  gap: 80px;
`;

const Scrollable = styled.div`
  width: 100%;
  height: calc(100vh - 64px - 68px);
`;

export default function UserDashboard(props: { setLogin: any }) {
  const { accountId, userId } = useParams() as any;
  const documentsList = useAppSelector(selectAllDocuments) as IDocument[];
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getUserDocuments([accountId, userId]));
  }, []);

  const user = useAppSelector(selectAllUsers) as any;
  return (
    <Layout>
      <HeaderLine>
        <Logo />
        <AvatarContainer>
          <Popover
            content={
              <Button
                key="2"
                onClick={() => {
                  {
                    window.localStorage.removeItem(userId);
                    props.setLogin(false);
                  }
                }}
              >
                Logout
              </Button>
            }
            trigger="click"
          >
            <Space>
              <CustomAvatar>{user[0]?.username[0]?.toUpperCase()}</CustomAvatar>
              <DownOutlined />
            </Space>
          </Popover>
        </AvatarContainer>
      </HeaderLine>
      <Layout>
        <Content>
          <SubMenuContainer>
            <Link to={"/user/" + accountId + "/" + userId}>
              <IconContainer
                className={
                  location.pathname === "/user/" + accountId + "/" + userId
                    ? "white"
                    : ""
                }
              >
                <AppstoreOutlined />
                <IconTitle>Dashboard</IconTitle>
              </IconContainer>
            </Link>
            {/* <Link to="/tutorials"> */}
            <Tooltip title="Coming Soon!">
              <IconContainer
                className={location.pathname === "/tutorials" ? "white" : ""}
              >
                <BankOutlined />
                <IconTitle>Tutorials</IconTitle>
              </IconContainer>
            </Tooltip>
            {/* </Link> */}
          </SubMenuContainer>
          <Scrollable data-simplebar>
            <GroupContainer>
              {documentsList.map((document, index) => {
                return (
                  <div key={index}>
                    <DocumentCard document={document} />
                  </div>
                );
              })}
            </GroupContainer>
          </Scrollable>
        </Content>
      </Layout>
    </Layout>
  );
}
