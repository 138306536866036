/*
 * TODO: Cleanup all unused values
 */

import Graphic from "../Graphic/Graphic";
import { v4 as uuidv4 } from "uuid";
import Mask from "../Mask";
import { ToolType } from "../Event/CanvasEvent";

export enum LayerType {
  ImageUpload = "image_upload",
  Dropdown = "dropdown",
  Textbox = "textbox",
  Layer = "layer",
}

export interface ILayer {
  id: string;
  docId: string;
  type: LayerType;
  name: string;
  visible: boolean;
  graphics: Graphic[];
  mask: Mask;
  userMode: boolean;
  opacity: number;
  blendMode: string;
  selectedId: string;
  selectedTool: ToolType;
  x: number;
  y: number;
  width: number;
  height: number;
  scale: number;
  rotate: number;
}

export class Layer {
  id: string = "";
  docId: string = "";
  type: LayerType;
  name: string = "name goes here";
  visible: boolean = true;
  graphics: Graphic[] = [];
  mask: Mask = new Mask();
  userMode: boolean = true;
  opacity: number = 1;
  blendMode: string = "Normal";
  selectedId: string = "";
  x: number = 0;
  y: number = 0;
  width: number = 10;
  height: number = 10;
  selectedTool: ToolType = ToolType.selectLayer;
  scale: number = 1;
  rotate: number = 0;

  constructor(dodId: string, newName: string) {
    this.id = uuidv4();
    this.name = newName;
    this.docId = dodId;
    this.type = LayerType.Layer;
  }

  addGraphic(newGraphic: Graphic) {
    this.graphics.push(newGraphic);
  }

  addGraphics(newGraphics: Graphic[]) {
    this.graphics.push(...newGraphics);
  }
  getGraphics(): Graphic[] {
    return this.graphics;
  }
  setGraphics(newGraphics: Graphic[]) {
    this.graphics = newGraphics;
  }

  toJSON(): ILayer {
    const graphics = this.graphics.map((g) => g.toJSON());

    return {
      ...this,
      mask: this.mask.toJSON(),
      graphics: graphics,
    };
  }
  toString() {
    return this.id;
  }
}
