import React, { Component, useState } from "react";
import { IDocument } from "../../core/Document";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Button,
  Tooltip,
  Card,
  Space,
  Divider,
  message,
  Typography,
  Drawer,
  Checkbox,
} from "antd";
import {
  DeleteOutlined,
  UserOutlined,
  FormatPainterOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { deleteDocument } from "../../redux/documentSlice";
import { useAppDispatch } from "../../redux/hooks";
import EditDocumentModal from "../Components/EditDocumentModal";
import AssignAccount from "./AssingAccount";

const { Meta } = Card;
const CoverContainer = styled.div`
  height: 200px;
  width: 300px;
  padding: 10px 30px;
  background-color: #1f1f1f;
  margin: 1px;
`;

const PreviewImg = styled.div<{ url: string }>`
  width: 240px;
  height: 180px;
  position: absolute;
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const CardContainer = styled.div`
  padding: 0px 20px 20px 20px;
`;

const CardHoverable = styled(Card)`
  opacity: 1;
`;

const DocumentTitle = styled.div`
  float: left;
  width: 218px;
  whitespace: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function DocumentCard(props: { document: IDocument }) {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  return (
    <CardContainer>
      <CardHoverable
        hoverable
        cover={
          <Link to={"/dm/" + props.document.id}>
            <CoverContainer>
              {props.document.screenshotTimestamp === 0 ? (
                <PreviewImg url={""} />
              ) : (
                <PreviewImg
                  url={
                    "https://tig-ignite-s3.s3.amazonaws.com/screenshots/" +
                    props.document.id +
                    ".webp?timestamp=" +
                    props.document.screenshotTimestamp
                  }
                />
              )}
            </CoverContainer>
          </Link>
        }
      >
        <Meta
          style={{ zIndex: 10 }}
          title={
            <>
              <DocumentTitle>{props.document.name}</DocumentTitle>
              <Tooltip title="Assign Layout">
                <Button
                  type="text"
                  shape="circle"
                  icon={<TeamOutlined />}
                  onClick={() => setOpen(true)}
                />
              </Tooltip>
              <EditDocumentModal
                selector={props.document}
                id={props.document.id}
              />
            </>
          }
        ></Meta>
        <Drawer
          title="Assign to account"
          placement="right"
          onClose={() => setOpen(false)}
          visible={open}
        >
          <AssignAccount
            documentId={props.document.id}
            documentUrl={
              "https://tig-ignite-s3.s3.amazonaws.com/screenshots/" +
              props.document.id +
              ".webp?timestamp=" +
              props.document.screenshotTimestamp
            }
          />
        </Drawer>
      </CardHoverable>
    </CardContainer>
  );
}
