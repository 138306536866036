import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { Button, Tooltip, Card, Drawer, Radio, Space, Input } from "antd";
import { useAppDispatch } from "../../redux/hooks";
import { addTemplate } from "../../redux/templateSlice";
import {
  PlusOutlined,
  ColumnHeightOutlined,
  ColumnWidthOutlined,
  InfoCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Typography, Divider } from "antd";
const { Title } = Typography;

const CardContainer = styled.div`
  width: 140px;
  heght: 140px;
  margin: 40px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
`;
const CardOption = styled(Card)<{ width: number; height: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin: auto;
`;

const CardText = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
`;

const PlusIconContainer = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px #303030 solid;
  margin: 50px;
`;
const PlusIcon = styled(PlusOutlined)`
  font-size: 40px;
  color: white;
`;

const TitleContainer = styled.div`
  margin: 20px 0px;
  text-align: center;
`;

const SelectLayoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const LayoutNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  order: -1;
  height: 50px;
`;

const CloseContainer = styled.div`
  position: absolute;
  width: calc(100vw - 38px);
  display: flex;
  align-items: center;
  float: right;
  justify-content: flex-end;
  margin-top: -35px;
  pointer-events: none;
`;
const CloseDiv = styled.div`
  width: 30px;
  text-align: center;
  height: 20px;
  cursor: pointer;
  pointer-events: all;
`;

export function AddTemplate() {
  const [isOpen, setIsOpen] = useState(false);
  const [option, setOption] = useState(1);
  const [width, setWidth] = useState(1920);
  const [height, setHeight] = useState(1080);
  const [name, setName] = useState("");
  const dispatch = useAppDispatch();
  return (
    <>
      <Tooltip title="Add New Template">
        <Card
          hoverable
          onClick={(e) => {
            setIsOpen(true);
          }}
          style={{ width: 300, height: 245, zIndex: 999 }}
        >
          <PlusIconContainer>
            <PlusIcon />
          </PlusIconContainer>
        </Card>
      </Tooltip>
      <Drawer
        width="100%"
        className="add-layout-container"
        visible={isOpen}
        closable={false}
        title={
          <>
            <LayoutNameContainer>
              <Title style={{ width: 250 }} level={5}>
                Enter Layout Name
              </Title>
              <Input
                width="120px"
                placeholder="Template Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </LayoutNameContainer>
            <CloseContainer>
              <CloseDiv onClick={() => setIsOpen(false)}>
                <CloseOutlined />
              </CloseDiv>
            </CloseContainer>
          </>
        }
      >
        <TitleContainer>
          <Title level={4}>Select Template Size</Title>
        </TitleContainer>
        <SelectLayoutContainer>
          <Space direction={"vertical"}>
            <Radio.Group
              className={"dashboard-add-layout-radio-group"}
              buttonStyle="solid"
              onChange={(e) => {
                setOption(e.target.value);
                switch (e.target.value) {
                  case 1:
                    setWidth(1920);
                    setHeight(1080);
                    return;
                  case 2:
                    setWidth(1920);
                    setHeight(1920);
                    return;
                  case 3:
                    setWidth(1080);
                    setHeight(1920);
                    return;
                }
              }}
              value={option}
            >
              <Radio className={"dashboard-add-layout-radio"} value={1}>
                <CardContainer>
                  <CardOption width={140} height={80} />
                  <CardText>1920x1080</CardText>
                </CardContainer>
              </Radio>
              <Radio className={"dashboard-add-layout-radio"} value={2}>
                <CardContainer>
                  <CardOption width={100} height={100} />
                  <CardText>1920x1920</CardText>
                </CardContainer>
              </Radio>
              <Radio className={"dashboard-add-layout-radio"} value={3}>
                <CardContainer>
                  <CardOption width={80} height={140} />
                  <CardText>1080x1920</CardText>
                </CardContainer>
              </Radio>
            </Radio.Group>
          </Space>
          <Space direction={"vertical"}>
            <Input
              placeholder="Enter Width"
              value={width}
              onChange={(e) => {
                setOption(0);
                setWidth(parseFloat(e.target.value));
              }}
              prefix={<ColumnWidthOutlined />}
              type={"number"}
              min={0}
              suffix={
                <Tooltip title="Enter Custom Width">
                  <span style={{ padding: "0px 10px" }}>px</span>
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />
            <Input
              placeholder="Enter Height"
              value={height}
              onChange={(e) => {
                setOption(0);
                setHeight(parseFloat(e.target.value));
              }}
              prefix={<ColumnHeightOutlined />}
              min={0}
              suffix={
                <Tooltip title="Enter Custom Height">
                  <span style={{ padding: "0px 10px" }}>px</span>
                  <InfoCircleOutlined />
                </Tooltip>
              }
              type={"number"}
            />
          </Space>
        </SelectLayoutContainer>
        <Divider />
        <SelectLayoutContainer>
          <Button onClick={() => setIsOpen(false)} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={() => addDoc()} type="primary">
            Create Template
          </Button>
        </SelectLayoutContainer>
      </Drawer>
    </>
    // </Tooltip>
  );

  async function addDoc() {
    await dispatch(addTemplate({ name, width, height }));
    setIsOpen(false);
  }
}
