import React, { useState, useEffect } from "react";
import {
  Button,
  Tooltip,
  Card,
  Space,
  Divider,
  message,
  Typography,
  Drawer,
  Checkbox,
  Modal,
  Input,
  Popconfirm,
  Avatar,
} from "antd";
import styled from "styled-components";
import {
  SettingOutlined,
  UserOutlined,
  CopyOutlined,
  KeyOutlined,
  ReloadOutlined,
  EyeOutlined,
  DeleteOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  getUsers,
  selectAllUsers,
  updateUser,
  deleteUser,
} from "../../redux/userSlice";
import AddNewAccount from "./AddNewAccount";

import copy from "copy-to-clipboard";
import EditAccount from "./EditAccount";
import UserLayoutList from "./UserLayoutsList";

const Container = styled.div`
  width: 100%;
  // width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px 70px;
  height: 100%;
  padding: 80px 50px;
`;
const AvatarInitials = styled(Avatar)`
  color: white;
  background-color: black;
  border: 1px solid white;

  vertical-align: middle;
`;

const AvatarContainer = styled.div`
  position: absolute;
  margin: -55px 0px 0px -55px;
`;
const Box = styled.div`
  padding: 30px;
  margin: 0px 10px;
  outline: 1px solid white;
  // max-width: 400px;
  width: 420px;
  margin: auto;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  line-height: 1.5;
  gap: 20px;
`;

const NotesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: fit-content;
  line-height: 1.5;
  gap: 20px;
  padding-bottom: 25px;
`;

const NewAccountContainer = styled.div`
  
    text-align: center;
}
`;

export default function Accounts() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const allUsers = useAppSelector((state) => {
    return selectAllUsers(state);
  });

  return (
    <div>
      <NewAccountContainer>
        <AddNewAccount />
      </NewAccountContainer>
      <Container>
        {allUsers.map((user: any) => {
          return (
            <Box key={user.id + "_users"}>
              <AvatarContainer>
                <AvatarInitials size={50}>{user.username[0]}</AvatarInitials>
              </AvatarContainer>

              <TextContainer>
                <Typography.Title level={4} style={{ width: 140 }}>
                  Account Name:
                </Typography.Title>
                <Typography.Title
                  level={5}
                  style={{ marginTop: 0, width: 200 }}
                >
                  {user.username}
                </Typography.Title>
              </TextContainer>

              <TextContainer>
                <Typography.Title level={5} style={{ width: 140 }}>
                  Assigned Layouts:
                </Typography.Title>
                <Typography.Title
                  level={5}
                  style={{ marginTop: 0, width: 200 }}
                >
                  {user.documents.length}
                </Typography.Title>
              </TextContainer>
              <NotesContainer>
                <Typography.Title level={5} style={{ width: 140 }}>
                  Account Notes:
                </Typography.Title>
                <Typography.Title
                  level={5}
                  style={{ marginTop: 0, width: 200, whiteSpace: "pre" }}
                >
                  {user.notes}
                </Typography.Title>
              </NotesContainer>
              <TextContainer>
                <EditAccount account={user} />
                <UserLayoutList
                  documentList={user.documents}
                  name={user.username}
                />
                <Button
                  onClick={() => {
                    window.open(`/user/${user.accountId}/${user.id}`, "_blank");
                  }}
                >
                  <Tooltip title="Link to Acccount">
                    <LinkOutlined />
                  </Tooltip>
                </Button>
                <Popconfirm
                  title="Are you sure to delete this account?"
                  onConfirm={() => {
                    dispatch(deleteUser([user.id]));
                    message.success("Account Deleted");
                  }}
                >
                  <Tooltip title="Delete Account">
                    <Button
                      danger
                      onClick={(e) => {
                        e.preventDefault();
                        e.currentTarget.blur();
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              </TextContainer>
            </Box>
          );
        })}
      </Container>
    </div>
  );
}
