import { Layer, LayerType } from "./Layer";
import Image from "../Graphic/Image";
import Graphic from "../Graphic/Graphic";

class ImageUpload extends Layer {
  type: LayerType = LayerType.ImageUpload;
  imageX: number = 0;
  imageY: number = 0;

  constructor(docId: string, newName: string = "New Image") {
    super(docId, newName);
  }
}

export default ImageUpload;
