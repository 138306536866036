import React, { Component, useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";

import { Tooltip, Space, message, Typography, Popconfirm, Button } from "antd";
import { DeleteOutlined, EditOutlined, LinkOutlined } from "@ant-design/icons";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import styled from "styled-components";
import {
  getUsers,
  selectAllUsers,
  updateUser,
  deleteUser,
} from "../../redux/userSlice";
import { User } from "../../core/User";
import EditAccount from "./EditAccount";
import { Link } from "react-router-dom";

type Props = {
  documentId: string;
  documentUrl: string;
};

const Preview = styled.div`
  width: 200px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
`;

export default function AssignAccount(props: Props) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const sensors = useSensors(useSensor(PointerSensor));
  const allUsers = useAppSelector((state) => {
    return selectAllUsers(state);
  });

  let empty = true;
  return (
    <>
      {props.documentUrl.endsWith("timestamp=0") ? (
        <Preview
          style={{
            backgroundImage: "url()",
          }}
        ></Preview>
      ) : (
        <Preview
          style={{
            backgroundImage: "url(" + props.documentUrl + ")",
          }}
        ></Preview>
      )}

      <div style={{ height: "30px" }}> </div>

      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <Typography.Title level={5}>
          Accounts with access to Layout:
        </Typography.Title>
        <Droppable
          key={"A"}
          id={"A"}
          deleteAccount={deleteAccount}
          style={{ margin: "auto" }}
        >
          {allUsers.map((user: any, i) => {
            if (user.documents.includes(props.documentId)) {
              empty = false;
              return (
                <Draggable
                  key={"userid_" + i}
                  id={"id" + i}
                  index={i}
                  deleteAccount={deleteAccount}
                  user={user}
                />
              );
            }
          })}
          {empty ? (
            <EmptyDroppable> Drag and Drop accounts here</EmptyDroppable>
          ) : (
            <></>
          )}
        </Droppable>
        <Typography.Title level={5}>Avaliable Accounts:</Typography.Title>
        <Droppable key={"B"} id={"B"}>
          {allUsers.map((user: any, i) => {
            return !user.documents.includes(props.documentId) ? (
              <Draggable
                key={"userid_" + i}
                id={"id" + i}
                index={i}
                deleteAccount={deleteAccount}
                user={user}
              />
            ) : (
              ""
            );
          })}
        </Droppable>
      </DndContext>
      <Link to="/accounts">
        <Button type="primary">Manage Accounts</Button>
      </Link>
    </>
  );
  function handleDragEnd(event: any) {
    const { over } = event;

    // If the item is dropped over a container, set it as the parent
    // otherwise reset the parent to `null`
    if (over !== null) {
      if (over.id === "A") {
        const index = event.active.id[2];
        const user = allUsers[index] as User;
        if (!user.documents.includes(props.documentId)) {
          let newDocs = Array.from(user.documents);
          newDocs.push(props.documentId);
          dispatch(updateUser([user.id, "documents", newDocs]));
        }
      }
      if (over.id === "B") {
        const index = event.active.id[2];
        const user = allUsers[index] as User;
        let newDocs = Array.from(user.documents);
        for (var i = 0; i < newDocs.length; i++) {
          if (newDocs[i] === props.documentId) {
            newDocs.splice(i, 1);
          }
        }
        dispatch(updateUser([user.id, "documents", newDocs]));
      }
    }
  }

  function deleteAccount(index: number) {
    const user = allUsers[index] as User;
    dispatch(deleteUser([user.id]));
  }
}

const DraggableDiv = styled.div`
  color: white;
  background-color: #1f1f1f;
  border: 1px #303030 solid;
  padding: 10px 20px;
  user-select: none;
  height: 40px;
  width: 250px;
  margin: 10px auto;
  &:hover {
    cursor: grab;
  }
`;

const NameHolder = styled.div`
  color: white;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  width: fill-available;
  height: 30px;
  text-overflow: ellipsis;
  float: left;
  white-space: nowrap;
  overflow: hidden;
`;

function Draggable(props: any) {
  const [showEdit, setShowEdit] = useState(false);
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;
  return (
    <>
      <DraggableDiv
        ref={setNodeRef}
        style={style}
        {...listeners}
        {...attributes}
      >
        <NameHolder>{props.user.username}</NameHolder>
        {/*       <div
          style={{
            float: "right",
            display: "flex",
            justifyContent: "space-between",
            width: 35,
          }}
        >
      
          <Space>
             <Tooltip title="Delete Account">
              <Popconfirm
                title="Are you sure to delete this account?"
                onConfirm={() => {
                  props.deleteAccount(props.index);
                  message.success("Account Deleted");
                }}
              >
                <DeleteOutlined />
              </Popconfirm>
            </Tooltip> */}
        {/* <Tooltip title="Open Account to new">
              <LinkOutlined
                onClick={() => {
                  window.open(
                    `/user/${props.user.accountId}/${props.user.id}`,
                    "_blank"
                  );
                }}
              />
            </Tooltip>
            <Tooltip title="Edit Account">
              <EditOutlined
                onClick={(e) => {
                  setShowEdit(true);
                }}
              />
            </Tooltip>
          </Space>
        </div>*/}
      </DraggableDiv>
      {/* <EditAccount
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        account={props.user}
      /> */}
    </>
  );
}

const DroppableArea = styled.div`
  // color: blue;
  width: 300px;
  // background-color: black;
  height: fit-content;
  min-height: 80px;
  margin: 10px auto;
  padding: 10px;
`;

const EmptyDroppable = styled.div`
  color: white;
  width: 150px;
  height: fit-content;
  min-height: 80px;
  margin: 10px auto;
  padding: 10px;
  border: 1px gray dashed;
  text-align: center;
  color: gray;
`;

function Droppable(props: any) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const style = {
    color: isOver ? "gray" : undefined,
  };
  return (
    <DroppableArea ref={setNodeRef} style={style}>
      {props.children ? props.children : "Drag & Drop Accounts Here"}
    </DroppableArea>
  );
}
