/*
 * Helper API Functions
 */

import netlifyIdentity from "netlify-identity-widget";
import { notification } from "antd";

interface LambdaError {
  data: string;
  // ...
}

export interface UserData {
  id: string;
  token: string;
}

export async function getTokenAndId() {
  //Expired token for testing
  // return {
  //   id: "eade092f-e962-4fba-b996-b8fdaa2b77a8",
  //   token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDI0NDE3NTcsInN1YiI6ImVhZGUwOTJmLWU5NjItNGZiYS1iOTk2LWI4ZmRhYTJiNzdhOCIsImVtYWlsIjoibHVpc0BmdXp6eXJlZHBhbmRhLmNvbSIsImFwcF9tZXRhZGF0YSI6eyJwcm92aWRlciI6ImVtYWlsIiwicm9sZXMiOlsiYWRtaW4iXX0sInVzZXJfbWV0YWRhdGEiOnsiZnVsbF9uYW1lIjoiTHVpcyJ9fQ.NselvSf938q86z5dIq5De8tPkSGJCvUtaTExzoT2770";
  // }
  const user = netlifyIdentity.currentUser();
  if (user === null) {
    window.location.reload();
    return { id: "", token: "" };
  } else {
    const token = await netlifyIdentity.refresh();
    return { id: user.id, token };
  }
}

export async function displayError(errorMsg: string) {
  if (errorMsg) {
    notification["error"]({
      message: errorMsg,
      duration: 5,
    });
  }
}

export async function handleResponseErrors(thunkApi: any, response: Response) {
  return thunkApi.rejectWithValue(response.status + ": " + response.statusText);
}
