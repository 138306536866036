import netlifyIdentity, { User } from "netlify-identity-widget";
import { useLocation } from "react-router-dom";
import "./login.css";
import { Button, Space } from "antd";

/* Trial Content */
let trialDiv = document.createElement("div");
trialDiv.textContent = "14 day trial - No credit Card Required";

trialDiv.classList.add("trialText");
trialDiv.style.textAlign = "center";
trialDiv.style.width = "100%";
trialDiv.style.fontSize = "14px";
trialDiv.style.color = "rgb(14, 30, 37)";
trialDiv.style.fontWeight = "700";

netlifyIdentity.on("open", () => {
  let iframeBody = document.querySelector("iframe")?.contentDocument?.body;
  iframeBody?.querySelector(".header")?.addEventListener("click", addText);
});
netlifyIdentity.on("close", () => {
  let iframeBody = document.querySelector("iframe")?.contentDocument?.body;
  iframeBody?.querySelector(".header")?.removeEventListener("click", addText);
  iframeBody?.querySelector(".trialText")?.remove();
});

function addText() {
  let iframeBody = document.querySelector("iframe")?.contentDocument?.body;
  const selectedTab = iframeBody
    ?.querySelector(".header")
    ?.querySelector(".btn")
    ?.className.includes("active");

  if (selectedTab) {
    iframeBody?.querySelector(".modalContent")?.appendChild(trialDiv);
  } else {
    console.log(iframeBody?.querySelector(".trialText"));
    iframeBody?.querySelector(".trialText")?.remove();
  }
}

export function Login(props: { loading?: boolean }) {
  const { search } = useLocation() as any;

  if (!props.loading) {
    if (search === "?signup") {
      netlifyIdentity.open("signup");
      let iframeBody = document.querySelector("iframe")?.contentDocument?.body;
      iframeBody?.querySelector(".modalContent")?.appendChild(trialDiv);
    }
    if (search === "?login") {
      netlifyIdentity.open("login");
      let iframeBody = document.querySelector("iframe")?.contentDocument?.body;
      iframeBody?.querySelector(".trialText")?.remove();
    }
  }
  return (
    <div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
          background:
            "url('https://tig-ignite.s3.amazonaws.com/Login_Page_back.png')",
          backgroundSize: "contain",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          gap: 20,
        }}
      >
        <div
          style={{
            width: 435,
            height: 100,
            background:
              "url('https://tig-ignite.s3.amazonaws.com/Ti_Ignite_Logo.png')",
            backgroundSize: 300,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>

        {props.loading ? (
          <div className="wrapper">
            <span className="circle circle-1"></span>
            <span className="circle circle-2"></span>
            <span className="circle circle-3"></span>
            <span className="circle circle-4"></span>
            <span className="circle circle-5"></span>
            <span className="circle circle-6"></span>
            <span className="circle circle-7"></span>
            <span className="circle circle-8"></span>
          </div>
        ) : (
          <Space size="middle">
            <Button
              value="large"
              onClick={() => {
                netlifyIdentity.open("signup");
                let iframeBody =
                  document.querySelector("iframe")?.contentDocument?.body;
                iframeBody
                  ?.querySelector(".modalContent")
                  ?.appendChild(trialDiv);
              }}
            >
              Sign Up
            </Button>
            <Button
              value="large"
              onClick={() => {
                netlifyIdentity.open("login");
              }}
            >
              Log In
            </Button>
          </Space>
        )}

        <div data-netlify-identity-menu></div>
      </div>
    </div>
  );
}
