import { Layer, LayerType } from "./Layer";
import Text from "../Graphic/Text";

class Textbox extends Layer {
  type: LayerType = LayerType.Textbox;

  constructor(docId: string, newName: string = "New Text") {
    super(docId, newName);
  }
}

export default Textbox;
