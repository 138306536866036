import { v4 as uuidv4 } from "uuid";
import { Font } from "../GraphicsEngine";

export enum DocumentType {
  motion = "motion",
  static = "static",
}

export interface IDocument {
  id: string;
  layers: string[];
  fonts: Font[];
  name: string;
  preview_url: string;
  width: number;
  height: number;
  screenshotTimestamp: number;
  visible?: boolean;
  created?: number;
}

export class Document implements IDocument {
  id: string = "";
  layers: string[];
  name: string = "name goes here";
  preview_url: string = "";
  width: number;
  height: number;
  fonts: Font[] = [];
  screenshotTimestamp: number;
  visible?: boolean;
  created?: number;

  constructor(
    name: string,
    width: number = 1080,
    height: number = 1080,
    layers: string[] = [],
    preview_url: string = ""
  ) {
    this.id = uuidv4();
    this.name = name;
    this.layers = layers;
    this.preview_url = preview_url;
    this.width = width;
    this.height = height;
    this.screenshotTimestamp = 0;
  }

  addLayer(layer: string) {
    this.layers.push(layer);
  }

  addLayers(layers: string[]) {
    this.layers.push(...layers);
  }
  getLayers(): String[] {
    return Array.from(this.layers);
  }
  setLayers(layers: string[]) {
    this.layers = layers;
  }

  toJSON(): IDocument {
    return {
      ...this,
    };
  }
  toString() {
    return this.id;
  }
}
