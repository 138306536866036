import React, { Component, useState, useEffect } from "react";
import { IDocument } from "../../core/Document";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Tooltip, Card, Space, message, Modal } from "antd";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { getDocuments } from "../../redux/documentSlice";
import { useAppDispatch } from "../../redux/hooks";
import EditDocumentModal from "../Components/EditDocumentModal";

import { isAdmin } from "../../utils/isAdmin";
import { getTokenAndId } from "../../redux/helpers";

const { Meta } = Card;
const CoverContainer = styled.div`
  height: 200px;
  width: 300px;
  padding: 10px 30px;
  background-color: #1f1f1f;
`;

const PreviewImg = styled.div<{ url: string }>`
  width: 240px;
  height: 180px;
  position: absolute;
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const CardContainer = styled.div`
  padding: 0px 20px 20px 20px;
`;

const CardHoverable = styled(Card)`
  opacity: 1;
`;

const DocumentTitle = styled.div`
  float: left;
  width: 154px;
  whitespace: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function TemplateCard(props: { document: IDocument }) {
  const dispatch = useAppDispatch();

  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const currentUserIsAdmin = isAdmin();
  return (
    <CardContainer>
      <CardHoverable
        cover={
          <>
            {currentUserIsAdmin ? (
              <Link to={"/templates/" + props.document.id}>
                <CoverContainer>
                  {props.document.screenshotTimestamp === 0 ? (
                    <PreviewImg url={""} />
                  ) : (
                    <PreviewImg
                      url={
                        "https://tig-ignite-s3.s3.amazonaws.com/screenshots/" +
                        props.document.id +
                        ".webp?timestamp=" +
                        props.document.screenshotTimestamp
                      }
                    />
                  )}
                </CoverContainer>
              </Link>
            ) : (
              <CoverContainer>
                {props.document.screenshotTimestamp === 0 ? (
                  <PreviewImg url={""} />
                ) : (
                  <PreviewImg
                    url={
                      "https://tig-ignite-s3.s3.amazonaws.com/screenshots/" +
                      props.document.id +
                      ".webp?timestamp=" +
                      props.document.screenshotTimestamp
                    }
                  />
                )}
              </CoverContainer>
            )}
          </>
        }
      >
        <Meta
          style={{ zIndex: 10 }}
          title={
            <>
              <DocumentTitle>{props.document.name}</DocumentTitle>
              <Space size="small" style={{ float: "right" }}>
                {currentUserIsAdmin ? (
                  <EditDocumentModal
                    isTemplate
                    selector={props.document}
                    id={props.document.id}
                  />
                ) : (
                  <></>
                )}

                <Tooltip title="Preview Template">
                  <Button
                    onClick={() => {
                      Modal.info({
                        title: props.document.name,
                        content: (
                          <div>
                            <img
                              style={{
                                width: "auto",
                                maxWidth: 360,
                                padding: 20,
                                margin: "auto",
                              }}
                              src={
                                "https://tig-ignite-s3.s3.amazonaws.com/screenshots/" +
                                props.document.id +
                                ".webp?timestamp=" +
                                props.document.screenshotTimestamp
                              }
                            />
                            <>
                              <h2>Specs:</h2>
                              <ul>
                                <li>Width: {props.document.width}px</li>
                                <li>Height: {props.document.height}px</li>
                                <li>
                                  Number of Layers:{" "}
                                  {props.document.layers.length}
                                </li>
                              </ul>
                            </>
                          </div>
                        ),

                        icon: <></>,
                        onOk() {},
                      });
                    }}
                  >
                    <EyeOutlined />
                  </Button>
                </Tooltip>

                <Tooltip title="Add Layout to Account">
                  <Button
                    // ghost
                    loading={duplicateLoading}
                    type="primary"
                    onClick={async () => {
                      setDuplicateLoading(true);
                      try {
                        /* Screenshot */
                        const userData = await getTokenAndId();

                        /*
                         * Header
                         */
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        myHeaders.append(
                          "Authorization",
                          "Bearer " + userData.token
                        );

                        /*
                         * RequestData
                         */
                        var raw: string = JSON.stringify({
                          templateId: props.document.id,
                          accountId: userData.id,
                        });
                        var requestOptions = {
                          method: "POST",
                          headers: myHeaders,
                          body: raw,
                        };

                        /*
                         * Fetch
                         */
                        const response = await fetch(
                          "/.netlify/functions/template-add-to-account",
                          requestOptions
                        );

                        if (response.status !== 200) {
                          message.error("Error while adding to account");
                        }
                        dispatch(getDocuments());
                        setDuplicateLoading(false);
                        message.success("Layout added to account");
                      } catch (error) {
                        message.error("Error while adding to account");
                        setDuplicateLoading(false);
                      }
                    }}
                  >
                    {!duplicateLoading ? <PlusOutlined /> : <></>}
                  </Button>
                </Tooltip>
              </Space>
            </>
          }
        ></Meta>
      </CardHoverable>
    </CardContainer>
  );
}
