import { useState } from "react";
import { Button, Tag } from "antd";
import { displayError, getTokenAndId } from "../../redux/helpers";
import netlifyIdentity from "netlify-identity-widget";

var baseUrl = "/.netlify/functions/";

export default function SubscribeButton() {
  const [loading, setLoading] = useState(false);
  const user = netlifyIdentity.currentUser();

  if (user !== null) {
    const app_metadata = user.app_metadata as any;
    if (!app_metadata.subscribed) {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const trialStart = app_metadata.trial_start || Date.now();
      const trial_length = app_metadata.trial_length || 14;
      const trial_ends = trialStart + trial_length * oneDay;
      if (Date.now() - trial_ends > 0) {
        return (
          <>
            <Tag color="error">Trial Expired</Tag>
            <Button onClick={handleClick} loading={loading}>
              Subscribe
            </Button>
          </>
        );
      } else {
        const daysRemaining = Math.round(
          Math.abs((Date.now() - trial_ends) / oneDay)
        );
        return (
          <>
            <Tag color="warning">Trial Expires in {daysRemaining} days</Tag>
            <Button onClick={handleClick} loading={loading}>
              Subscribe
            </Button>
          </>
        );
      }
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }

  async function handleClick(e: React.MouseEvent<HTMLElement>) {
    setLoading(true);
    e.currentTarget.blur();
    const userData = await getTokenAndId();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    const request = await fetch(baseUrl + "subscription-link", requestOptions);
    const response = await request.json();
    if (request.status !== 200) {
      displayError(request.status + ": " + request.statusText);
      setLoading(false);
    } else {
      window.location.href = response.url;
    }
  }
}
