import React, { useEffect, useState } from "react";
import { CanvasKit } from "canvaskit-wasm";
import "./App.css";
import DesignerMode from "./ui/DesignerMode";
import UserLogin from "./ui/UserLogin/";
import Dashboard from "./ui/Dashboard";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import UserMode from "./ui/UserMode";
import netlifyIdentity from "netlify-identity-widget";
import { Login } from "./ui/Login";
import { TutorialPost } from "./ui/Dashboard/TutorialPost";
import { GraphicsEngine } from "./core/GraphicsEngine";

import Success from "./ui/Subscription/Success";
import Profile from "./ui/Subscription/Profile";
import Expired from "./ui/Subscription/Expired";
import { logoutUser } from "./core/Identity";

let ck: CanvasKit;

netlifyIdentity.on("init", (user) => {
  if (user !== null) {
    netlifyIdentity.refresh(true);
  }
});

function App() {
  const [ckLoaded, setCkLoaded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    netlifyIdentity.currentUser() !== null
  );

  useEffect(() => {
    netlifyIdentity.on("login", () => {
      setIsAuthenticated(true);
      netlifyIdentity.close();
    });
    netlifyIdentity.on("logout", () => {
      logoutUser();
      setIsAuthenticated(false);
    });

    GraphicsEngine.init().then((canvaskit) => {
      ck = canvaskit;
      setCkLoaded(true);
    });
  }, []);

  return (
    <Router>
      <Route
        exact
        path="/dm/:id"
        render={({ location }) => {
          if (ckLoaded) {
            return redirect(<DesignerMode ck={ck} />, location);
          } else {
            return <Login loading={true} />;
          }
        }}
      />
      <Route
        exact
        path="/um/:id"
        render={({ location }) => {
          if (ckLoaded) {
            return redirect(<UserMode ck={ck} />, location);
          } else {
            return <Login loading={true} />;
          }
        }}
      />
      <Route
        exact
        path="/dashboard"
        render={({ location }) => redirect(<Dashboard />, location)}
      />
      <Route
        exact
        path="/templates"
        render={({ location }) => redirect(<Dashboard />, location)}
      />
      <Route
        exact
        path="/templates/:id"
        render={({ location }) => {
          return redirect(<DesignerMode ck={ck} isTemplate />, location);
        }}
      />
      <Route
        exact
        path="/tutorials"
        render={({ location }) => redirect(<Dashboard />, location)}
      />
      <Route
        exact
        path="/tutorials/:id"
        render={({ location }) => redirect(<TutorialPost />, location)}
      />
      <Route
        exact
        path="/accounts"
        render={({ location }) => redirect(<Dashboard />, location)}
      />
      <Route
        exact
        path="/user/:accountId/:userId"
        render={() => {
          return <UserLogin />;
        }}
      />

      <Route
        exact
        path="/user/:accountId/:userId/um/:id"
        render={() => {
          if (ckLoaded) {
            return <UserMode ck={ck} />;
          } else {
            return <Login loading={true} />;
          }
        }}
      />

      <Route
        exact
        path="/profile"
        render={({ location }) => redirect(<Profile />, location)}
      />

      <Route exact path="/" render={() => dashRedirect(<Login />)} />
      <Route exact path="/success" render={() => <Success />} />
      <Route
        exact
        path="/expired"
        render={() => {
          if (isAuthenticated && !isSubscribed()) {
            return <Expired />;
          } else {
            return <Redirect to={{ pathname: "/" }} />;
          }
        }}
      />
      <Route exact path="/loading" render={() => <Login loading={true} />} />
    </Router>
  );

  /*
   * Aux functions
   */

  function dashRedirect(component: any): any {
    if (isAuthenticated) {
      let user = netlifyIdentity.currentUser();
      if (user !== null) {
        if (isSubscribed()) {
          return <Redirect to={{ pathname: "/dashboard" }} />;
        } else {
          return <Redirect to={{ pathname: "/expired" }} />;
        }
      } else {
        return component;
      }
    } else {
      return component;
    }
  }

  function redirect(component: any, location: any): any {
    if (isAuthenticated) {
      let user = netlifyIdentity.currentUser();
      if (user !== null) {
        if (isSubscribed()) {
          return component;
        } else {
          return <Redirect to={{ pathname: "/expired" }} />;
        }
      } else {
        return (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        );
      }
    } else {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: location },
          }}
        />
      );
    }
  }
  function isSubscribed() {
    let user = netlifyIdentity.currentUser();
    const app_metadata = user?.app_metadata as any;
    if (app_metadata.subscribed) {
      return app_metadata.subscribed;
    } else {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const trialStart = app_metadata.trial_start || Date.now();
      const trial_length = app_metadata.trial_length || 14;
      const trial_ends = trialStart + trial_length * oneDay;
      return Date.now() - trial_ends < 0;
    }
  }
}

export default App;
