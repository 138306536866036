import React, { useEffect, useState } from "react";
import { Layout, Button, Space, Dropdown, Menu, Avatar } from "antd";
import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";
import netlifyIdentity from "netlify-identity-widget";
import SubscribeButton from "../Subscription/SubscribeButton";
import { displayError, getTokenAndId } from "../../redux/helpers";
import { Link } from "react-router-dom";
var baseUrl = "/.netlify/functions/";

const { Header } = Layout;

const Logo = styled.div`
  float: left;
  width: 160px;
  height: 64px;
  background-image: url("/img/IGNITE_branding.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const HeaderLine = styled(Header)`
  border-bottom: 1px #ff0200 solid;
`;

const AvatarContainer = styled.div`
  padding: 0px 10px;
  width: fit-content;
  height: 64px;
  cursor: pointer;
`;
const CustomAvatar = styled(Avatar)`
  border: 1px gray solid;
  background-color: transparent;
  width: 30px;
  height: 30px;
`;
const SubscribeContainer = styled.div`
  width: fit-content;
  height: 64px;
  cursor: pointer;
`;

const RightContainer = styled.div`
  display: flex;
  float: right;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  gap: 10px;
`;
const ButtonLoading = styled(Button)`
  &:hover {
    background: transparent !important;
  }
`;

export default function DashboardHeader() {
  const user = netlifyIdentity.currentUser();
  const [loading, setLoading] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState(false);
  return (
    <HeaderLine>
      <Link to="/dashboard">
        <Logo />
      </Link>
      <RightContainer>
        <SubscribeContainer>
          <SubscribeButton />
        </SubscribeContainer>
        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          visible={visibleMenu}
          onVisibleChange={(flag) => {
            setVisibleMenu(flag);
          }}
          overlay={
            <Menu
              onClick={(e: any) => {
                if (e.key === "3") {
                  setVisibleMenu(false);
                }
              }}
            >
              <Menu.Item key="0">
                <ButtonLoading
                  onClick={handleClick}
                  type="text"
                  loading={loading}
                >
                  Manage Subscription
                </ButtonLoading>
              </Menu.Item>
              <Menu.Item key="1">
                <a href="mailto:helpdesk@teaminfographics.com">
                  <ButtonLoading type="text">Contact Us</ButtonLoading>
                </a>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="3"
                onClick={() => {
                  netlifyIdentity.logout();
                }}
              >
                <ButtonLoading type="text">Logout</ButtonLoading>
              </Menu.Item>
            </Menu>
          }
        >
          <AvatarContainer>
            <Space>
              <CustomAvatar>
                {user?.user_metadata.full_name
                  ? user?.user_metadata.full_name[0].toLocaleUpperCase()
                  : user?.email[0].toLocaleUpperCase()}
              </CustomAvatar>
              <DownOutlined />
            </Space>
          </AvatarContainer>
        </Dropdown>
      </RightContainer>
    </HeaderLine>
  );
  async function handleClick(e: React.MouseEvent<HTMLElement>) {
    setLoading(true);
    e.currentTarget.blur();
    const userData = await getTokenAndId();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    const request = await fetch(
      baseUrl + "subscription-manage",
      requestOptions
    );
    const response = await request.json();
    if (request.status !== 200) {
      displayError(request.status + ": " + request.statusText);
      setLoading(false);
    } else {
      window.location.href = response.url;
    }
  }
}
