import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Input, Switch, Popconfirm, Tooltip } from "antd";
import Icon, {
  EyeOutlined,
  DeleteOutlined,
  FileImageOutlined,
  EyeInvisibleOutlined,
  UnorderedListOutlined,
  FontSizeOutlined,
  PictureOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import styled, { css } from "styled-components";

const WrapperDiv = styled.div.attrs((props) => ({
  style: {
    transform: props.transform,
    transition: props.transition,
  },
}))`
  padding: 4px 0px;
  width: 100%;
  margin-right: 1px;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  ${(props) =>
    props.selected &&
    css`
      background-color: #2b2b2b;
    `}
`;

const EyeOn = styled(EyeOutlined)`
  padding-right: 10px;
  transition: color 0.2s;
  &:hover {
    color: #177ddc;
  }
`;

const Delete = styled(DeleteOutlined)`
  padding-right: 10px;
  transition: color 0.2s;
  &:hover {
    color: #177ddc;
  }
`;

const EyeOff = styled(EyeInvisibleOutlined)`
  padding-right: 10px;
`;
const LayerIcon = styled(Icon)`
  padding: 0px 10px 0px 10px;
  color: white;
  float: left;
`;

const DblClickInput = styled(Input)`
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: fill-available;
  width: -webkit-fill-available;
  text-overflow: ellipsis;
  margin-right: 10px;
`;
const LayerName = styled.span`
  // width: 154px;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  // max-width: 70%;
  height: 28px;
  margin-right: 10px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  position: relative;
  display: inline-block;
  min-width: 0;
  padding: 3px 7px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 12px;
  line-height: 1.66667;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 2px;
`;

const HoveredButtons = styled.span`
  padding-right: 7px;
  display: inline-flex;
  justify-content: flex-end;
  // min-width: 54px;
    width: auto;
}
`;

export function SortableItem(props) {
  const [hovered, setHovered] = useState(false);
  const [dblClick, setDblClick] = useState(false);
  const [textValue, setTextValue] = useState(props.title);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });
  const listenersActive = !dblClick && listeners;

  if (dblClick && props.selected !== props.id) {
    setDblClick(false);
    props.setTitle(textValue, props.index);
  }

  return (
    <WrapperDiv
      id={props.id}
      ref={setNodeRef}
      transform={CSS.Transform.toString(transform)}
      transition={transition}
      {...props}
      {...attributes}
      {...listenersActive}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onMouseDown={(e) => {
        if (e.detail === 1) {
          props.setSelected(props.id);
        }
      }}
      selected={props.selected === props.id ? true : ""}
      title=""
    >
      {!props.iconOff ? (
        <LayerIcon
          component={() => {
            if (props.type === "dropdown") {
              return <UnorderedListOutlined />;
            }
            if (props.type === "image_upload") {
              return <PictureOutlined />;
            }
            if (props.type === "textbox") {
              return <FontSizeOutlined />;
            }
            return <FileImageOutlined />;
          }}
        />
      ) : (
        <></>
      )}

      {dblClick && props.selected === props.id ? (
        <DblClickInput
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          defaultValue={props.title}
          onBlur={(e) => {
            setDblClick(false);
            props.setTitle(e.target.value, props.index);
          }}
          onChange={(e) => {
            setTextValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.keyCode === 13) {
              e.target.blur();
            }
          }}
          autoFocus
        />
      ) : (
        <LayerName
          title="Double click to edit name"
          onClick={(e) => {
            if (e.detail === 2) {
              setDblClick(true);
            }
          }}
        >
          {props.title}
        </LayerName>
      )}

      {hovered || props.selected === props.id ? (
        <HoveredButtons>
          {!props.eyeOff ? (
            <>
              {props.visible ? (
                <EyeOn
                  onClick={(e) => {
                    e.preventDefault();
                    props.setVisible(false, props.index);
                  }}
                />
              ) : (
                <EyeOff
                  onClick={(e) => {
                    e.preventDefault();
                    props.setVisible(true, props.index);
                  }}
                />
              )}
            </>
          ) : (
            <></>
          )}
          {props.deleteLayer ? (
            <Popconfirm
              title="Are you sure to delete this item?"
              okText="Yes"
              cancelText="No"
              okButtonProps={{ danger: true }}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => {
                props.setSelected("");
                props.deleteLayer(props.index);
              }}
            >
              <Delete
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </Popconfirm>
          ) : (
            <></>
          )}
          {props.userMode !== undefined ? (
            <Tooltip title="Allow User Edit">
              <Switch
                size="small"
                checked={props.userMode}
                onChange={() => {
                  props.setUserMode(props.index, !props.userMode);
                }}
              />
            </Tooltip>
          ) : (
            <></>
          )}
        </HoveredButtons>
      ) : (
        ""
      )}
    </WrapperDiv>
  );
}
