import { useState } from "react";
import { selectDocumentById } from "../../redux/documentSlice";
import { useAppSelector } from "../../redux/hooks";
import { Link, useParams } from "react-router-dom";
import { Typography, Switch } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import styled from "styled-components";
import EditDocumentModal from "../Components/EditDocumentModal";
import { selectTemplateById } from "../../redux/templateSlice";
const { Paragraph } = Typography;

type TypeID = {
  id: string;
};

const ParagraphContainer = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
`;

const NavBack = styled.div`
  width: 100px;
  float: left;
  padding-top: 10px;
  font-size: 15px;
`;

const Header = styled.div<{ um: boolean }>`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.85);
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.66667;
  list-style: none;
  font-feature-settings: tnum, "tnum";
  position: relative;
  padding: 8px 16px;
  background-color: #1f1f1f;
  text-align: center;

  display: flex;
  justify-content: space-between;
  border-bottom: 1px
    ${(props) => {
      if (props.um) {
        return "gray";
      } else {
        return "red";
      }
    }}
    solid;
`;

const SwitchContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0px 10px;
`;
const IgniteLogo = styled.div`
  width: 55px;
  height: 35px;
  background: url(/img/IGNITE_logo.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

export function EditableHeader(props: {
  setUm: Function;
  um: boolean;
  isTemplate?: boolean;
}) {
  const { id } = useParams() as TypeID;
  const [isLoading, setIsLoading] = useState(false);
  const [currentState, setCurrentState] = useState(true);
  const selector = useAppSelector((state) => {
    if (props.isTemplate) {
      return selectTemplateById(state, id);
    } else {
      return selectDocumentById(state, id);
    }
  });
  return (
    <Header um={props.um}>
      <NavBack>
        {props.isTemplate ? (
          <Link to="/templates">
            <Paragraph>
              <LeftOutlined />
              {" Dashboard"}
            </Paragraph>
          </Link>
        ) : (
          <Link to="/dashboard">
            <Paragraph>
              <LeftOutlined />
              {" Dashboard"}
            </Paragraph>
          </Link>
        )}
      </NavBack>
      <ParagraphContainer>
        <IgniteLogo />
        <Paragraph
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginBottom: 1,
            padding: "0px 10px",
          }}
        >
          {selector?.name ? selector.name : ""}
        </Paragraph>
        <EditDocumentModal
          selector={selector}
          id={id}
          isTemplate={props.isTemplate}
        />
      </ParagraphContainer>
      <SwitchContainer>
        {!props.isTemplate ? (
          <>
            <Paragraph
              style={{
                display: "inline-flex",
                padding: "10px 20px 0px 0px",
                marginBottom: "1em",
              }}
            >
              {props.um ? "User" : "Designer"} Mode
            </Paragraph>
            <Switch
              loading={isLoading}
              checked={currentState}
              style={{
                backgroundColor: `${
                  !props.um
                    ? "rgb(222, 15, 14, 0.9)"
                    : "rgba(255, 255, 255, 0.3)"
                }`,
              }}
              onChange={(val) => {
                setIsLoading(true);
                props.setUm(!val);
                setTimeout(() => {
                  setCurrentState(!currentState);
                  setIsLoading(false);
                }, 800);
              }}
            />
          </>
        ) : (
          <></>
        )}
      </SwitchContainer>
    </Header>
  );
}
