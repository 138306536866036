import {
  CanvasKit,
  Surface,
  Canvas,
  Image as SkImage,
  Paragraph,
  TextAlign,
  TypefaceFontProvider,
} from "canvaskit-wasm";

import Graphic from "../Graphic/Graphic";
import Image from "../Graphic/Image";
import { Layer, ILayer, LayerType } from "../Layer/Layer";
import Text from "../Graphic/Text";
import Mask, { ShapeType } from "../Mask";
import { ToolType } from "../Event/CanvasEvent";
import isUrl from "is-url";

const CanvasKitInit = require("canvaskit-wasm/bin/full/canvaskit.js");

/*
 * Usage:
 * const ge = new GraphicsEngine(await GraphicsEngine.init())
 *
 * TODO: Get artboard out of GraphicsEngine class
 */

type CanvasState = {
  width: number;
  height: number;
  scale: number;
  artboardX: number;
  artboardY: number;
  selectedLayer: string;
  selectedGraphic: string;
  selectedTool: ToolType;
};

type GraphicData = [Graphic, SkImage | Paragraph | null];

export type FontList = {
  [index: string]: Font;
};
export type Font = { name: string; url: string; file?: ArrayBuffer | null };

export class GraphicsEngine {
  private canvaskit: CanvasKit;
  private surface: Surface | null;
  private canvas: Canvas;
  private fontMgr: TypefaceFontProvider | null;
  private fontsQuery: FontList;
  private renderQueue: Map<string, GraphicData>;
  private renderTopQueue: Array<any>;
  private canvasState: CanvasState;

  constructor(ck: CanvasKit, fonts: Font[], canvasId: string) {
    this.canvaskit = ck;
    this.surface = this.canvaskit.MakeCanvasSurface(canvasId);
    this.canvas = this.surface!.getCanvas();
    this.renderQueue = new Map();
    this.renderTopQueue = new Array();
    this.canvasState = {
      width: 0,
      height: 0,
      scale: 1,
      artboardX: 0,
      artboardY: 0,
      selectedLayer: "",
      selectedGraphic: "",
      selectedTool: ToolType.selectLayer,
    };
    this.fontsQuery = {};
    fonts.forEach((font) => {
      if (font.name !== undefined) {
        this.fontsQuery[font.url] = {
          name: font.name,
          url: font.url,
          file: null,
        };
      }
    });
    this.fontMgr = ck.TypefaceFontProvider.Make();

    if (!this.fontMgr) {
      console.log("Could not load font");
      return;
    }
    if (!this.surface) {
      console.log("Could not make surface");
      return;
    }
  }

  public static init(): Promise<CanvasKit> {
    let location: String = "";
    switch (process.env.NODE_ENV) {
      case "test": {
        location = "/public/bin/full/";
        break;
      }
      case "development": {
        location = "/bin/full/";
        break;
      }
      case "production": {
        location = "https://tig-client.s3.amazonaws.com/0.33/";
        break;
      }
    }

    return CanvasKitInit({
      locateFile: (file: string) => {
        return location + file;
      },
    });
  }

  public static async loadFonts(fontsList: Font[]): Promise<FontList> {
    return new Promise((resolve, reject) => {
      let skfonts: FontList = {};
      // let fonts
      fontsList.forEach(async (font) => {
        skfonts[font.url] = { name: font.name, url: font.url, file: null };
      });
      resolve(skfonts);
    });
  }

  renderText(newText: Text, layer: ILayer, layers: ILayer[]) {
    const ck = this.canvaskit;

    //Check if is url or name -- Fix for old layouts
    if (!isUrl(newText.fontFamily)) {
      //Check for url
      const keys = Object.keys(this.fontsQuery);
      keys.forEach((key) => {
        if (newText.fontFamily === this.fontsQuery[key].name) {
          newText.fontFamily = this.fontsQuery[key].url;
        }
      });
    }

    //Check if font has been loaded
    if (this.fontsQuery[newText.fontFamily] !== undefined) {
      if (
        this.fontsQuery[newText.fontFamily].url !== null &&
        this.fontsQuery[newText.fontFamily].file === null
      ) {
        this.fontsQuery[newText.fontFamily].file = new ArrayBuffer(0);
        fetch(this.fontsQuery[newText.fontFamily].url)
          .then((response) => response.arrayBuffer())
          .then((font) => {
            this.fontsQuery[newText.fontFamily].file = font;
            this.fontMgr?.registerFont(font, newText.fontFamily);
            this.renderLayers(layers, this.canvasState);
          });
      }
    }
    if (ck === null) {
      return;
    }
    if (this.surface === null) {
      return;
    }
    const text = newText;
    const textStyle = {
      color: ck.Color(
        text.color[0],
        text.color[1],
        text.color[2],
        layer.opacity
      ),
      fontFamilies: [text.fontFamily],
      fontSize: text.fontSize,
      // shadows: [{ color: ck.BLACK, blurRadius: 15 }],
      fontStyle: {
        weight: text.bold ? ck.FontWeight.Bold : ck.FontWeight.Normal,
        slant: text.italic ? ck.FontSlant.Italic : ck.FontSlant.Upright,
      },
      letterSpacing: text.letterSpacing | 0,
      heightMultiplier: text.lineHeight / 100,
    };
    const paraStyle = new ck.ParagraphStyle({
      textStyle: textStyle,
      textAlign: align(text.hAlign),
    });

    const builder = ck.ParagraphBuilder.MakeFromFontProvider(
      paraStyle,
      this.fontMgr!
    );

    const paint = new ck.Paint();
    paint.setColor(
      ck.Color(text.color[0], text.color[1], text.color[2], layer.opacity)
    );
    paint.setAntiAlias(true);
    paint.setBlendMode(this.getBlendMode(layer.blendMode));

    const paint2 = new ck.Paint();
    paint2.setColor(ck.TRANSPARENT);
    paint2.setAntiAlias(true);
    builder.pushPaintStyle(textStyle, paint, paint2);
    builder.addText(text.value);

    const paragraphNew = builder.build();
    paragraphNew.layout(text.width);
    let topOffset = 0;
    if (text.vAlign === "middle") {
      topOffset = (text.height - paragraphNew.getHeight()) / 2;
    }
    if (text.vAlign === "bottom") {
      topOffset = text.height - paragraphNew.getHeight();
    }
    if (text.vAlign === "top") {
      topOffset = 0;
    }

    this.canvas.save();
    if (text.rotate) {
      this.canvas.rotate(
        text.rotate,
        text.width / 2 + text.x,
        text.height / 2 + text.y
      );
    }

    this.canvas.drawParagraph(paragraphNew, newText.x, newText.y + topOffset);
    if (newText.id === this.canvasState.selectedGraphic) {
      this.renderTopQueue.push({
        width: newText.width,
        height: newText.height,
        x: newText.x,
        y: newText.y,
        selected: true,
        rotate: newText.rotate,
      });
    }

    this.canvas.restore();
    paint.delete();
    paint2.delete();
    paragraphNew.delete();
    builder.delete();
    function align(select: string): TextAlign {
      if (select === "right") {
        return ck.TextAlign.Right;
      }
      if (select === "left") {
        return ck.TextAlign.Left;
      }
      if (select === "center") {
        return ck.TextAlign.Center;
      }
      return ck.TextAlign.Left;
    }
  }

  renderImage(newImage: Image, imgsrc: SkImage, layer: Layer | ILayer) {
    const ck = this.canvaskit;
    const paint = new ck.Paint();

    paint.setAntiAlias(true);
    paint.setBlendMode(this.getBlendMode(layer.blendMode));
    paint.setColor(ck.Color(0, 0, 0, layer.opacity));

    if (ck === null) {
      return;
    }
    if (this.surface === null) {
      return;
    }

    if (imgsrc === null) {
      return;
    }
    if (newImage.rotate) {
      this.canvas.save();
      this.canvas.rotate(
        newImage.rotate,
        newImage.width / 2 + newImage.x,
        newImage.height / 2 + newImage.y
      );
    }
    const src = this.canvaskit.XYWHRect(
      0,
      0,
      newImage.srcWidth,
      newImage.srcHeight
    );

    const dest = this.canvaskit.XYWHRect(
      layer.x + newImage.x,
      layer.y + newImage.y,
      newImage.width,
      newImage.height
    );
    if (imgsrc === null) {
      return;
    }

    // From here: https://github.com/google/skia/blob/main/include/core/SkSamplingOptions.h
    this.canvas.drawImageRectCubic(imgsrc, src, dest, 1 / 3, 1 / 3, paint);

    if (
      newImage.id === this.canvasState.selectedGraphic &&
      layer.mask.currentTool !== ToolType.layer
    ) {
      this.renderTopQueue.push({
        width: newImage.width,
        height: newImage.height,
        x: newImage.x,
        y: newImage.y,
        selected: true,
        rotate: newImage.rotate,
      });
    }
    if (newImage.rotate) {
      this.canvas.restore();
    }
    paint.delete();
  }

  renderImageMask(newImage: Image, imgsrc: SkImage, layer: Layer | ILayer) {
    const ck = this.canvaskit;
    const paint = new ck.Paint();

    paint.setAntiAlias(true);

    if (ck === null) {
      return;
    }
    if (this.surface === null) {
      return;
    }

    if (imgsrc === null) {
      return;
    }

    const x = newImage.x;
    const y = newImage.y;

    const src = ck.XYWHRect(0, 0, imgsrc.width(), imgsrc.height());
    const dest = ck.XYWHRect(x, y, newImage.width, newImage.height);

    paint.setBlendMode(this.getBlendMode(layer.blendMode));
    paint.setColor(ck.Color(0, 0, 0, layer.opacity));
    const path = new ck.Path();

    this.canvas.save();
    if (layer.mask.type === ShapeType.rectangle) {
      const maskR = ck.XYWHRect(
        layer.mask.x,
        layer.mask.y,
        layer.mask.width,
        layer.mask.height
      );
      path.addRect(maskR);
    }
    if (layer.mask.type === ShapeType.circle) {
      const maskR = ck.XYWHRect(
        layer.mask.x,
        layer.mask.y,
        layer.mask.width,
        layer.mask.height
      );
      path.addOval(maskR);
    }
    this.canvas.clipPath(path, ck.ClipOp.Intersect, true);
    if (newImage.rotate) {
      this.canvas.rotate(
        newImage.rotate,
        newImage.width / 2 + x,
        newImage.height / 2 + y
      );
    }
    this.canvas.drawImageRectCubic(imgsrc, src, dest, 1 / 3, 1 / 3, paint);

    this.canvas.restore();
    if (newImage.id === this.canvasState.selectedGraphic) {
      if (this.canvasState.selectedTool === ToolType.mask) {
        this.renderTopQueue.push({
          width: layer.mask.width,
          height: layer.mask.height,
          x: layer.mask.x,
          y: layer.mask.y,
          selected: true,
          rotate: layer.mask.rotate,
        });
      } else {
        this.renderTopQueue.push({
          width: layer.mask.width,
          height: layer.mask.height,
          x: layer.mask.x,
          y: layer.mask.y,
          selected: false,
          rotate: layer.mask.rotate,
        });
        this.renderTopQueue.push({
          width: newImage.width,
          height: newImage.height,
          x: newImage.x,
          y: newImage.y,
          selected: true,
          rotate: newImage.rotate,
        });
      }
    }
    path.delete();
    paint.delete();
  }

  renderBox(
    width: number,
    height: number,
    x: number,
    y: number,
    isSelected: boolean,
    rotate?: number
  ) {
    const ck = this.canvaskit;

    const rPaint = new ck.Paint();
    const boxFillColor = ck.Color(67, 67, 67, 1.0);
    const outlineColor = ck.Color(255, 255, 255, 1.0);

    rPaint.setAntiAlias(true);

    if (rotate) {
      this.canvas.save();
      this.canvas.rotate(rotate, width / 2 + x, height / 2 + y);
    }
    rPaint.setStrokeWidth(2 / this.canvasState.scale);
    rPaint.setColor(boxFillColor);
    rPaint.setStyle(ck.PaintStyle.Stroke);
    const rect = ck.XYWHRect(x, y, width, height);
    this.canvas.drawRect(rect, rPaint);
    if (isSelected) {
      const boxWidth = 10 / this.canvasState.scale;
      rPaint.setColor(outlineColor);
      rPaint.setStyle(ck.PaintStyle.Fill);

      const sTopLeft = ck.XYWHRect(
        x - boxWidth / 2,
        y - boxWidth / 2,
        boxWidth,
        boxWidth
      );
      this.canvas.drawRect(sTopLeft, rPaint);

      rPaint.setColor(boxFillColor);
      rPaint.setStyle(ck.PaintStyle.Stroke);
      const sTopLeftOutline = ck.XYWHRect(
        x - boxWidth / 2,
        y - boxWidth / 2,
        boxWidth,
        boxWidth
      );
      this.canvas.drawRect(sTopLeftOutline, rPaint);

      rPaint.setColor(outlineColor);
      rPaint.setStyle(ck.PaintStyle.Fill);
      const sTopRight = ck.XYWHRect(
        x + width - boxWidth / 2,
        y - boxWidth / 2,
        boxWidth,
        boxWidth
      );
      this.canvas.drawRect(sTopRight, rPaint);

      rPaint.setColor(boxFillColor);
      rPaint.setStyle(ck.PaintStyle.Stroke);
      const sTopRightOutline = ck.XYWHRect(
        x + width - boxWidth / 2,
        y - boxWidth / 2,
        boxWidth,
        boxWidth
      );
      this.canvas.drawRect(sTopRightOutline, rPaint);

      rPaint.setColor(outlineColor);
      rPaint.setStyle(ck.PaintStyle.Fill);
      const sBotLeft = ck.XYWHRect(
        x - boxWidth / 2,
        y + height - boxWidth / 2,
        boxWidth,
        boxWidth
      );
      this.canvas.drawRect(sBotLeft, rPaint);

      rPaint.setColor(boxFillColor);
      rPaint.setStyle(ck.PaintStyle.Stroke);
      const sBotLeftOutline = ck.XYWHRect(
        x - boxWidth / 2,
        y + height - boxWidth / 2,
        boxWidth,
        boxWidth
      );
      this.canvas.drawRect(sBotLeftOutline, rPaint);

      rPaint.setColor(outlineColor);
      rPaint.setStyle(ck.PaintStyle.Fill);
      const sBotRight = ck.XYWHRect(
        x + width - boxWidth / 2,
        y + height - boxWidth / 2,
        boxWidth,
        boxWidth
      );
      this.canvas.drawRect(sBotRight, rPaint);

      rPaint.setColor(boxFillColor);
      rPaint.setStyle(ck.PaintStyle.Stroke);
      const sBotRightOutline = ck.XYWHRect(
        x + width - boxWidth / 2,
        y + height - boxWidth / 2,
        boxWidth,
        boxWidth
      );
      this.canvas.drawRect(sBotRightOutline, rPaint);
    }
    rPaint.delete();
    if (rotate) {
      this.canvas.restore();
    }
  }

  addGraphic(newGraphic: Graphic, layers: ILayer[]) {
    const ck = this.canvaskit;
    switch (newGraphic.type) {
      case "image": {
        if (
          (newGraphic as Image).url !== null &&
          (newGraphic as Image).url !== ""
        ) {
          this.renderQueue.set(newGraphic.id, [newGraphic, null]);
          fetch((newGraphic as Image).url)
            .then((response) => response.arrayBuffer())
            .then((buffer) => {
              const imgsrc = ck.MakeImageFromEncoded(buffer);
              this.renderQueue.set(newGraphic.id, [newGraphic, imgsrc]);
              this.renderLayers(layers, this.canvasState);
            });
        }
        break;
      }
      case "text": {
        const text = newGraphic as Text;
        const paraStyle = new ck.ParagraphStyle({
          textStyle: {
            color: ck.Color(
              text.color[0],
              text.color[1],
              text.color[2],
              text.color[3]
            ),
            fontFamilies: [text.fontFamily],
            fontSize: text.fontSize,
            // shadows: [{ color: ck.BLACK, blurRadius: 15 }],
            fontStyle: {
              weight: text.bold ? ck.FontWeight.Bold : ck.FontWeight.Normal,
              slant: text.italic ? ck.FontSlant.Italic : ck.FontSlant.Upright,
            },
            letterSpacing: text.letterSpacing | 0,
          },
          textAlign: align(text.hAlign),
          heightMultiplier: text.lineHeight / 100,
        });

        const builder = ck.ParagraphBuilder.MakeFromFontProvider(
          paraStyle,
          this.fontMgr!
        );

        builder.addText(text.value);

        const paragraph = builder.build();
        paragraph.layout(text.width);
        this.renderQueue.set(newGraphic.id, [newGraphic, paragraph]);
        break;
      }
      default:
        return;
    }
    function align(select: string): TextAlign {
      if (select === "right") {
        return ck.TextAlign.Right;
      }
      if (select === "left") {
        return ck.TextAlign.Left;
      }
      if (select === "center") {
        return ck.TextAlign.Center;
      }
      return ck.TextAlign.Left;
    }
  }

  renderArtboard(width: number, height: number) {
    const paint = new this.canvaskit.Paint();

    this.canvas.clear(this.canvaskit.Color(0, 0, 0, 1));
    paint.setColor(this.canvaskit.WHITE);

    const rr = this.canvaskit.XYWHRect(0, 0, width, height);

    this.canvas.drawRect(rr, paint);
    const filter = this.canvaskit.MaskFilter.MakeBlur(
      this.canvaskit.BlurStyle.Outer,
      10,
      false
    );

    paint.setColor(this.canvaskit.Color4f(0, 0, 0, 0.3));
    paint.setMaskFilter(filter);
    this.canvas.drawRect(rr, paint);

    paint.delete();
    filter.delete();
  }

  renderBoundaries(width: number, height: number) {
    const paint = new this.canvaskit.Paint();
    const path = new this.canvaskit.Path();
    const maskR = this.canvaskit.XYWHRect(0, 0, width, height);

    path.addRect(maskR);
    this.canvas.clipPath(path, this.canvaskit.ClipOp.Intersect, false);

    path.delete();
    paint.delete();
  }

  renderLayers(layerList: ILayer[], canvasState: CanvasState) {
    requestAnimationFrame(() => {
      this.canvasState = canvasState;
      this.canvas.save();
      this.canvas.scale(canvasState.scale, canvasState.scale);
      this.canvas.translate(canvasState.artboardX, canvasState.artboardY);
      this.renderArtboard(canvasState.width, canvasState.height);

      this.canvas.saveLayer();

      this.renderBoundaries(canvasState.width, canvasState.height);
      if (layerList.length > 0) {
        for (var i = layerList.length - 1; i >= 0; i--) {
          //Loop for layers
          const layer = layerList[i];
          if (layer.visible && layer) {
            const layerGraphics = layer.graphics;
            if (
              layer.type === LayerType.Dropdown &&
              layer.mask.currentTool === ToolType.layer &&
              layer.id === canvasState.selectedLayer
            ) {
              this.renderTopQueue.push({
                width: layer.width,
                height: layer.height,
                x: layer.x,
                y: layer.y,
                selected: true,
              });
            }
            for (var j = layerGraphics.length - 1; j >= 0; j--) {
              //Loop for graphics
              const graphic = this.renderQueue.get(layerGraphics[j].id);
              if (
                layerGraphics.length === 1 ||
                layerGraphics[j].id === layer.selectedId
              ) {
                if (this.renderQueue.has(layerGraphics[j].id)) {
                  this.renderGraphic(
                    graphic,
                    layerGraphics[j],
                    layer,
                    layerList
                  );
                } else {
                  this.addGraphic(layerGraphics[j], layerList);
                  this.renderGraphic(
                    this.renderQueue.get(layerGraphics[j].id),
                    layerGraphics[j],
                    layer,
                    layerList
                  );
                }
              }
            }
          }
        }
      }
      this.canvas.restore();
      while (this.renderTopQueue.length > 0) {
        const box = this.renderTopQueue.pop();
        this.renderBox(
          box.width,
          box.height,
          box.x,
          box.y,
          box.selected,
          box.rotate
        );
      }
      this.canvas.restore();
      this.surface?.flush();
    });
  }

  renderGraphic(
    graphic: GraphicData | undefined,
    graphicLayer: Graphic,
    layer: Layer | ILayer,
    layerList: ILayer[]
  ) {
    if (graphic !== undefined) {
      switch (graphicLayer.type) {
        case "image": {
          if (layer.mask.type !== ShapeType.none) {
            this.renderImageMask(
              graphicLayer as Image,
              graphic[1] as SkImage,
              layer
            );
          } else {
            if (
              layer.type === LayerType.Dropdown &&
              layer.selectedId === graphicLayer.id
            ) {
              this.renderImage(
                graphicLayer as Image,
                graphic[1] as SkImage,
                layer
              );
            } else if (layer.type === LayerType.ImageUpload) {
              this.renderImage(
                graphicLayer as Image,
                graphic[1] as SkImage,
                layer
              );
            }
          }
          break;
        }
        case "text": {
          this.renderText(graphicLayer as Text, layer, layerList);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  resize() {
    this.surface?.delete();
    this.surface = this.canvaskit.MakeCanvasSurface("mainCanvas");
    this.canvas = this.surface!.getCanvas();
  }

  addNewFont(fontName: string, url: string) {
    if (this.fontsQuery[url] === undefined) {
      this.fontsQuery[url] = {
        name: fontName,
        url: url,
        file: null,
      };
    }
  }
  getBlendMode(blend: string) {
    switch (blend) {
      case "Normal": {
        return this.canvaskit.BlendMode.SrcOver;
      }
      case "Multiply": {
        return this.canvaskit.BlendMode.Multiply;
      }
      case "Screen": {
        return this.canvaskit.BlendMode.Screen;
      }
      case "Overlay": {
        return this.canvaskit.BlendMode.Overlay;
      }
      case "Darken": {
        return this.canvaskit.BlendMode.Darken;
      }
      case "Lighten": {
        return this.canvaskit.BlendMode.Lighten;
      }
      case "ColorDodge": {
        return this.canvaskit.BlendMode.ColorDodge;
      }
      case "ColorBurn": {
        return this.canvaskit.BlendMode.ColorBurn;
      }
      case "HardLight": {
        return this.canvaskit.BlendMode.HardLight;
      }
      case "SoftLight": {
        return this.canvaskit.BlendMode.SoftLight;
      }
      case "Difference": {
        return this.canvaskit.BlendMode.Difference;
      }
      case "Exclusion": {
        return this.canvaskit.BlendMode.Exclusion;
      }
      case "Hue": {
        return this.canvaskit.BlendMode.Hue;
      }
      case "Saturation": {
        return this.canvaskit.BlendMode.Saturation;
      }
      case "Color": {
        return this.canvaskit.BlendMode.Color;
      }
      case "Luminosity": {
        return this.canvaskit.BlendMode.Luminosity;
      }
      default: {
        return this.canvaskit.BlendMode.SrcOver;
      }
    }
  }

  exportToPNG(
    layerList: ILayer[],
    canvasState: CanvasState,
    screenshot?: boolean
  ) {
    let scale = 1;
    let surfaceId = "canvasFull";
    if (screenshot) {
      scale = 0.25;
      surfaceId = "canvasBG";
    }
    let newSurface = this.canvaskit.MakeCanvasSurface(surfaceId);
    let newCanvas = newSurface!?.getCanvas();
    this.canvas = newCanvas;

    this.canvasState = canvasState;
    this.canvas.save();
    this.canvas.scale(scale, scale);
    this.canvas.translate(0, 0);
    this.renderArtboard(canvasState.width, canvasState.height);

    if (layerList.length > 0) {
      for (var i = layerList.length - 1; i >= 0; i--) {
        //Loop for layers
        const layer = layerList[i];
        if (layer.visible && layer) {
          const layerGraphics = layer.graphics;
          if (
            layer.type === LayerType.Dropdown &&
            layer.mask.currentTool === ToolType.layer &&
            layer.id === canvasState.selectedLayer
          ) {
            this.renderTopQueue.push({
              width: layer.width,
              height: layer.height,
              x: layer.x,
              y: layer.y,
              selected: true,
            });
          }
          for (var j = layerGraphics.length - 1; j >= 0; j--) {
            //Loop for graphics
            const graphic = this.renderQueue.get(layerGraphics[j].id);
            if (
              layerGraphics.length === 1 ||
              layerGraphics[j].id === layer.selectedId
            ) {
              if (this.renderQueue.has(layerGraphics[j].id)) {
                this.renderGraphic(graphic, layerGraphics[j], layer, layerList);
              } else {
                this.addGraphic(layerGraphics[j], layerList);
                this.renderGraphic(
                  this.renderQueue.get(layerGraphics[j].id),
                  layerGraphics[j],
                  layer,
                  layerList
                );
              }
            }
          }
        }
      }
    }
    this.canvas.restore();
    const img = newSurface?.makeImageSnapshot();

    newSurface?.flush();

    const out = img?.encodeToBytes(this.canvaskit.ImageFormat.PNG, 10);
    newSurface?.delete();

    this.surface = this.canvaskit.MakeCanvasSurface("mainCanvas");
    this.canvas = this.surface!.getCanvas();
    this.renderLayers(layerList, canvasState);

    return out;
  }

  delete() {
    this.renderQueue.forEach((value) => {
      value[1]?.delete();
    });
    this.renderQueue.clear();
    while (this.renderTopQueue.length) {
      this.renderTopQueue.pop();
    }
    this.surface?.delete();
  }
}
