/*
 * TODO: Cleanup all unused values
 */
import { v4 as uuidv4 } from "uuid";
class Graphic {
  id: string = "";
  type: string = "";
  name: string = "Default";
  value: string = "Default";
  x: number = 0;
  y: number = 0;
  width: number = 100;
  height: number = 20;
  visible: boolean = true;
  hovered: boolean = false;
  selected: boolean = false;
  rotate: number = 0;

  constructor() {
    this.id = uuidv4();
  }

  resize(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  reposition(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  toJSON() {
    return {
      ...this,
    };
  }
}

export default Graphic;
