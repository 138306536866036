import React, { useEffect, useState } from "react";
import { IDocument } from "../../core/Document";
import { TemplateCard } from "./TemplateCard";
import { AddTemplate } from "./AddTemplate";
import { getTemplates, selectAllTemplates } from "../../redux/templateSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";

import { isAdmin } from "../../utils/isAdmin";

export function Templates() {
  const templateList = useAppSelector(selectAllTemplates) as IDocument[];
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getTemplates());
  }, []);
  const currentUserIsAdmin = isAdmin();
  return (
    <>
      {currentUserIsAdmin ? <AddTemplate /> : <></>}
      {templateList.map((document, index) => {
        if (document.visible) {
          return (
            <div key={index}>
              <TemplateCard document={document} />
            </div>
          );
        } else {
          if (currentUserIsAdmin) {
            return (
              <div key={index}>
                <TemplateCard document={document} />
              </div>
            );
          }
        }
      })}
    </>
  );
}
