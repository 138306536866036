import GetThunkApi, {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { Document, IDocument } from "../core/Document/Document";
import { displayError, getTokenAndId, handleResponseErrors } from "./helpers";

type DocumentState = {
  [index: string]: IDocument;
};
type DocumentEntry = {
  name: string;
  width: number;
  height: number;
};

const initialState: DocumentState = {};

var baseUrl = "/.netlify/functions/";

/*
 * Async
 */

export const addDocument = createAsyncThunk<any, DocumentEntry>(
  "documents/addDocument",
  async (docToAdd, thunkApi) => {
    const newDoc = new Document(docToAdd.name, docToAdd.width, docToAdd.height);
    const userData = await getTokenAndId();

    const data = {
      ...newDoc.toJSON(),
      accountId: userData.id,
      created: Date.now().toString(),
      updated: Date.now().toString(),
    };

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "document-create", requestOptions);

    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

export const getDocuments = createAsyncThunk<any>(
  "documents/getDocuments",
  async (data, thunkApi) => {
    const userData = await getTokenAndId();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    const response = await fetch(baseUrl + "document-read", requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

export const deleteDocument = createAsyncThunk<any, string>(
  "documents/deleteDocument",
  async (deleteId, thunkApi) => {
    const userData = await getTokenAndId();
    const data = {
      id: deleteId,
      accountId: userData.id,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "document-delete", requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return data.id;
  }
);

export const updateDocument = createAsyncThunk<
  any,
  [string, keyof IDocument, any]
>(
  "documents/updateDocument",
  async ([docId, propertyName, propertyValue], thunkApi) => {
    const userData = await getTokenAndId();
    const data = {
      id: docId,
      accountId: userData.id,
      propertyName,
      propertyValue,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(baseUrl + "document-update", requestOptions).then(
      async (response) => {
        if (response.status !== 200) {
          return await handleResponseErrors(thunkApi, response);
        }
      }
    );

    return data;
  }
);

export const updateDocumentMultiParams = createAsyncThunk<
  any,
  [string, Array<keyof IDocument>, Array<any>]
>(
  "layers/updateDocumentMultiParams",
  async ([docId, propertyName, propertyValue], thunkApi) => {
    const userData = await getTokenAndId();
    const data = {
      id: docId,
      propertyName,
      propertyValue,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    fetch(baseUrl + "document-update", requestOptions).then(
      async (response) => {
        if (response.status !== 200) {
          return await handleResponseErrors(thunkApi, response);
        }
      }
    );

    return data;
  }
);

/*
 * User Queries
 */

export const getUserDocuments = createAsyncThunk<any, [string, string]>(
  "documents/getUserDocuments",
  async ([accountId, userId], thunkApi) => {
    const userData = await getTokenAndId();

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    const response = await fetch(
      baseUrl + "user-get-documents?id=" + accountId + "&userid=" + userId,
      requestOptions
    );
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

/*
 * Reducer
 */
const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    updateDocumentLocal(state, action) {
      const key = action.payload.propertyName;
      const value = action.payload.propertyValue;
      Object.assign(state[action.payload.id], { [key]: value });
    },
    updateDocumentMultiParamsLocal(state, { payload }) {
      payload.propertyName.map((name: string, i: number) => {
        Object.assign(state[payload.id], {
          [name]: payload.propertyValue[i],
        });
      });
    },
  },
  extraReducers: (builder) => {
    /*
     * FulFilled
     */
    builder.addCase(addDocument.fulfilled, (state, { payload }) => {
      state[payload.id] = payload;
    });
    builder.addCase(getDocuments.fulfilled, (state, { payload }) => {
      payload.map((document: any) => {
        state[document.id] = document;
      });
      state = payload;
    });
    builder.addCase(deleteDocument.fulfilled, (state, { payload }) => {
      delete state[payload];
    });
    builder.addCase(updateDocument.fulfilled, (state, action) => {
      const key = action.payload.propertyName;
      const value = action.payload.propertyValue;
      Object.assign(state[action.payload.id], { [key]: value });
    });
    builder.addCase(
      updateDocumentMultiParams.fulfilled,
      (state, { payload }) => {
        payload.propertyName.map((name: string, i: number) => {
          Object.assign(state[payload.id], {
            [name]: payload.propertyValue[i],
          });
        });
      }
    );
    builder.addCase(getUserDocuments.fulfilled, (state, { payload }) => {
      payload.map((document: any) => {
        state[document.id] = document;
      });
      state = payload;
    });

    /*
     * Rejected
     */
    builder.addCase(getDocuments.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(addDocument.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(deleteDocument.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(updateDocument.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(updateDocumentMultiParams.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(getUserDocuments.rejected, (state, action) => {
      displayError(action.payload as string);
    });
  },
});

/*
 * Selectors
 */

//Select all?
const selectDocuments = (state: any) => state.documents;
export const selectAllDocuments = createSelector(selectDocuments, (entities) =>
  Object.values(entities)
);

export const selectDocumentById = createSelector(
  [(state) => state.documents, (state: any, docId: string) => docId],
  (items, docId) => {
    return items[docId];
  }
);

/*
 * Exports
 */

// export const { addDocument } = documentsSlice.actions;
export default documentsSlice.reducer;
