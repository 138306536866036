import { ILayer, LayerType } from "../Layer/Layer";
import Graphic from "../Graphic/Graphic";
import Image from "../Graphic/Image";
import Mask from "../Mask";
import store from "../../redux/Store";
import { updateLayer, updateLayerMultiParams } from "../../redux/layerSlice";

/*
 * TODO: Cleanup this. It is hard to test & Debug
 */

export enum CanvasMouseEventState {
  drag = "drag",
  hover = "hover",
  click = "click",
  none = "none",
}
export enum CanvasKeyEventState {
  space = "space",
  none = "none",
}

export enum ToolType {
  autoSelect = "auto-select",
  selectLayer = "selectLayer",
  mask = "mask",
  image = "image",
  layer = "layer",
}

export type CanvasEventState = {
  mouseEvent: CanvasMouseEventState;
  keyEvent: CanvasKeyEventState;
  isPointerInCanvas: boolean;
  selectedGraphicID: string;
  selectedGraphicIndex: number;
  selectedLayerID: string;
  hoveredGraphicID: string;
  x: number;
  y: number;
  offsetX: number;
  offsetY: number;
  imgOffsetX: number;
  imgOffsetY: number;
  cornerId: number;
  selectedGraphicWidth: number;
  selectedGraphicHeight: number;
  isIU: boolean;
  latestGraphicUpdate: Graphic | null;
  latestLayerUpdate: ILayer | null;
  selectedTool: ToolType;
};

export default class CanvasEvent {
  state: CanvasEventState = {
    mouseEvent: CanvasMouseEventState.none,
    keyEvent: CanvasKeyEventState.none,
    isPointerInCanvas: false,
    x: 0,
    y: 0,
    offsetX: 0,
    offsetY: 0,
    imgOffsetX: 0,
    imgOffsetY: 0,
    selectedGraphicID: "",
    selectedGraphicIndex: 0,
    selectedLayerID: "",
    hoveredGraphicID: "",
    cornerId: -1,
    selectedGraphicWidth: 0,
    selectedGraphicHeight: 0,
    isIU: false,
    latestGraphicUpdate: null,
    latestLayerUpdate: null,
    selectedTool: ToolType.selectLayer,
  };
  canvasHTML = () => document.getElementById("mainCanvas")!;

  newMouseEvent(
    type: string,
    x: number,
    y: number,
    pressure: number,
    layerData: ILayer[],
    scale: number,
    artboard: [number, number],
    selectedTool: ToolType,
    selectedLayer: string,
    selectedGraphic: string,
    repositionArtboard: Function
  ) {
    this.state.selectedGraphicID = selectedGraphic;
    this.state.selectedLayerID = selectedLayer;
    const spacePressed = this.state.keyEvent === CanvasKeyEventState.space;
    // if (type === "pointerenter") {
    //   this.state.isPointerInCanvas = true;
    //   this.state.mouseEvent = CanvasMouseEventState.hover;
    //   document.getElementById("mainCanvas")!.style.cursor = "default";
    //   return;
    // }
    // if (type === "pointerleave") {
    //   this.state.isPointerInCanvas = false;
    //   this.state.mouseEvent = CanvasMouseEventState.none;
    //   document.getElementById("mainCanvas")!.style.cursor = "default";
    //   return;
    // }
    if (
      type !== "pointermove" &&
      (type === "lostpointercapture" ||
        type === "pointerup" ||
        type === "touchend" ||
        !pressure)
    ) {
      this.state.mouseEvent = CanvasMouseEventState.hover;
      this.updateDB();
      this.state.latestGraphicUpdate = null;
      this.state.latestLayerUpdate = null;
    }
    if (
      (type === "gotpointercapture" ||
        type === "pointerdown" ||
        type === "touchstart") &&
      pressure
    ) {
      this.state.mouseEvent = CanvasMouseEventState.click;
      if (spacePressed) {
        this.state.offsetX = x;
        this.state.offsetY = y;
        return true;
      }
    }
    if (
      type === "pointermove" &&
      pressure &&
      (this.state.mouseEvent === CanvasMouseEventState.drag ||
        this.state.mouseEvent === CanvasMouseEventState.click)
    ) {
      if (spacePressed) {
        if (this.state.mouseEvent === CanvasMouseEventState.click) {
          this.state.imgOffsetX = artboard[0];
          this.state.imgOffsetY = artboard[1];
        }
        this.state.x = this.state.imgOffsetX + (x - this.state.offsetX) / scale;
        this.state.y = this.state.imgOffsetY + (y - this.state.offsetY) / scale;
        repositionArtboard(this.state.x, this.state.y);
        this.state.mouseEvent = CanvasMouseEventState.drag;
        this.state.selectedTool = selectedTool;
        return true;
      }
      this.state.mouseEvent = CanvasMouseEventState.drag;
      this.state.selectedTool = selectedTool;
    }
    this.state.x = Math.trunc(x / scale) - artboard[0];
    this.state.y = Math.trunc(y / scale) - artboard[1];
    if (selectedTool === ToolType.mask) {
      this.updateCanvasMask(layerData, scale);
    } else {
      this.updateCanvasSelectLayer(layerData, scale);
    }
    if (this.state.mouseEvent === CanvasMouseEventState.hover) {
      return false;
    } else {
      return true;
    }
  }

  newKeyboardEvent(type: string, key: string, e: KeyboardEvent) {
    if (type === "keydown") {
      if (key === " ") {
        // e.preventDefault();
        this.state.keyEvent = CanvasKeyEventState.space;
      }
    }
    if (type === "keyup") {
      if (key === " ") {
        this.state.keyEvent = CanvasKeyEventState.none;
      }
    }
  }

  updateCanvasSelectLayer(layerData: ILayer[], scale: number) {
    for (var i = 0; i < layerData.length; i++) {
      if (layerData[i].visible) {
        let layerGraphics = layerData[i].graphics;
        if (
          layerData[i].mask.currentTool === ToolType.layer &&
          layerData[i].id === this.state.selectedLayerID
        ) {
          switch (this.state.mouseEvent) {
            case CanvasMouseEventState.hover: {
              this.checkCornerBounds(
                [this.state.x, this.state.y],
                layerData[i],
                scale
              );
              this.state.cornerId = -1;
              break;
            }
            case CanvasMouseEventState.click: {
              const selectedCorner = this.checkCornerBounds(
                [this.state.x, this.state.y],
                layerData[i],
                scale
              );
              if (selectedCorner > 0) {
                //if corner has been Clicked
                this.state.selectedGraphicWidth = layerData[i].width;
                this.state.selectedGraphicHeight = layerData[i].height;
                this.state.offsetX = this.state.x;
                this.state.offsetY = this.state.y;
                this.state.cornerId = selectedCorner;
                this.state.latestLayerUpdate = layerData[i];
              } else {
                this.state.offsetX = layerData[i].x - this.state.x;
                this.state.offsetY = layerData[i].y - this.state.y;
                this.state.latestLayerUpdate = layerData[i];
              }
              break;
            }
            case CanvasMouseEventState.drag: {
              if (this.state.cornerId > 0) {
                // const scale = this.resizeLayer(layerData[i], true);
                // layerData[i].scale = scale || 1;
                // this.state.latestLayerUpdate = layerData[i];
              } else {
                layerData[i].x = this.state.x + this.state.offsetX;
                layerData[i].y = this.state.y + this.state.offsetY;
                this.state.latestLayerUpdate = layerData[i];
              }
              break;
            }
            case CanvasMouseEventState.none: {
              break;
            }
          }
        } else {
          for (var j = 0; j < layerGraphics.length; j++) {
            if (
              layerGraphics[j].visible ||
              layerData[i].selectedId === layerGraphics[j].id
            ) {
              switch (this.state.mouseEvent) {
                case CanvasMouseEventState.hover: {
                  if (this.state.selectedGraphicID === layerGraphics[j].id) {
                    this.checkCornerBounds(
                      [this.state.x, this.state.y],
                      layerGraphics[j],
                      scale
                    );
                  }
                  this.state.cornerId = -1;
                  break;
                }
                case CanvasMouseEventState.click: {
                  const isSelected =
                    this.state.selectedGraphicID === layerGraphics[j].id;
                  if (isSelected) {
                    const selectedCorner = this.checkCornerBounds(
                      [this.state.x, this.state.y],
                      layerGraphics[j],
                      scale
                    );
                    if (selectedCorner > 0) {
                      //if corner has been Clicked
                      this.state.selectedGraphicWidth = layerGraphics[j].width;
                      this.state.selectedGraphicHeight =
                        layerGraphics[j].height;
                      this.state.offsetX = this.state.x;
                      this.state.offsetY = this.state.y;
                      this.state.cornerId = selectedCorner;
                      this.state.latestLayerUpdate = layerData[i];
                      this.state.selectedGraphicIndex = j;
                    } else {
                      this.state.selectedGraphicID = layerGraphics[j].id;
                      this.state.latestLayerUpdate = layerData[i];
                      this.state.selectedGraphicIndex = j;
                      this.state.offsetX = layerGraphics[j].x - this.state.x;
                      this.state.offsetY = layerGraphics[j].y - this.state.y;
                    }
                  }
                  break;
                }
                case CanvasMouseEventState.drag: {
                  const isSelected =
                    this.state.selectedGraphicID === layerGraphics[j].id;
                  if (this.state.cornerId > 0 && isSelected) {
                    this.resizeGraphic(
                      layerGraphics[j],
                      layerGraphics[j].type === "image" ? true : false
                    );
                    this.state.latestGraphicUpdate = layerGraphics[j];
                    this.state.latestLayerUpdate = layerData[i];
                  } else if (isSelected) {
                    layerGraphics[j].x = this.state.x + this.state.offsetX;
                    layerGraphics[j].y = this.state.y + this.state.offsetY;
                    this.state.latestGraphicUpdate = layerGraphics[j];
                    this.state.latestLayerUpdate = layerData[i];
                  }
                  break;
                }
                case CanvasMouseEventState.none: {
                  break;
                }
              }
            }
          }
          layerData[i].graphics = layerGraphics;
        }
      }
    }
  }

  updateCanvasMask(layerData: ILayer[], scale: number) {
    for (var i = 0; i < layerData.length; i++) {
      if (layerData[i].visible) {
        const layerGraphics = layerData[i].graphics;
        for (var j = 0; j < layerGraphics.length; j++) {
          const isSelected =
            this.state.selectedGraphicID === layerGraphics[j].id;
          if (layerGraphics[j].visible && isSelected) {
            switch (this.state.mouseEvent) {
              case CanvasMouseEventState.hover: {
                if (this.state.selectedGraphicID === layerGraphics[j].id) {
                  this.checkCornerBounds(
                    [this.state.x, this.state.y],
                    layerData[i].mask,
                    scale
                  );
                }
                this.state.cornerId = -1;
                break;
              }
              case CanvasMouseEventState.click: {
                const selectedCorner = this.checkCornerBounds(
                  [this.state.x, this.state.y],
                  layerData[i].mask,
                  scale
                );

                this.state.cornerId = selectedCorner;
                if (this.state.cornerId > 0 && isSelected) {
                  this.state.selectedGraphicWidth = layerData[i].mask.width;
                  this.state.selectedGraphicHeight = layerData[i].mask.height;
                  this.state.offsetX = this.state.x;
                  this.state.offsetY = this.state.y;
                  this.state.latestGraphicUpdate = layerGraphics[j];
                  this.state.latestLayerUpdate = layerData[i];
                } else if (isSelected) {
                  this.state.offsetX = layerData[i].mask.x - this.state.x;
                  this.state.offsetY = layerData[i].mask.y - this.state.y;
                  this.state.imgOffsetX = layerGraphics[j].x - this.state.x;
                  this.state.imgOffsetY = layerGraphics[j].y - this.state.y;
                  this.state.latestGraphicUpdate = layerGraphics[j];
                  this.state.latestLayerUpdate = layerData[i];
                }
                break;
              }
              case CanvasMouseEventState.drag: {
                if (this.state.cornerId > 0 && isSelected) {
                  this.resizeGraphic(layerData[i].mask, false);
                } else if (isSelected) {
                  layerGraphics[j].x = this.state.x + this.state.imgOffsetX;
                  layerGraphics[j].y = this.state.y + this.state.imgOffsetY;
                  layerData[i].mask.x = this.state.x + this.state.offsetX;
                  layerData[i].mask.y = this.state.y + this.state.offsetY;
                }
              }
            }
          }
        }
      }
    }
  }

  checkCornerBounds(
    position: [number, number],
    graphic: Graphic | Mask | ILayer,
    scale: number
  ) {
    /*
     *  1------2
     *  |      |
     *  3------4
     *
     */
    const boxWidth = 10 / 2 / scale;

    let x1 = graphic.x;
    let y1 = graphic.y;

    let x2 = graphic.x + graphic.width;
    let y2 = graphic.y;

    let x3 = graphic.x;
    let y3 = graphic.y + graphic.height;

    let x4 = graphic.x + graphic.width;
    let y4 = graphic.y + graphic.height;
    if (graphic.rotate > 0) {
      let centerX = graphic.x + graphic.width / 2;
      let centerY = graphic.y + graphic.height / 2;

      const angle = graphic.rotate * (Math.PI / 180); //in radians

      /*
       * 1
       */
      // let tempX = x1 - centerX;
      // let tempY = y1 - centerY;

      // let rotatedX = tempX * Math.cos(angle) - tempY * Math.sin(angle);
      // let rotatedY = tempX * Math.sin(angle) + tempY * Math.cos(angle);

      // x1 = rotatedX + centerX;
      // y1 = rotatedY + centerY;

      [x1, y1] = this.rotate(x1, y1, centerX, centerY, angle);
      /*
       * 2
       */
      // tempX = x2 - centerX;
      // tempY = y2 - centerY;

      // rotatedX = tempX * Math.cos(angle) - tempY * Math.sin(angle);
      // rotatedY = tempX * Math.sin(angle) + tempY * Math.cos(angle);

      // x2 = rotatedX + centerX;
      // y2 = rotatedY + centerY;
      [x2, y2] = this.rotate(x2, y2, centerX, centerY, angle);

      /*
       * 3
       */
      // tempX = x3 - centerX;
      // tempY = y3 - centerY;

      // rotatedX = tempX * Math.cos(angle) - tempY * Math.sin(angle);
      // rotatedY = tempX * Math.sin(angle) + tempY * Math.cos(angle);

      // x3 = rotatedX + centerX;
      // y3 = rotatedY + centerY;
      [x3, y3] = this.rotate(x3, y3, centerX, centerY, angle);
      /*
       * 4
       */
      // tempX = x4 - centerX;
      // tempY = y4 - centerY;

      // rotatedX = tempX * Math.cos(angle) - tempY * Math.sin(angle);
      // rotatedY = tempX * Math.sin(angle) + tempY * Math.cos(angle);

      // x4 = rotatedX + centerX;
      // y4 = rotatedY + centerY;
      [x4, y4] = this.rotate(x4, y4, centerX, centerY, angle);
    }

    const checkTL =
      position[0] >= x1 - boxWidth &&
      position[0] <= x1 + boxWidth &&
      position[1] >= y1 - boxWidth &&
      position[1] <= y1 + boxWidth;
    const checkTR =
      position[0] >= x2 - boxWidth &&
      position[0] <= x2 + boxWidth &&
      position[1] >= y2 - boxWidth &&
      position[1] <= y2 + boxWidth;
    const checkBR =
      position[0] >= x4 - boxWidth &&
      position[0] <= x4 + boxWidth &&
      position[1] >= y4 - boxWidth &&
      position[1] <= y4 + boxWidth;
    const checkBL =
      position[0] >= x3 - boxWidth &&
      position[0] <= x3 + boxWidth &&
      position[1] >= y3 - boxWidth &&
      position[1] <= y3 + boxWidth;
    if (checkTL) {
      this.canvasHTML().style.cursor = "nw-resize";
      return 1;
    }
    if (checkTR) {
      this.canvasHTML().style.cursor = "ne-resize";
      return 2;
    }
    if (checkBR) {
      this.canvasHTML().style.cursor = "se-resize";
      return 3;
    }
    if (checkBL) {
      this.canvasHTML().style.cursor = "sw-resize";
      return 4;
    }
    this.canvasHTML().style.cursor = "default";
    return -1;
  }
  resizeGraphic(graphic: Graphic | Mask | ILayer, keepAspectRatio: boolean) {
    const currWH = [
      this.state.selectedGraphicWidth,
      this.state.selectedGraphicHeight,
    ];
    const widthMin = 10;
    const heightMin = 10;
    switch (this.state.cornerId) {
      case 1: {
        //TL

        if (graphic.rotate) {
          const topLeftX = Math.round(this.state.x);
          const topLeftY = Math.round(this.state.y);

          let cx = graphic.x + graphic.width / 2;
          let cy = graphic.y + graphic.height / 2;
          const angle = graphic.rotate * (Math.PI / 180); //in radians

          const rotatedBottomRight = this.rotate(
            graphic.x + graphic.width,
            graphic.y + graphic.height,
            cx,
            cy,
            angle
          );
          const newCenter = [
            (rotatedBottomRight[0] + topLeftX) / 2,
            (rotatedBottomRight[1] + topLeftY) / 2,
          ];
          const newBottomRight = this.rotate(
            rotatedBottomRight[0],
            rotatedBottomRight[1],
            newCenter[0],
            newCenter[1],
            -angle
          );
          const newTopLeft = this.rotate(
            topLeftX,
            topLeftY,
            newCenter[0],
            newCenter[1],
            -angle
          );

          let x = newTopLeft[0];
          let y = newTopLeft[1];
          let width = newBottomRight[0] - newTopLeft[0];
          let height = newBottomRight[1] - newTopLeft[1];
          // let ratio = 1;
          // if (keepAspectRatio) {
          //   ratio = Math.min(
          //     width / (graphic as Image).srcWidth,
          //     height / (graphic as Image).srcHeight
          //   );

          //   const scaledW = (graphic as Image).srcWidth * ratio;
          //   const scaledH = (graphic as Image).srcHeight * ratio;
          //   x = currWH[0] - scaledW + this.state.offsetX;
          //   y = currWH[1] - scaledH + this.state.offsetY;
          //   width = scaledW;
          //   height = scaledH;
          // }

          graphic.x = x;
          graphic.y = y;

          graphic.width = width;
          graphic.height = height;
        } else {
          const newWidth = currWH[0] + (this.state.offsetX - this.state.x);
          const newHeight = currWH[1] + (this.state.offsetY - this.state.y);
          const width = newWidth > widthMin ? newWidth : widthMin;
          const height = newHeight > heightMin ? newHeight : heightMin;

          if (keepAspectRatio) {
            var ratio = Math.min(
              width / (graphic as Image).srcWidth,
              height / (graphic as Image).srcHeight
            );
            const scaledW = (graphic as Image).srcWidth * ratio;
            const scaledH = (graphic as Image).srcHeight * ratio;
            graphic.x = currWH[0] - scaledW + this.state.offsetX;
            graphic.y = currWH[1] - scaledH + this.state.offsetY;
            graphic.width = scaledW;
            graphic.height = scaledH;

            return ratio;
          } else {
            if (newWidth > widthMin) {
              graphic.x = this.state.x;
            }
            if (newHeight > heightMin) {
              graphic.y = this.state.y;
            }
            graphic.width = width;
            graphic.height = height;
          }
        }
        break;
      }
      case 2: {
        //TR
        if (graphic.rotate > 0) {
          const topRightX = Math.round(this.state.x);
          const topRightY = Math.round(this.state.y);

          let cx = graphic.x + graphic.width / 2;
          let cy = graphic.y + graphic.height / 2;
          const angle = graphic.rotate * (Math.PI / 180); //in radians

          const rotatedBottomLeft = this.rotate(
            graphic.x,
            graphic.y + graphic.height,
            cx,
            cy,
            angle
          );
          const newCenter = [
            (rotatedBottomLeft[0] + topRightX) / 2,
            (rotatedBottomLeft[1] + topRightY) / 2,
          ];
          const newBottomLeft = this.rotate(
            rotatedBottomLeft[0],
            rotatedBottomLeft[1],
            newCenter[0],
            newCenter[1],
            -angle
          );
          const newTopRight = this.rotate(
            topRightX,
            topRightY,
            newCenter[0],
            newCenter[1],
            -angle
          );
          graphic.x = newBottomLeft[0];
          graphic.y = newTopRight[1];
          graphic.width = newTopRight[0] - newBottomLeft[0];
          graphic.height = newBottomLeft[1] - newTopRight[1];
        } else {
          const newWidth = currWH[0] - (this.state.offsetX - this.state.x);
          const newHeight = currWH[1] + (this.state.offsetY - this.state.y);
          const width = newWidth > widthMin ? newWidth : widthMin;
          const height = newHeight > heightMin ? newHeight : heightMin;

          if (keepAspectRatio) {
            var ratio = height / (graphic as Image).srcHeight;
            const scaledH = (graphic as Image).srcHeight * ratio;
            graphic.y = currWH[1] - scaledH + this.state.offsetY;
            graphic.width = (graphic as Image).srcWidth * ratio;
            graphic.height = (graphic as Image).srcHeight * ratio;
            return ratio;
          } else {
            if (newHeight > heightMin) {
              graphic.y = this.state.y;
            }
            graphic.width = width;
            graphic.height = height;
          }
        }
        break;
      }
      case 3: {
        //BR
        if (graphic.rotate > 0) {
          const bottomRightX = Math.round(this.state.x);
          const bottomRightY = Math.round(this.state.y);

          let cx = graphic.x + graphic.width / 2;
          let cy = graphic.y + graphic.height / 2;
          const angle = graphic.rotate * (Math.PI / 180); //in radians

          const rotatedTopLeft = this.rotate(
            graphic.x,
            graphic.y,
            cx,
            cy,
            angle
          );
          const newCenter = [
            (rotatedTopLeft[0] + bottomRightX) / 2,
            (rotatedTopLeft[1] + bottomRightY) / 2,
          ];
          const newTopLeft = this.rotate(
            rotatedTopLeft[0],
            rotatedTopLeft[1],
            newCenter[0],
            newCenter[1],
            -angle
          );
          const newBottomRight = this.rotate(
            bottomRightX,
            bottomRightY,
            newCenter[0],
            newCenter[1],
            -angle
          );
          graphic.x = newTopLeft[0];
          graphic.y = newTopLeft[1];
          graphic.width = newBottomRight[0] - newTopLeft[0];
          graphic.height = newBottomRight[1] - newTopLeft[1];
        } else {
          const newWidth = currWH[0] - (this.state.offsetX - this.state.x);
          const newHeight = currWH[1] - (this.state.offsetY - this.state.y);

          const width = newWidth > widthMin ? newWidth : widthMin;
          const height = newHeight > heightMin ? newHeight : heightMin;
          if (keepAspectRatio) {
            var ratio = Math.min(
              width / (graphic as Image).srcWidth,
              height / (graphic as Image).srcHeight
            );
            graphic.width = (graphic as Image).srcWidth * ratio;
            graphic.height = (graphic as Image).srcHeight * ratio;
            return ratio;
          } else {
            graphic.width = width;
            graphic.height = height;
          }
        }

        break;
      }
      case 4: {
        //BL
        if (graphic.rotate > 0) {
          const bottomLeftX = Math.round(this.state.x);
          const bottomLeftY = Math.round(this.state.y);

          let cx = graphic.x + graphic.width / 2;
          let cy = graphic.y + graphic.height / 2;
          const angle = graphic.rotate * (Math.PI / 180); //in radians

          //TopRight
          const rotatedTopRight = this.rotate(
            graphic.x + graphic.width,
            graphic.y,
            cx,
            cy,
            angle
          );
          const newCenter = [
            (rotatedTopRight[0] + bottomLeftX) / 2,
            (rotatedTopRight[1] + bottomLeftY) / 2,
          ];

          const newTopRight = this.rotate(
            rotatedTopRight[0],
            rotatedTopRight[1],
            newCenter[0],
            newCenter[1],
            -angle
          );
          const newBottomLeft = this.rotate(
            bottomLeftX,
            bottomLeftY,
            newCenter[0],
            newCenter[1],
            -angle
          );
          graphic.x = newBottomLeft[0];
          graphic.y = newTopRight[1];
          graphic.width = newTopRight[0] - newBottomLeft[0];
          graphic.height = newBottomLeft[1] - newTopRight[1];
        } else {
          const newWidth = currWH[0] + (this.state.offsetX - this.state.x);
          const newHeight = currWH[1] - (this.state.offsetY - this.state.y);
          const width = newWidth > widthMin ? newWidth : widthMin;
          const height = newHeight > heightMin ? newHeight : heightMin;

          if (keepAspectRatio) {
            var ratio = width / (graphic as Image).srcWidth;
            const scaledW = (graphic as Image).srcWidth * ratio;
            graphic.x = currWH[0] - scaledW + this.state.offsetX;

            graphic.width = (graphic as Image).srcWidth * ratio;
            graphic.height = (graphic as Image).srcHeight * ratio;
            return ratio;
          } else {
            if (newWidth >= widthMin) {
              graphic.x = this.state.x;
            }
            graphic.width = width;
            graphic.height = height;
          }
        }

        break;
      }
    }
  }

  rotate(x: number, y: number, cx: number, cy: number, angle: number) {
    return [
      (x - cx) * Math.cos(angle) - (y - cy) * Math.sin(angle) + cx,
      (x - cx) * Math.sin(angle) + (y - cy) * Math.cos(angle) + cy,
    ];
  }

  resizeLayer(layer: ILayer, keepAspectRatio: boolean) {
    const currWH = [layer.width, layer.height];
    const widthMin = 10;
    const heightMin = 10;
    switch (this.state.cornerId) {
      case 1: {
        //TL
        const newWidth = currWH[0] + (this.state.offsetX - this.state.x);
        const newHeight = currWH[1] + (this.state.offsetY - this.state.y);
        const width = newWidth > widthMin ? newWidth : widthMin;
        const height = newHeight > heightMin ? newHeight : heightMin;

        if (keepAspectRatio) {
          var ratio = Math.min(width / currWH[0], height / currWH[1]);
          const scaledW = currWH[0] * ratio;
          const scaledH = currWH[1] * ratio;
          layer.x = currWH[0] - scaledW + this.state.offsetX;
          layer.y = currWH[1] - scaledH + this.state.offsetY;
          layer.width = scaledW;
          layer.height = scaledH;
          return ratio;
        } else {
          if (newWidth > widthMin) {
            layer.x = this.state.x;
          }
          if (newHeight > heightMin) {
            layer.y = this.state.y;
          }
          layer.width = width;
          layer.height = height;
        }
        break;
      }
      case 2: {
        //TR
        const newWidth = currWH[0] - (this.state.offsetX - this.state.x);
        const newHeight = currWH[1] + (this.state.offsetY - this.state.y);
        const width = newWidth > widthMin ? newWidth : widthMin;
        const height = newHeight > heightMin ? newHeight : heightMin;

        if (keepAspectRatio) {
          var ratio = height / currWH[1];
          const scaledH = currWH[1] * ratio;
          layer.y = currWH[1] - scaledH + this.state.offsetY;
          layer.width = currWH[0] * ratio;
          layer.height = currWH[1] * ratio;
          return ratio;
        } else {
          if (newHeight > heightMin) {
            layer.y = this.state.y;
          }
          layer.width = width;
          layer.height = height;
        }
        break;
      }
      case 3: {
        //BR
        const newWidth = currWH[0] - (this.state.offsetX - this.state.x);
        const newHeight = currWH[1] - (this.state.offsetY - this.state.y);

        const width = newWidth > widthMin ? newWidth : widthMin;
        const height = newHeight > heightMin ? newHeight : heightMin;
        if (keepAspectRatio) {
          var ratio = Math.min(width / currWH[0], height / currWH[1]);
          layer.width = currWH[0] * ratio;
          layer.height = currWH[1] * ratio;
          return ratio;
        } else {
          layer.width = width;
          layer.height = height;
        }
        break;
      }
      case 4: {
        //BL
        const newWidth = currWH[0] + (this.state.offsetX - this.state.x);
        const newHeight = currWH[1] - (this.state.offsetY - this.state.y);
        const width = newWidth > widthMin ? newWidth : widthMin;
        const height = newHeight > heightMin ? newHeight : heightMin;

        if (keepAspectRatio) {
          var ratio = width / currWH[0];
          const scaledW = currWH[0] * ratio;
          layer.x = currWH[0] - scaledW + this.state.offsetX;

          layer.width = currWH[0] * ratio;
          layer.height = currWH[1] * ratio;
          return ratio;
        } else {
          if (newWidth >= widthMin) {
            layer.x = this.state.x;
          }
          layer.width = width;
          layer.height = height;
        }
        break;
      }
    }
  }
  updateDB() {
    //[docId, layerId, propertyName, propertyValue],
    if (
      this.state.latestGraphicUpdate !== null &&
      this.state.latestLayerUpdate !== null
    ) {
      if (
        this.state.selectedTool === ToolType.mask &&
        this.state.latestLayerUpdate.type === LayerType.ImageUpload
      ) {
        const newGraphicsList = [...this.state.latestLayerUpdate.graphics];
        newGraphicsList[this.state.selectedGraphicIndex] =
          this.state.latestGraphicUpdate;
        store.dispatch(
          updateLayerMultiParams([
            this.state.latestLayerUpdate.docId,
            this.state.selectedLayerID,
            ["mask", "graphics"],
            [this.state.latestLayerUpdate.mask, newGraphicsList],
          ])
        );
      } else {
        const newGraphicsList = [...this.state.latestLayerUpdate.graphics];
        newGraphicsList[this.state.selectedGraphicIndex] =
          this.state.latestGraphicUpdate;
        // console.log(this.state.latestGraphicUpdate);
        store.dispatch(
          updateLayer([
            this.state.latestLayerUpdate.docId,
            this.state.selectedLayerID,
            "graphics",
            newGraphicsList,
          ])
        );
      }
    }
    if (this.state.latestLayerUpdate !== null) {
      if (
        this.state.selectedTool === ToolType.layer &&
        this.state.latestLayerUpdate.type === LayerType.Dropdown
      ) {
        store.dispatch(
          updateLayerMultiParams([
            this.state.latestLayerUpdate.docId,
            this.state.selectedLayerID,
            ["x", "y"],
            [this.state.latestLayerUpdate.x, this.state.latestLayerUpdate.y],
          ])
        );
      }
    }
  }
}
