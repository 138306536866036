import React, { Component, useState, useEffect } from "react";
import { IDocument } from "../../core/Document";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Tooltip, Card, Drawer } from "antd";
import { TeamOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../redux/hooks";
import EditDocumentModal from "../Components/EditDocumentModal";
import AssignAccount from "./AssingAccount";

const { Meta } = Card;
const CoverContainer = styled.div`
  height: 200px;
  width: 300px;
  padding: 10px 30px;
  background-color: #1f1f1f;
`;

const PreviewImg = styled.div<{ url: string }>`
  width: 240px;
  height: 180px;
  position: absolute;
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const CardContainer = styled.div`
  padding: 0px 20px 20px 20px;
`;

const CardHoverable = styled(Card)`
  opacity: 1;
`;

const DocumentTitle = styled.div`
  width: 274px;
  height: fit-content;
  font-size: 13px;
  white-space: normal;
`;
const Tags = styled.div`
  color: rgba(255, 255, 255, 0.45);
  font-weight: 400;
  font-size: 12px;
  padding-top: 5px;
`;

export function Tutorials() {
  const [list, setList] = useState([]);

  useEffect(() => {
    fetch(
      "https://blog.teaminfo.graphics/ghost/api/v3/content/posts/?key=9efebe93e79e2f1e4e85567af3&include=tags"
    )
      .then((response) => response.json())
      .then((data) => {
        setList(data.posts);
      });
  }, []);

  return (
    <>
      {list.map((value: any) => {
        return (
          <CardContainer key={value.id}>
            <CardHoverable
              bordered={false}
              hoverable
              cover={
                <Link to={"/tutorials/" + value.id}>
                  <CoverContainer>
                    <PreviewImg url={value.feature_image} />
                  </CoverContainer>
                </Link>
              }
            >
              <Meta
                title={
                  <>
                    <DocumentTitle>{value.title}</DocumentTitle>
                    <Tags>
                      {" "}
                      {value.tags.map((value: any) => value.name)} •{" "}
                      {value.reading_time} min read
                    </Tags>
                  </>
                }
              />
            </CardHoverable>
          </CardContainer>
        );
      })}
    </>
  );
}

function DocumentCard(props: { document: IDocument }) {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  return (
    <CardContainer>
      <CardHoverable
        hoverable
        cover={
          <Link to={"/dm/" + props.document.id}>
            <CoverContainer>
              {props.document.screenshotTimestamp === 0 ? (
                <PreviewImg url={""} />
              ) : (
                <PreviewImg
                  url={
                    "https://tig-ignite-s3.s3.amazonaws.com/screenshots/" +
                    props.document.id +
                    ".webp?timestamp=" +
                    props.document.screenshotTimestamp
                  }
                />
              )}
            </CoverContainer>
          </Link>
        }
      >
        <Meta
          style={{ zIndex: 10 }}
          title={
            <>
              <DocumentTitle>{props.document.name}</DocumentTitle>
              <Tooltip title="Assign Layout">
                <Button
                  type="text"
                  shape="circle"
                  icon={<TeamOutlined />}
                  onClick={() => setOpen(true)}
                />
              </Tooltip>
              <EditDocumentModal
                selector={props.document}
                id={props.document.id}
              />
            </>
          }
        ></Meta>
        {/* <Drawer placement="right" onClose={() => setOpen(false)} visible={open}>
          <AssignAccount
            documentId={props.document.id}
            documentUrl={
              "https://tig-ignite-s3.s3.amazonaws.com/screenshots/" +
              props.document.id +
              ".webp?timestamp=" +
              props.document.screenshotTimestamp
            }
          />
        </Drawer>  */}
      </CardHoverable>
    </CardContainer>
  );
}
