import {
  Input,
  Layout,
  Collapse,
  Button,
  Select,
  Slider,
  Tooltip,
  Space,
} from "antd";
import { EditableHeader } from "./EditableHeader";
import {
  UploadOutlined,
  ExpandOutlined,
  ZoomInOutlined,
  RotateRightOutlined,
  EditOutlined,
  CheckOutlined,
  BgColorsOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import { CanvasKit } from "canvaskit-wasm";
import { GraphicsEngine, Font } from "../../core/GraphicsEngine";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ILayer } from "../../core/Layer/Layer";
import Graphic from "../../core/Graphic/Graphic";
import styled from "styled-components";
import { CanvasState } from "../DesignerMode/Canvas";
import CanvasEvent, { ToolType } from "../../core/Event/CanvasEvent";
import { ImageFile } from "../../core/Graphic/Image";
import { uploadFile, FileType } from "../../utils/uploadFile";

import fonts from "../../fonts.json";
// Redux imports
import {
  addLayer,
  getLayers,
  updateLayer,
  selectAllLayers,
  selectLayersById,
  addMultipleLayers,
  updateLayerLocal,
} from "../../redux/layerSlice";
import {
  updateDocument,
  selectDocumentById,
  getDocuments,
} from "../../redux/documentSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { debounce } from "../../utils/debounce";
import ButtonGroup from "antd/lib/button/button-group";
import Title from "antd/lib/typography/Title";

const { Header, Footer, Sider, Content } = Layout;
const { Panel } = Collapse;

/*
 * Canvas
 *
 */

const canvasEvent: CanvasEvent = new CanvasEvent();

type TypeID = {
  id: string;
};

type ImageData = { url: string; width: number; height: number };
type Props = {
  ck: CanvasKit;
  dm?: boolean;
};

const ButtonsBot = styled.div`
  padding: 10px 30px;
  border-top: 1px solid gray;
  position: fixed;
  width: 250px;
  background-color: #141414;
  height: 54px;
`;

const SliderContainer = styled.div`
  width: 100px;
  border: white;
`;

var ge: GraphicsEngine | null = null;
let canvasState: CanvasState = {
  width: 0,
  height: 0,
  scale: 1,
  artboardX: 0,
  artboardY: 0,
  selectedLayer: "",
  selectedGraphic: "",
  selectedTool: ToolType.selectLayer,
};
const Scrollable = styled.div`
  width: 100%;
  height: calc(100vh - 121px);
`;

const ControlPanel = styled.div`
  position: absolute;
  z-index: 100;
  margin: 0px auto 0px auto;

  bottom: 0px;
  left: calc(50% - 270px);

  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background-color: #141414;
  border-radius: 25px 25px 0px 0px;
  border-top: 2px solid #808080;
  border-left: 2px solid #808080;
  border-right: 2px solid #808080;
`;

let layers: ILayer[] = [];
let selectedDocument: any;
export default function UserMode(props: Props) {
  const { id } = useParams() as TypeID;
  const dispatch = useAppDispatch();

  const [selectedLayer, setSelectedLayer] = useState("");

  const [zoomOpen, setZoomOpen] = useState(false);
  const [rotateOpen, setRotateOpen] = useState(false);
  selectedDocument = useAppSelector((state) => selectDocumentById(state, id));
  const selectedLayers = useAppSelector((state) => {
    return selectLayersById(
      state,
      selectedDocument !== undefined ? selectedDocument.layers : []
    );
  });

  const [windowSize, setWindowSize] = useState({
    scale: 1,
  });
  useEffect(() => {
    if (selectedDocument === undefined) {
      dispatch(getDocuments());
    }
    dispatch(getLayers(id));
  }, []);

  useEffect(() => {
    handleResize();
    if (selectedDocument !== undefined) {
      canvasState.width = selectedDocument.width;
      canvasState.height = selectedDocument.height;
      canvasState.artboardX = 0;
      canvasState.artboardY = 0;
      ge?.resize();
      render();
    }
  }, [selectedDocument]);
  useEffect(() => {
    if (selectedLayers.length > 0) {
      loadFonts();
      layers = JSON.parse(JSON.stringify(selectedLayers));
      render();
    }
  }, [selectedLayers]);

  useEffect(() => {
    // Add event listener
    const canvas = document.getElementById("mainCanvas");
    canvas?.addEventListener("pointermove", canvasMouseInteraction);
    canvas?.addEventListener("pointerdown", canvasMouseInteraction);
    canvas?.addEventListener("pointerup", canvasMouseInteraction);
    window.addEventListener("resize", handleResize);
    handleResize();
    ge = new GraphicsEngine(props.ck, fonts, "mainCanvas");
    return () => {
      canvas?.removeEventListener("pointermove", canvasMouseInteraction);
      canvas?.removeEventListener("pointerdown", canvasMouseInteraction);
      canvas?.removeEventListener("pointerup", canvasMouseInteraction);
      window.removeEventListener("resize", handleResize);
      ge?.delete();
      ge = null;
      layers = [];
      selectedDocument = null;
    };
  }, []); // Empty array ensures that effect is only run on mount

  const updateTextDB = useCallback(debounce(updateGraphic, 1000), []);
  return (
    <Layout>
      {!props.dm ? <EditableHeader /> : <></>}
      <Layout>
        <Content
          style={{
            padding: "50px",
            backgroundColor: "#000",
          }}
        >
          {selectedLayer !== "" ? (
            <ControlPanel>
              <ButtonGroup>
                <Space size="large">
                  <Tooltip color="#1f1f1f" title="Center Image">
                    <Button
                      size="large"
                      style={{ backgroundColor: "#141414" }}
                      onClick={(e) => {
                        e.currentTarget.blur();
                        if (selectedLayer !== "") {
                          const selected: any = layers
                            .filter(
                              (layer: ILayer) => layer.id === selectedLayer
                            )
                            .map((selected) => selected);
                          let { width, height, x, y } = contain(
                            selected[0].mask.width,
                            selected[0].mask.height,
                            selected[0].graphics[0].srcWidth,
                            selected[0].graphics[0].srcHeight
                          );
                          selected[0].graphics[0].x = x + selected[0].mask.x;
                          selected[0].graphics[0].y = y + selected[0].mask.y;
                          selected[0].graphics[0].width = width;
                          selected[0].graphics[0].height = height;
                          updateLocal(selected[0].graphics[0], selectedLayer);
                        }
                      }}
                      icon={<ExpandOutlined />}
                    ></Button>
                  </Tooltip>

                  <Tooltip
                    color="#1f1f1f"
                    title={
                      <SliderContainer>
                        <Title level={5}>Zoom</Title>
                        <Slider
                          key={selectedLayer}
                          defaultValue={100}
                          min={10}
                          max={300}
                          onChange={(val: number) => {
                            if (selectedLayer !== "") {
                              const selected: any = layers
                                .filter(
                                  (layer: ILayer) => layer.id === selectedLayer
                                )
                                .map((selected) => selected);
                              const graphic = Object.assign(
                                {},
                                selected[0].graphics[0]
                              );
                              const xpoint = graphic.width / 2 + graphic.x;
                              const ypoint = graphic.height / 2 + graphic.y;
                              graphic.width = graphic.srcWidth * (val / 100);
                              graphic.height = graphic.srcHeight * (val / 100);
                              graphic.x = xpoint - graphic.width / 2;
                              graphic.y = ypoint - graphic.height / 2;
                              updateLocal(graphic, selectedLayer);
                            }
                          }}
                        />
                      </SliderContainer>
                    }
                  >
                    <Button
                      size="large"
                      style={{ backgroundColor: "#141414" }}
                      onClick={(e) => {
                        e.currentTarget.blur();
                        if (zoomOpen) {
                        }
                        setZoomOpen(!zoomOpen);
                      }}
                      icon={<ZoomInOutlined />}
                    ></Button>
                  </Tooltip>
                  <Tooltip
                    color="#1f1f1f"
                    title={
                      <SliderContainer>
                        <Title level={5}>Rotate</Title>
                        <Slider
                          min={0}
                          max={360}
                          onChange={(val: number) => {
                            if (selectedLayer !== "") {
                              const selected: any = layers
                                .filter(
                                  (layer: ILayer) => layer.id === selectedLayer
                                )
                                .map((selected) => selected);
                              const graphic = Object.assign(
                                {},
                                selected[0].graphics[0]
                              );
                              // selected[0].graphics[0].x = x;
                              // selected[0].graphics[0].y = y;
                              graphic.rotate = val;
                              updateLocal(graphic, selectedLayer);
                            }
                          }}
                        />
                      </SliderContainer>
                    }
                  >
                    <Button
                      size="large"
                      style={{ backgroundColor: "#141414" }}
                      onClick={(e) => {
                        e.currentTarget.blur();
                        setRotateOpen(!rotateOpen);
                      }}
                      icon={<RotateRightOutlined />}
                    ></Button>
                  </Tooltip>

                  <Tooltip
                    color="#1f1f1f"
                    title={
                      process.env.NODE_ENV === "development" ? (
                        <SliderContainer>
                          <Title level={5}>Filters Here</Title>
                        </SliderContainer>
                      ) : (
                        "Filters Coming Soon"
                      )
                    }
                  >
                    <Button
                      size="large"
                      style={{ backgroundColor: "#141414" }}
                      onClick={(e) => {
                        e.currentTarget.blur();
                        setRotateOpen(!rotateOpen);
                      }}
                      icon={<BgColorsOutlined />}
                    ></Button>
                  </Tooltip>
                  <Tooltip color="#1f1f1f" title="Done Editing">
                    <Button
                      // ghost
                      size="large"
                      style={{ backgroundColor: "#141414" }}
                      icon={<CheckOutlined />}
                      onClick={() => {
                        setSelected("");
                        render();
                      }}
                    ></Button>
                  </Tooltip>
                </Space>
              </ButtonGroup>
            </ControlPanel>
          ) : (
            <></>
          )}

          <div
            id="tig_main"
            style={{
              margin: "auto",
              width: "100%",
              height: "calc(100vh - 155px)",
            }}
          >
            <div
              style={{
                position: "relative",
                left: "50%",
                top: "50%",
                transform:
                  "translate(-50%, -50%) scale(" + windowSize.scale + ")",
                display: "inline-block",
                transformOrigin: "center center",
                backgroundColor: "black",
              }}
            >
              {selectedDocument ? (
                <>
                  <canvas
                    id="canvasBG"
                    width={selectedDocument.width * 0.25}
                    height={selectedDocument.height * 0.25}
                    style={{ display: "none" }}
                  />
                  <canvas
                    id="canvasFull"
                    width={selectedDocument.width}
                    height={selectedDocument.height}
                    style={{ display: "none" }}
                  />
                </>
              ) : (
                <></>
              )}
              <canvas
                id="mainCanvas"
                width={`${selectedDocument ? selectedDocument.width : 1}px`}
                height={`${selectedDocument ? selectedDocument.height : 1}px`}
              />
            </div>
          </div>
        </Content>
        <Sider width={250}>
          <Scrollable
            style={{
              height: "calc( 100vh - 121px)",
            }}
            data-simplebar
            onMouseDown={(e) => {
              if (
                (e.target as Element).className !== "edit-image-button" ||
                (e.target as Element).id === "sotrable-list-container"
              ) {
                setSelected("");
              }
            }}
          >
            <div>
              <Collapse ghost>
                {selectedLayers
                  .filter((layer: ILayer) => {
                    if (layer.userMode) {
                      return layer;
                    }
                  })
                  .map((layer: ILayer, index: number) => {
                    return renderUserModeFields(layer, index);
                  })}
              </Collapse>
            </div>
          </Scrollable>
          <ButtonsBot>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                const imgBuff = ge?.exportToPNG(layers, canvasState);
                if (imgBuff !== undefined && imgBuff !== null) {
                  var blob = new Blob([imgBuff], { type: "image/png" });
                  var url = URL.createObjectURL(blob);
                  var aDownloadLink = document.createElement("a");
                  aDownloadLink.download =
                    selectedDocument.name.split(" ").join("_") +
                    "_" +
                    Date.now() +
                    ".png";
                  aDownloadLink.href = url;
                  aDownloadLink.click();
                }
              }}
            >
              <DownloadOutlined /> Export
            </Button>
          </ButtonsBot>
        </Sider>
      </Layout>
    </Layout>
  );

  function renderUserModeFields(layer: ILayer, index: number) {
    switch (layer.type) {
      case "textbox":
        return renderTextboxFields(layer, index);
      case "image_upload":
        return renderImageUploadFields(layer, index);
      case "dropdown":
        return renderDropdownFields(layer, index);
      default:
        return <></>;
    }
  }

  function renderTextboxFields(layer: ILayer, index: number) {
    return (
      <Panel header={layer.name} key={layer.id}>
        <Input
          defaultValue={layer.graphics[0].value}
          onChange={(e) => {
            const textData = { ...layer.graphics[0] };
            textData.value = e.target.value;
            updateLocal(textData, layer.id);
            updateTextDB(layer, textData, 0);
          }}
        />
      </Panel>
    );
  }
  function renderImageUploadFields(layer: ILayer, index: number) {
    return (
      <Panel header={layer.name} key={layer.id}>
        <Space>
          <Button
            className="edit-image-button"
            icon={<EditOutlined />}
            onClick={(e) => {
              e.currentTarget.blur();
              setSelected(layer.id);
            }}
          >
            Edit Image
          </Button>
          <label>
            <input
              type="file"
              id="fileuploadImageUpload"
              style={{ display: "none" }}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (e.target.files?.item(0) !== null) {
                  const file = e.target.files?.item(0);
                  uploadFile(file!, FileType.image).then((url) => {
                    addImageToImageUpload(file!, layer, url);
                    e.target.value = "";
                  });
                }
              }}
              accept=".jpg, .jpeg, .png"
            />
            <Tooltip title="Upload Image">
              <Button
                icon={<UploadOutlined />}
                onClick={(e) => {
                  e.currentTarget.blur();
                  document.getElementById("fileuploadImageUpload")?.click();
                }}
              ></Button>
            </Tooltip>
          </label>
        </Space>
      </Panel>
    );
  }

  function renderDropdownFields(layer: ILayer, index: number) {
    return (
      <Panel header={layer.name} key={layer.id}>
        <Select
          defaultValue={layer.selectedId}
          style={{ width: 120 }}
          onChange={(value) => {
            //do something
            dispatch(updateLayer([id, layer.id, "selectedId", value]));
          }}
        >
          {layer.graphics.map((graphic: Graphic) => {
            return (
              <Select.Option key={graphic.id} value={graphic.id}>
                {graphic.name}
              </Select.Option>
            );
          })}
        </Select>
      </Panel>
    );
  }

  function updateGraphic(layer: ILayer, graphic: any, index: number) {
    const newGraphicsList = [...layer.graphics];
    newGraphicsList[index] = { ...graphic };
    dispatch(updateLayer([id, layer.id, "graphics", newGraphicsList]));
  }

  // Handler to call on window resize
  function handleResize() {
    // Set window width/height to state
    if (selectedDocument !== undefined) {
      setTimeout(() => {
        const canvas = document.getElementById("tig_main");
        const scale = Math.min(
          canvas!.clientWidth / selectedDocument.width,
          canvas!.clientHeight / selectedDocument.height
        );

        setWindowSize({
          scale,
        });
      }, 50);
    }
  }
  function render() {
    ge?.renderLayers(layers, canvasState);
  }

  function updateLocal(textData: any, id: string) {
    dispatch(
      updateLayerLocal({
        id: id,
        propertyName: "graphics",
        propertyValue: [textData],
      })
    );
  }

  function addImageToImageUpload(
    file: File,
    selectedLayer: any,
    data: ImageData
  ) {
    const child = new ImageFile(
      file,
      selectedLayer.mask.x,
      selectedLayer.mask.y,
      true
    );
    Object.assign(child, {
      ...data,
      srcWidth: data.width,
      srcHeight: data.height,
    });
    const newGraphics = [child.toJSON()];
    dispatch(updateLayer([id, selectedLayer.id, "graphics", newGraphics]));
  }

  function canvasMouseInteraction(e: PointerEvent) {
    let type = e.type;
    canvasEvent.newMouseEvent(
      type,
      e.offsetX,
      e.offsetY,
      e.pressure,
      layers,
      canvasState.scale,
      [canvasState.artboardX, canvasState.artboardY],
      canvasState.selectedTool,
      canvasState.selectedLayer,
      canvasState.selectedGraphic,
      (newX: number, newY: number) => {
        canvasState.artboardX = newX;
        canvasState.artboardY = newY;
      }
    );
    render();
  }
  function setSelected(id: string) {
    if (id !== "") {
      const selected = selectedLayers
        .filter((layer: ILayer) => layer.id === id)
        .map((selected) => selected);
      if (selected[0].type === "image_upload") {
        canvasState.selectedTool = ToolType.image;
        canvasState.selectedGraphic = selected[0].graphics[0].id;
      }
      canvasState.selectedLayer = id;
    } else {
      canvasState.selectedLayer = "";
      canvasState.selectedGraphic = "";
    }
    setSelectedLayer(id);
  }
  function contain(
    parentWidth: number,
    parentHeight: number,
    childWidth: number,
    childHeight: number
  ) {
    const doRatio = childWidth / childHeight;
    const cRatio = parentWidth / parentHeight;
    let width = parentWidth;
    let height = parentHeight;

    if (doRatio > cRatio) {
      height = width / doRatio;
    } else {
      width = height * doRatio;
    }

    return {
      width,
      height,
      x: (parentWidth - width) / 2,
      y: (parentHeight - height) / 2,
    };
  }
  function loadFonts() {
    for (var i = selectedDocument.fonts.length - 1; i >= 0; i--) {
      const newFont = selectedDocument.fonts[i];
      ge?.addNewFont(newFont.name, newFont.url);
    }
  }
}
