import React, { useEffect, useState } from "react";
import { IDocument } from "../../core/Document";
import { getDocuments, selectAllDocuments } from "../../redux/documentSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { DocumentCard } from "./DocumentCard";
import styled from "styled-components";
import { AddLayout } from "./AddLayout";

const CardContainer = styled.div`
  padding: 0px 20px 20px 20px;
`;

export function DashboardList() {
  const documentsList = useAppSelector(selectAllDocuments) as IDocument[];
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (documentsList === undefined || documentsList.length === 0) {
      dispatch(getDocuments());
    }
  }, []);

  if (documentsList !== undefined) {
    documentsList.sort((a: IDocument, b: IDocument) => {
      if (a.created && b.created) {
        return b.created - a.created;
      }
      return 0;
    });
  }

  return (
    <>
      <CardContainer>
        <AddLayout />
      </CardContainer>
      {documentsList !== undefined
        ? documentsList.map((document, index) => {
            return (
              <div key={index}>
                <DocumentCard document={document} />
              </div>
            );
          })
        : ""}
    </>
  );
}
