import { useCallback } from "react";
import { InputNumber, Input, Row, Col } from "antd";
import { debounce } from "../../../utils/debounce";
import { updateLayerLocal, updateLayer } from "../../../redux/layerSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { useParams } from "react-router-dom";

function SizePositionToolbox(props: {
  selectedLayer: any;
  updateGraphic: any;
  currentGraphic: any;
  currentGraphicIndex: any;
  updateLocal: any;
  selected: any;
}) {
  const { id } = useParams() as any;
  const dispatch = useAppDispatch();
  const updateDB = useCallback(debounce(updateGraphicArray, 200), [
    props.selected,
  ]);
  return (
    <>
      <Row>
        <Col span={12}>
          <Input.Group
            style={{
              width: "100px",
            }}
          >
            <span className="ant-input-group-addon" style={{ width: 50 }}>
              W
            </span>
            <InputNumber
              disabled={props.currentGraphic === undefined}
              value={props.currentGraphic ? props.currentGraphic.width : 0}
              style={{ width: 72 }}
              onChange={(value) => {
                const graphic = { ...props.currentGraphic };
                graphic.width = value;
                updateGraphic(graphic);
              }}
            />
          </Input.Group>
        </Col>
        <Col span={12}>
          <Input.Group
            style={{
              width: "100px",
            }}
          >
            <span className="ant-input-group-addon" style={{ width: 50 }}>
              H
            </span>
            <InputNumber
              disabled={props.currentGraphic === undefined}
              value={props.currentGraphic ? props.currentGraphic.height : 0}
              style={{ width: 72 }}
              onChange={(value) => {
                const graphic = { ...props.currentGraphic };
                graphic.height = value;
                updateGraphic(graphic);
              }}
            />
          </Input.Group>
        </Col>
      </Row>
      <Row>
        <div style={{ height: 10, width: 10 }}></div>
      </Row>
      <Row>
        <Col span={12}>
          <Input.Group
            style={{
              width: "100px",
            }}
          >
            <span className="ant-input-group-addon" style={{ width: 50 }}>
              X
            </span>
            <InputNumber
              disabled={props.currentGraphic === undefined}
              value={props.currentGraphic ? props.currentGraphic.x : 0}
              style={{ width: 72 }}
              onChange={(value) => {
                const graphic = { ...props.currentGraphic };
                graphic.x = value;
                updateGraphic(graphic);
              }}
            />
          </Input.Group>
        </Col>
        <Col span={12}>
          <Input.Group
            style={{
              width: "100px",
            }}
          >
            <span className="ant-input-group-addon" style={{ width: 50 }}>
              Y
            </span>
            <InputNumber
              disabled={props.currentGraphic === undefined}
              value={props.currentGraphic ? props.currentGraphic.y : 0}
              style={{ width: 72 }}
              onChange={(value) => {
                const graphic = { ...props.currentGraphic };
                graphic.y = value;
                updateGraphic(graphic);
              }}
            />
          </Input.Group>
        </Col>
      </Row>
    </>
  );

  function updateGraphic(graphic: any) {
    if (props.selectedLayer.type === "dropdown") {
      const graphicsArray = Array.from(props.selectedLayer.graphics);
      graphicsArray[props.currentGraphicIndex] = graphic;
      updateLocal(graphicsArray, props.selectedLayer?.id);
      updateDB(graphicsArray, props.selectedLayer?.id);
    } else {
      updateLocal([graphic], props.selectedLayer?.id);
      updateDB([graphic], props.selectedLayer?.id);
    }
  }
  function updateLocal(graphicsArray: any, layerId: string) {
    dispatch(
      updateLayerLocal({
        id: layerId,
        propertyName: "graphics",
        propertyValue: graphicsArray,
      })
    );
  }
  function updateGraphicArray(graphicsArray: any, layerId: string) {
    dispatch(updateLayer([id, layerId, "graphics", graphicsArray]));
  }
}
export default SizePositionToolbox;
