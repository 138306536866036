import React, { useEffect, useState } from "react";
import { selectDocumentById } from "../../redux/documentSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import { Typography } from "antd";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
const { Title, Paragraph } = Typography;

type TypeID = {
  id: string;
};

const ParagraphContainer = styled.div`
  width: 250px;
  display: inline-flex;
  font-size: 15px;
  justify-content: center;
  padding-top: 8px;
  margin-left: -120px;
`;

const NavBack = styled.div`
  width: 100px;
  float: left;
  padding-top: 10px;
  font-size: 15px;
`;

const Header = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.85);
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.66667;
  list-style: none;
  font-feature-settings: tnum, "tnum";
  position: relative;
  padding: 8px 16px;
  background-color: #1f1f1f;
  text-align: center;
`;
const IgniteLogo = styled.div`
  width: 55px;
  height: 35px;
  background: url(/img/IGNITE_logo.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;
export function EditableHeader() {
  const [name, setName] = useState("");
  const { accountId, userId, id } = useParams() as any;
  const selector = useAppSelector((state) => state.documents[id]);
  useEffect(() => {
    if (selector) {
      setName(selector.name);
    }
  }, [selector]);
  return (
    <Header>
      <NavBack>
        <Link to={"/user/" + accountId + "/" + userId}>
          <Paragraph
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <LeftOutlined />
            Dashboard
          </Paragraph>
        </Link>
      </NavBack>
      <ParagraphContainer>
        <IgniteLogo />
        <Paragraph
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginBottom: 1,
            padding: "0px 10px",
          }}
        >
          {name}
        </Paragraph>
      </ParagraphContainer>
    </Header>
  );
}
