import React, { useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { Result, Button } from "antd";
import { displayError, getTokenAndId } from "../../redux/helpers";

var baseUrl = "/.netlify/functions/";

export default function Expired() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <DashboardHeader />
      <div>
        <Result
          status="error"
          title="Your trial or subscription has expired."
          subTitle="To avoid losing access subscribe today!"
          extra={[
            <Button onClick={handleClick} loading={loading} key={1}>
              Subscribe
            </Button>,
            <a href="mailto:helpdesk@teaminfographics.com" key={2}>
              <Button type="primary" key="console">
                Contact Us
              </Button>
            </a>,
          ]}
        />
        ,
      </div>
    </>
  );
  async function handleClick(e: React.MouseEvent<HTMLElement>) {
    setLoading(true);
    e.currentTarget.blur();
    const userData = await getTokenAndId();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    const request = await fetch(baseUrl + "subscription-link", requestOptions);
    const response = await request.json();
    if (request.status !== 200) {
      displayError(request.status + ": " + request.statusText);
      setLoading(false);
    } else {
      window.location.href = response.url;
    }
  }
}
