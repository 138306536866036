import { useCallback } from "react";

import {
  Space,
  Typography,
  Select,
  InputNumber,
  Input,
  Button,
  Divider,
  Radio,
  Tooltip,
  Slider,
  Row,
  Col,
  Empty,
} from "antd";

import { uploadFile, FileType } from "../../../utils/uploadFile";
import { UploadOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { SortableList } from "../../Components/SortableList/SortableList";
import { useParams } from "react-router-dom";

import styled from "styled-components";
import Graphic from "../../../core/Graphic/Graphic";
import { ImageFile } from "../../../core/Graphic/Image";
import { ToolType } from "../../../core/Event/CanvasEvent";

//Redux Imports
import { updateLayer, updateLayerLocal } from "../../../redux/layerSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { debounce } from "../../../utils/debounce";

import Title from "antd/lib/typography/Title";

type ImageData = { url: string; width: number; height: number };
const SliderContainer = styled.div`
  width: 100px;
  border: white;
`;

function DropdownToolbox(props: {
  currentGraphic: any;
  selectedTool: any;
  updateToolType: any;
  updateGraphic: any;
  selectedLayer: any;
  setSelectedGraphic: any;
  currentGraphicIndex: any;
}) {
  const dispatch = useAppDispatch();
  const { id } = useParams() as any;
  const updateGraphicDB = useCallback(debounce(props.updateGraphic, 200), [
    props.selectedLayer,
  ]);

  return (
    <>
      <Typography.Title level={5}> Edit Type </Typography.Title>
      <Radio.Group
        onChange={(e) => {
          props.updateToolType(e.target.value);
        }}
        value={props.selectedTool}
      >
        <Radio.Button value={ToolType.layer}>Position All Items</Radio.Button>
        <Radio.Button value={ToolType.selectLayer}>
          Position Selected Item
        </Radio.Button>

        {props.selectedTool === ToolType.selectLayer ? (
          <div style={{ marginTop: 15 }}>
            <SliderContainer>
              <Title level={5}>Rotate</Title>
              <Slider
                disabled={props.currentGraphic === undefined}
                min={0}
                max={360}
                key={
                  props.currentGraphic
                    ? props.currentGraphic.id + "ls"
                    : "disabled"
                }
                onAfterChange={(value: number) => {
                  const graphic = Object.assign({}, props.currentGraphic);
                  graphic.rotate = value;

                  updateGraphicDB(graphic, props.currentGraphicIndex);
                }}
                onChange={(value: number) => {
                  const graphicsArray = Array.from(
                    props.selectedLayer.graphics
                  );
                  const graphic = Object.assign({}, props.currentGraphic);
                  graphic.rotate = value;
                  graphicsArray[props.currentGraphicIndex] = graphic;
                  updateLocalDropdown(graphicsArray, props.selectedLayer?.id);
                }}
                defaultValue={
                  props.currentGraphic ? props.currentGraphic.rotate : 0
                }
              />
            </SliderContainer>
          </div>
        ) : (
          <></>
        )}
      </Radio.Group>
      <Divider></Divider>
      <div style={{ padding: "10px 0px" }}>
        <Typography.Title level={5}>
          Items
          <label>
            <input
              type="file"
              id="fileuploadItem"
              style={{ display: "none" }}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (e.target.files?.item(0) !== null) {
                  const file = e.target.files?.item(0);
                  uploadFile(file!, FileType.image).then((url) => {
                    addImageToDropdown(file!, url);
                    e.target.value = "";
                  });
                }
              }}
              accept=".jpg, .jpeg, .png"
            />
            <Tooltip title="Sort Ascending">
              <Button
                style={{ float: "right", marginLeft: 10 }}
                icon={<SortAscendingOutlined />}
                ghost
                onClick={(e) => {
                  e.currentTarget.blur();
                  let sortedGraphics = Array.from(props.selectedLayer.graphics);
                  var collator = new Intl.Collator(undefined, {
                    numeric: true,
                    sensitivity: "base",
                  });
                  sortedGraphics.sort((a: any, b: any) => {
                    return collator.compare(a.name, b.name);
                  });
                  setOrder(sortedGraphics as Graphic[]);
                }}
              />
            </Tooltip>
            <Tooltip title="Upload Item">
              <Button
                style={{ float: "right" }}
                icon={<UploadOutlined />}
                ghost
                onClick={(e) => {
                  e.currentTarget.blur();
                  document.getElementById("fileuploadItem")?.click();
                }}
              />
            </Tooltip>
          </label>
        </Typography.Title>
      </div>

      {props.selectedLayer.graphics.length > 0 ? (
        <SortableList
          setData={setOrder}
          data={props.selectedLayer.graphics}
          selected={props.selectedLayer.selectedId}
          setSelected={setSelectedGraphic}
          setTitle={setGraphicsTitle}
          deleteLayer={deleteGraphics}
          eyeOff
          iconOff
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>No Items</span>}
        >
          <Button
            type="primary"
            onClick={(e) => {
              e.currentTarget.blur();
              document.getElementById("fileuploadItem")?.click();
            }}
          >
            Upload Item
          </Button>
        </Empty>
      )}
    </>
  );
  function setOrder(newChildList: Graphic[]) {
    dispatch(
      updateLayer([id, props.selectedLayer.id, "graphics", newChildList])
    );
  }
  function addImageToDropdown(file: File, data: ImageData) {
    const child = new ImageFile(file, 0, 0, true);
    Object.assign(child, {
      ...data,
      srcWidth: data.width,
      srcHeight: data.height,
    });
    const newGraphics = [child.toJSON(), ...props.selectedLayer.graphics];
    props.setSelectedGraphic(child.id);
    dispatch(
      updateLayer([id, props.selectedLayer.id, "graphics", newGraphics])
    );
  }
  function setSelectedGraphic(selectedId: string) {
    props.setSelectedGraphic(selectedId);
    dispatch(
      updateLayer([id, props.selectedLayer.id, "selectedId", selectedId])
    );
  }
  function deleteGraphics(graphicsIndex: number) {
    const newGraphicsList = [...props.selectedLayer.graphics];
    newGraphicsList.splice(graphicsIndex, 1);
    dispatch(
      updateLayer([id, props.selectedLayer.id, "graphics", newGraphicsList])
    );
  }
  function setGraphicsTitle(title: string, graphicsIndex: number) {
    const newGraphicsList = [...props.selectedLayer.graphics];
    const newGraphic = {
      ...newGraphicsList[graphicsIndex],
      name: title,
    };
    newGraphicsList[graphicsIndex] = newGraphic;
    dispatch(
      updateLayer([id, props.selectedLayer.id, "graphics", newGraphicsList])
    );
  }
  function updateLocalDropdown(graphicsArray: any, id: string) {
    dispatch(
      updateLayerLocal({
        id: id,
        propertyName: "graphics",
        propertyValue: graphicsArray,
      })
    );
  }
}

export default DropdownToolbox;
