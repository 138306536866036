import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import documentsReducer from "./documentSlice";
import templatesReducer from "./templateSlice";
import usersReducer from "./userSlice";
import layersReducer from "./layerSlice";
import { debounce } from "../utils/debounce";
import { notification } from "antd";
import netlifyIdentity from "netlify-identity-widget";

var past: any[] = [];
var current: any = {};
var future: any[] = [];

var layerHistory = {
  past: past,
  current: current,
  future: future,
};

// var websocket = new WebSocket("wss://ws.teaminfo.graphics/");

// websocket.addEventListener("open", function (event) {
//   notification.open({
//     message: "Connected",
//     description: "cool",
//   });
// });
// websocket.addEventListener("message", function (event) {
//   const info = JSON.parse(event.data);
//   notification.open({
//     message: "New Change",
//     description: info.type,
//   });
//   const type = info.type.split("/");
//   store.dispatch({
//     type: type[0] + "/" + type[1] + "Local",
//     payload: info.payload,
//   });
// });
// websocket.addEventListener("close", function (event) {
//   console.log("Socket is closed. Reconnect will be attempted in 1 second.");
//   setTimeout(function () {
//     websocket = new WebSocket("wss://ws.teaminfo.graphics/");
//   }, 100);
// });

// const wsSignal = (store: any) => (next: any) => (action: any) => {
//   let result = next(action);
//   if (
//     (action.type.startsWith("documents/update") ||
//       action.type.startsWith("layers/update") ||
//       action.type.startsWith("layers/add") ||
//       action.type.startsWith("layers/delete")) &&
//     action.type.endsWith("fulfilled")
//   ) {
//     websocket.send(
//       JSON.stringify({ type: action.type, payload: action.payload })
//     );
//   }
//   return result;
// };

const checkIdentity = (store: any) => (next: any) => (action: any) => {
  // let user = netlifyIdentity.currentUser();
  // let timestamp = user?.token?.expires_at;
  // console.log("times");
  // setTimeout(() => {
  //   console.log("times?");
  //   next(action);
  // }, 1200);
  // if (user) {
  //   if (timestamp !== undefined && timestamp < Date.now()) {
  //     netlifyIdentity.refresh().then(() => {
  //       return next(action);
  //     });
  //   } else {
  //     return next(action);
  //   }
  // } else {
  //   return next(action);
  // }
};

const undoRedo = (store: any) => (next: any) => (action: any) => {
  if (
    action.type.startsWith("layers/updateLayer") &&
    action.type.endsWith("fulfilled")
  ) {
    layerHistory.past.push(store.getState().layers);
    next(action);
    layerHistory.current = store.getState().layers;
    layerHistory.future = [];
  } else if (action.type === "layers/undoRedo") {
    if (action.payload === "undo") {
      const index = layerHistory.past.length - 1;
      if (index >= 0) {
        next({
          type: action.type,
          payload: { state: layerHistory.past[index] },
        });
        const current = layerHistory.current;
        layerHistory.current = layerHistory.past[index];
        layerHistory.future.push(current);
        layerHistory.past.pop();
      }
    }
    if (action.payload === "redo") {
      const index = layerHistory.future.length - 1;
      if (index >= 0) {
        //Do something
        next({
          type: action.type,
          payload: { state: layerHistory.future[index] },
        });
        const current = layerHistory.current;
        layerHistory.current = layerHistory.future[index];
        layerHistory.past.push(current);
        layerHistory.future.pop();
      }
    }
  } else {
    next(action);
  }
};

const store = configureStore({
  reducer: {
    documents: documentsReducer,
    layers: layersReducer,
    users: usersReducer,
    templates: templatesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(undoRedo), //.concat(wsSignal),
});

const debounced = debounce(() => console.log(store.getState()), 1000);

if (process.env.NODE_ENV === "development") {
  store.subscribe(() => {
    debounced();
  });
}

export default store;

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
