import React, { useEffect } from "react";
import { createDropdownListFromFileList } from "../../../core/Layer/Dropdown";
import { SortableList } from "../../Components/SortableList/SortableList";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { useParams } from "react-router-dom";
import { Layer, ILayer } from "../../../core/Layer/Layer";
import ImageUpload from "../../../core/Layer/ImageUpload";
import Text from "../../../core/Graphic/Text";
import Graphic from "../../../core/Graphic/Graphic";
import Image, { ImageFile } from "../../../core/Graphic/Image";
import { Button, Tooltip, Space, message, Divider } from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  uploadFile,
  FileType,
  uploadMultipleFiles,
} from "../../../utils/uploadFile";
import {
  UnorderedListOutlined,
  FontSizeOutlined,
  PictureOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import {
  addLayer,
  getLayers,
  deleteLayer as deleteLayerRedux,
  updateLayer,
  selectAllLayers,
  selectLayersById,
  addMultipleLayers,
  selectLayerById,
} from "../../../redux/layerSlice";
import {
  updateDocument,
  selectDocumentById,
} from "../../../redux/documentSlice";
import Textbox from "../../../core/Layer/Textbox";
import { ToolType } from "../../../core/Event/CanvasEvent";
import {
  selectTemplateById,
  updateTemplate,
} from "../../../redux/templateSlice";

type TypeID = {
  id: string;
};
type ImageData = { url: string; width: number; height: number };

const Box = styled.div`
  padding: 10px 30px;
  // border-bottom: 1px solid gray;
  width: 300px;
  height: calc(100% - 55px);
`;

const ButtonsBot = styled.div`
  padding: 10px 30px;
  border-top: 1px solid gray;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 300px;
  background-color: #141414;
  height: 49px;
`;

const DroppableDiv = styled.div`
  height: inherit;
  overflow: hidden;
`;
const Scrollable = styled.div`
  width: 100%;
  height: calc(100vh - 66px - 52px);
`;

function LeftSidebar(props: any) {
  const { id } = useParams() as TypeID;
  const dispatch = useAppDispatch();

  const selectedDocument = useAppSelector((state) => {
    if (props.isTemplate) {
      return selectTemplateById(state, id);
    } else {
      return selectDocumentById(state, id);
    }
  });
  const selectedLayers = useAppSelector((state) => {
    return selectLayersById(
      state,
      selectedDocument !== undefined ? selectedDocument.layers : []
    );
  });
  return (
    <DroppableDiv
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragEnter={(e) => {
        e.preventDefault();
      }}
      onDragLeave={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        e.preventDefault();
        e.stopPropagation();
        var length = e.dataTransfer.items.length;
        var itemList: Array<File> = [];

        for (var i = 0; i < length; i++) {
          var entry = e.dataTransfer.items[i].webkitGetAsEntry();
          if (entry) {
            scanAndLogFiles(entry, itemList);
          }
        }
        //TODO: Fix this. Need to wait for recusrive function to finish.
        setTimeout(() => {
          if (itemList.length > 0) {
            uploadMultipleFiles(itemList, FileType.image).then((urls) => {
              const layerArray = createDropdownListFromFileList(
                itemList,
                id,
                true,
                urls
              ).map((dropdown) => dropdown.toJSON());
              addDropdown(layerArray);
            });
          } else {
            message.warn("Error loading files.", 3);
          }
        }, 500);
      }}
    >
      <Scrollable
        data-simplebar
        onMouseDown={(e) => {
          if (
            (e.target as Element).className === "simplebar-content-wrapper" ||
            (e.target as Element).id === "sotrable-list-container"
          ) {
            setSelected("");
            props.setSelectedGraphic("");
          }
        }}
      >
        <Box id="sotrable-list-container">
          {selectedLayers !== undefined ? (
            <SortableList
              selected={props.selected}
              setSelected={setSelected}
              data={selectedLayers}
              setData={setOrder}
              setUserMode={setUserMode}
              setVisible={setVisible}
              setTitle={setTitle}
              deleteLayer={deleteLayer}
              icons={true}
            />
          ) : (
            ""
          )}
        </Box>
      </Scrollable>

      <ButtonsBot>
        <Space size="large">
          <Tooltip key="1" title="Add Dropdown">
            <label>
              <input
                type="file"
                id="folderupload"
                style={{ display: "none" }}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.target.files !== null) {
                    const filteredList = checkFileUpload(e.target.files);
                    if (filteredList.length > 0) {
                      uploadMultipleFiles(filteredList, FileType.image).then(
                        (urls) => {
                          let layerArray = createDropdownListFromFileList(
                            filteredList,
                            id,
                            false,
                            urls
                          ).map((dropdown) => dropdown.toJSON());
                          addDropdown(layerArray);
                          e.target.value = "";
                        }
                      );
                    } else {
                      message.warn("Error loading files.", 3);
                    }
                  }
                }}
                multiple
                accept=".jpg, .jpeg, .png"
                /* @ts-expect-error */
                directory=""
                webkitdirectory=""
              />

              <Button
                icon={<UnorderedListOutlined />}
                onClick={(e) => {
                  e.currentTarget.blur();
                  document.getElementById("folderupload")?.click();
                }}
              />
            </label>
          </Tooltip>
          <Tooltip title="Add Text">
            <Button
              onClick={(e) => {
                e.currentTarget.blur();
                addTextbox();
              }}
              key="2"
              icon={<FontSizeOutlined />}
            />
          </Tooltip>
          <Tooltip key="3" title="Add Image Upload">
            <label>
              <input
                type="file"
                id="fileupload"
                style={{ display: "none" }}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.target.files?.item(0) !== null) {
                    const file = e.target.files?.item(0);
                    uploadFile(file!, FileType.image).then((url) => {
                      addImageUpload(file, url);
                      e.target.value = "";
                    });
                  }
                }}
                accept=".jpg, .jpeg, .png"
              />
              <Button
                onClick={(e) => {
                  e.currentTarget.blur();
                  document.getElementById("fileupload")?.click();
                }}
                icon={<PictureOutlined />}
              />
            </label>
          </Tooltip>
          {props.selected !== "" ? (
            <>
              <Divider type="vertical" />
              <Tooltip key="4" title="Duplicate Layer">
                <Button
                  onClick={(e) => {
                    const selected = selectedLayers
                      .filter((layer: ILayer) => layer.id === props.selected)
                      .map((selected) => selected);
                    let copiedLayer = Object.assign({}, selected[0]);
                    if (copiedLayer.PK) {
                      delete copiedLayer.PK;
                    }
                    if (copiedLayer.SK) {
                      delete copiedLayer.SK;
                    }
                    copiedLayer.id = uuidv4();
                    copiedLayer.graphics = [];
                    selected[0].graphics.forEach(
                      (graphic: any, index: number) => {
                        let newGraphics = Object.assign({}, graphic);
                        newGraphics.id = uuidv4();
                        copiedLayer.graphics.push(newGraphics);
                      }
                    );

                    //Add Layer to Doc list
                    var newLayers = [
                      copiedLayer.id,
                      ...selectedDocument.layers,
                    ];
                    copiedLayer.selectedId = copiedLayer.graphics[0].id;
                    if (props.isTemplate) {
                      dispatch(updateTemplate([id, "layers", newLayers]));
                    } else {
                      dispatch(updateDocument([id, "layers", newLayers]));
                    }
                    dispatch(addLayer(copiedLayer));
                  }}
                  icon={<CopyOutlined />}
                />
              </Tooltip>
            </>
          ) : (
            <></>
          )}
        </Space>
      </ButtonsBot>
    </DroppableDiv>
  );

  function setVisible(value: boolean, index: number) {
    dispatch(
      updateLayer([
        selectedDocument.id,
        selectedDocument.layers[index],
        "visible",
        value,
      ])
    );
  }

  function setSelected(id: string) {
    if (id === "") {
      props.setSelected(id);
      return;
    }
    const selected = selectedLayers
      .filter((layer: ILayer) => layer.id === id)
      .map((selected) => selected);
    if (selected[0].type === "dropdown") {
      props.setToolType(selected[0].mask.currentTool);
      props.setSelectedGraphic(selected[0].selectedId);
    } else if (selected[0].type === "textbox") {
      props.setToolType(ToolType.selectLayer);
      props.setSelectedGraphic(selected[0].graphics[0].id);
    } else if (selected[0].type === "image_upload") {
      props.setToolType(selected[0].mask.currentTool);
      props.setSelectedGraphic(selected[0].graphics[0].id);
    }
    props.setSelected(id);
  }
  function setTitle(title: string, index: number) {
    dispatch(
      updateLayer([
        selectedDocument.id,
        selectedDocument.layers[index],
        "name",
        title,
      ])
    );
  }

  function deleteLayer(index: number) {
    //Remove Layer from Doc list
    var newLayers = Array.from(selectedDocument.layers);
    const layerId = newLayers[index] as string;
    newLayers.splice(index, 1);
    if (props.isTemplate) {
      dispatch(updateTemplate([id, "layers", newLayers]));
    } else {
      dispatch(updateDocument([id, "layers", newLayers]));
    }

    //Remove layer from redux+db
    dispatch(deleteLayerRedux([id, layerId]));
  }

  function addDropdown(layerArray: ILayer[]) {
    //Add Layer to Doc list
    var newLayers = [
      ...layerArray.map((layer) => layer.id),
      ...selectedDocument.layers,
    ];
    if (props.isTemplate) {
      dispatch(updateTemplate([id, "layers", newLayers]));
    } else {
      dispatch(updateDocument([id, "layers", newLayers]));
    }

    const updatedLayers = layerArray.map((layer) => {
      //Calculate Dropdown width+height
      const maxW = layer.graphics.reduce(
        (acc: number, val: Graphic) => Math.max(acc, val.width),
        0
      );
      const maxH = layer.graphics.reduce(
        (acc: number, val: Graphic) => Math.max(acc, val.height),
        0
      );

      var collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      layer.graphics.sort(function (a, b) {
        return collator.compare(a.name, b.name);
      });
      return {
        ...layer,
        selectedId: layer.graphics[0].id,
        width: maxW,
        height: maxH,
      };
    });
    //Add Layer to redux+db
    dispatch(addMultipleLayers(updatedLayers));
    props.setSelected(updatedLayers[0].id);
    props.setSelectedGraphic(updatedLayers[0].selectedId);
  }

  function addTextbox() {
    const layer = new Textbox(id, "New Text");
    layer.addGraphic(new Text());

    //Add Layer to Doc list
    var newLayers = [layer.id, ...selectedDocument.layers];

    if (props.isTemplate) {
      dispatch(updateTemplate([id, "layers", newLayers]));
    } else {
      dispatch(updateDocument([id, "layers", newLayers]));
    }

    //Add Layer to redux+db
    dispatch(addLayer(layer.toJSON()));
    props.setSelected(layer.id);
    props.setSelectedGraphic(layer.graphics[0].id);
  }

  function addImageUpload(file: File | null | undefined, data: ImageData) {
    const child = new ImageFile(file!, 0, 0, true);
    child.url = data.url;
    child.width = data.width;
    child.height = data.height;
    child.srcWidth = data.width;
    child.srcHeight = data.height;
    const parent = new ImageUpload(id, "Image Upload 1");
    parent.addGraphic(child);
    parent.mask.width = selectedDocument.width;
    parent.mask.height = selectedDocument.height;
    parent.mask.imgHeight = selectedDocument.width;
    parent.mask.imgWidth = selectedDocument.height;
    parent.mask.setRectangle();

    //Add Layer to Doc list
    var newLayers = [parent.id, ...selectedDocument.layers];
    if (props.isTemplate) {
      dispatch(updateTemplate([id, "layers", newLayers]));
    } else {
      dispatch(updateDocument([id, "layers", newLayers]));
    }
    //Add Layer to redux+db
    dispatch(addLayer(parent.toJSON()));
    props.setSelected(parent.id);
    props.setSelectedGraphic(child.id);
  }

  function setOrder(newLayerOrder: Layer[]) {
    let idLayers = newLayerOrder.map((layer) => {
      return layer.id;
    });
    if (props.isTemplate) {
      dispatch(updateTemplate([id, "layers", idLayers]));
    } else {
      dispatch(updateDocument([id, "layers", idLayers]));
    }
  }
  function setUserMode(index: number, userMode: boolean) {
    dispatch(
      updateLayer([
        selectedDocument.id,
        selectedDocument.layers[index],
        "userMode",
        userMode,
      ])
    );
  }

  function scanAndLogFiles(
    item: any,
    itemList: Array<File>,
    flag: boolean = true
  ) {
    if (item.isDirectory) {
      var directoryReader = item.createReader();
      directoryReader.readEntries((entries: any) => {
        entries.forEach((entry: any) => {
          if (entry.isFile) {
            entry.file((file: any) => {
              file.fullPath = entry.fullPath;
              if (pathChecker(file.fullPath)) {
                itemList.push(file);
              }
            });
          }
          scanAndLogFiles(entry, itemList, false);
        });
      });
    } else if (item.isFile && flag) {
      item.file((file: any) => {
        file.fullPath = item.fullPath;
        if (pathChecker(file.fullPath)) {
          itemList.push(file);
        }
      });
    }
  }
  function checkFileUpload(files: FileList) {
    let newFiles = [];
    for (let i = 0; i < files.length; i++) {
      const currFile = files.item(i);
      if (
        pathChecker((currFile as any).webkitRelativePath) &&
        currFile !== null
      ) {
        newFiles.push(currFile);
      }
    }

    return newFiles;
  }
  function pathChecker(path: string) {
    if (path.includes("__MACOSX")) {
      return false;
    }
    if (path.includes(".DS_STORE")) {
      return false;
    }
    if (path.includes(".ds_store")) {
      return false;
    }
    if (path.includes(".DS_Store")) {
      return false;
    }
    if (path[1] === ".") {
      return false;
    }
    return true;
  }
}

export default LeftSidebar;
