import { useEffect, useState } from "react";
import { Drawer, Card, Button } from "antd";

import { getDocuments, selectAllDocuments } from "../../redux/documentSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { DocumentCard } from "../UserDashboard/DocumentCard";

export default function UserLayoutList(props: {
  documentList: string[];
  name: string;
}) {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const allDocuments: any = useAppSelector((state) => {
    return selectAllDocuments(state);
  });
  useEffect(() => {
    if (allDocuments === null || allDocuments.length === 0) {
      dispatch(getDocuments());
    }
  }, []);
  return (
    <>
      <Drawer
        width={391}
        visible={open}
        onClose={() => {
          setOpen(false);
        }}
        title={"Layouts for " + props.name}
      >
        {allDocuments !== null ? (
          <>
            {allDocuments.map((doc: any) => {
              if (props.documentList.includes(doc.id)) {
                {
                  return (
                    <DocumentCard
                      key={doc.id + "_preview"}
                      document={doc}
                      preview
                    />
                  );
                }
              }
            })}
          </>
        ) : (
          <></>
        )}
      </Drawer>
      <Button
        type="primary"
        onClick={(e) => {
          e.preventDefault();
          e.currentTarget.blur();
          setOpen(true);
        }}
      >
        Assigned Layouts
      </Button>
    </>
  );
}
