import React, { Component, useState, useEffect } from "react";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { PageHeader } from "antd";
import { useHistory, Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const Scrollable = styled.div`
  width: 100%;
  height: calc(100vh - 78px);
`;
const BackButton = styled.div`
  width: 120px;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 30px;
`;
const EmptySpace = styled.div`
  width: 100%;
  height: 100px;
`;

export function TutorialPost() {
  const { id } = useParams() as any;

  const [post, setPost] = useState({
    html: "",
    excerpt: "",
    title: "",
    feature_image: "",
    tags: { name: "" },
  });
  useEffect(() => {
    fetch(
      "https://blog.teaminfo.graphics/ghost/api/v3/content/posts/" +
        id +
        "/?key=9efebe93e79e2f1e4e85567af3&include=tags"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const post = data.posts[0];
        setPost({
          html: post.html,
          excerpt: post.excerpt || "",
          title: post.title || "",
          feature_image: post.feature_image || "",
          tags: post.primary_tag || "",
        });
      });
  }, []);

  const clean = DOMPurify.sanitize(post.html, {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
  });
  let history = useHistory();
  return (
    <div className="dark-mode">
      <PageHeader
        className="site-page-header"
        onBack={() => {
          history.goBack();
        }}
        backIcon=""
        title={
          <Link to="/tutorials">
            <BackButton>
              <ArrowLeftOutlined /> Tutorials
            </BackButton>
          </Link>
        }
      />
      <Scrollable className="dark-mode" data-simplebar>
        <header className="article-header gh-canvas">
          <section className="article-tag">{post.tags.name}</section>

          <h1 className="article-title">{post.title}</h1>

          <p className="article-excerpt">{post.excerpt}</p>
          {post.feature_image !== "" ? (
            <figure className="article-image">
              <img
                src={post.feature_image}
                alt="Start here for a quick overview of everything you need to know"
              />
            </figure>
          ) : (
            <></>
          )}
        </header>
        <div
          className="gh-content gh-canvas"
          dangerouslySetInnerHTML={{ __html: clean }}
        ></div>
        <EmptySpace />
      </Scrollable>
      <link
        rel="stylesheet"
        href="https://blog.teaminfo.graphics/assets/built/screen.css?v=435b6d5aea"
      />
    </div>
  );
}
