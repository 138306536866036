import "./index.css";
import "antd/dist/antd.less";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/Store";
import App from "./App";
import netlifyIdentity from "netlify-identity-widget";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from "react-ga";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://f4bf8cca8a8e4b16bb3960a37f2e6407@o1113609.ingest.sentry.io/6144263",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  ReactGA.initialize("UA-38108960-5");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

netlifyIdentity.init({
  logo: false,
  APIUrl:
    process.env.NODE_ENV === "development"
      ? "https://dev.teaminfo.graphics/.netlify/identity"
      : `https://${document.location.host}/.netlify/identity`,
});

const consoleError = console.error.bind(console);
console.error = (errObj: any, ...args: any[]) => {
  if (
    process.env.NODE_ENV === "development" &&
    typeof errObj === "string" &&
    args.includes("findDOMNode")
  ) {
    return;
  }
  consoleError(errObj, ...args);
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// Front end key:fnAENIwbSVACRMRraR5ov620DFnJTWQbeDFskL_2
