import React, { useEffect, useState } from "react";
import { Button, Space, Input, Typography } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";

import netlifyIdentity from "netlify-identity-widget";
import {
  getUserName,
  selectAllUsers,
  updateUser,
  deleteUser,
} from "../../redux/userSlice";
import { useParams, useHistory } from "react-router-dom";
import { User } from "../../core/User";
import UserDashboard from "../UserDashboard";
type TypeID = {
  accountId: string;
  userId: string;
};
export default function UserLogin() {
  const [username, setUsetname] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [login, setlogin] = useState(false);

  const { accountId, userId } = useParams() as TypeID;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUserName([accountId, userId]));
    if (window.localStorage.getItem(userId)) {
      setlogin(true);
    }
  }, []);
  const user = useAppSelector((state) => {
    return selectAllUsers(state)[0];
  }) as User;

  useEffect(() => {
    if (user) {
      setUsetname(user.username);
    }
  }, [user]);
  if (login) return <UserDashboard setLogin={setlogin} />;
  else {
    return (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
          background:
            "url('https://tig-ignite.s3.amazonaws.com/Login_Page_back.png')",
          backgroundSize: "contain",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          gap: 20,
        }}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div>
            <Space direction="vertical">
              <div
                style={{
                  width: 435,
                  height: 100,
                  background:
                    "url('https://tig-ignite.s3.amazonaws.com/Ti_Ignite_Logo.png')",
                  backgroundSize: 200,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
              <Typography.Title>{`Welcome ${username}`}</Typography.Title>
              <Typography.Text>Enter password:</Typography.Text>
              <div style={{ width: 200, margin: "auto" }}>
                <Input.Password
                  onChange={(e) => {
                    setPassword(e.currentTarget.value);
                  }}
                  placeholder="Password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </div>
              <div style={{ height: 20 }}>{message}</div>
              <div style={{ height: 40, width: "100%" }}></div>
              <Button
                value="large"
                onClick={() => {
                  if (password === user.password) {
                    setPassword("");
                    setMessage("");
                    window.localStorage.setItem(userId, "true");
                    setlogin(true);
                  } else {
                    setMessage("Incorrect Password");
                    setTimeout(() => {
                      setMessage("");
                    }, 5000);
                  }
                }}
              >
                Login
              </Button>
            </Space>
          </div>
        </div>
      </div>
    );
  }
}
