import { ToolType } from "./Event/CanvasEvent";

export enum ShapeType {
  none = "none",
  rectangle = "rectangle",
  circle = "circle",
}
export default class Mask {
  type: ShapeType;
  currentTool: ToolType = ToolType.selectLayer;
  width: number = 200;
  height: number = 200;
  x: number = 0;
  y: number = 0;

  imgWidth: number = 200;
  imgHeight: number = 200;
  imgX: number = 0;
  imgY: number = 0;
  rotate: number = 0;
  constructor(type: ShapeType = ShapeType.none) {
    this.type = type;
  }

  setRectangle() {
    this.type = ShapeType.rectangle;
  }

  setCircle() {
    this.type = ShapeType.circle;
  }

  setNone() {
    this.type = ShapeType.none;
  }

  resize(newWidth: number, newHeight: number) {
    this.width = newWidth;
    this.height = newHeight;
  }

  toJSON() {
    return {
      ...this,
    };
  }
}
