import netlifyIdentity from "netlify-identity-widget";
import { message } from "antd";

const maxUploadDimW = 3840;
const maxUploadDimH = 3840;

export enum FileType {
  image = "image",
  font = "font",
}

export async function uploadFile(file: File, type: FileType) {
  const m1 = message.loading(`Uploading File ${file.name} ...`, 0);
  const { data, width, height } = await processFile(file);
  const url = await fileToS3(data, type);
  m1();
  message.success("File Uploaded", 3);
  return { url, width, height };
}

export async function uploadScreenshotToS3(
  file: File,
  type: FileType,
  documentId: string
) {
  // const m1 = message.loading(`Uploading File ${file.name} ...`, 0);
  const { data, width, height } = await processFile(file);
  const url = await fileToS3(data, type, "webp", documentId);
  // message.success("File Uploaded", 3);
  return { url, width, height };
}

export async function uploadFont(file: File, fileExtension: string = "ttf") {
  const m1 = message.loading(`Uploading File ${file.name} ...`, 0);
  const url = await fileToS3(file, FileType.font, fileExtension);
  m1();
  return url;
}

export async function uploadMultipleFiles(files: File[], type: FileType) {
  let urls = [];
  const m1 = message.loading(`Uploading ${files.length} Files...`, 0);
  let flag = true;
  for (let file of files) {
    try {
      const { data, width, height } = await processFile(file);
      const url = await fileToS3(data, type);
      urls.push({ url, width, height });
    } catch (e) {
      flag = false;
      message.error(`Error while loading file ${file.name}`, 4);
    }
  }
  m1();
  if (flag) {
    message.success("Files Uploaded", 3);
  } else {
    message.warn("Couldn't load some files.", 3);
  }
  return urls;
}
async function fileToS3(
  blob: Blob | File,
  type: string,
  fileExtension?: string,
  documentId?: string
): Promise<string> {
  return new Promise(function (resolve, reject) {
    // save the image
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = async function () {
      const token = netlifyIdentity.currentUser()?.token?.access_token;

      let data = {};
      if (documentId !== null) {
        data = {
          data: reader.result,
          type: type,
          extension: fileExtension,
          documentId: documentId,
        };
      } else {
        data = {
          data: reader.result,
          type: type,
          extension: fileExtension,
        };
      }
      await fetch("/.netlify/functions/upload", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "post",
        body: JSON.stringify(data),
      })
        .then((response) => response.text())
        .then((result) => resolve(result))
        .catch((error) => reject(console.log("error", error)));
    };
  });
}

function processFile(
  file: File
): Promise<{ data: Blob; width: number; height: number }> {
  // Load the data into an image
  return new Promise<HTMLImageElement>(function (resolve, reject) {
    let rawImage = new window.Image();
    rawImage.onload = function () {
      resolve(rawImage);
    };
    rawImage.onerror = function () {
      reject(console.log("error"));
    };
    rawImage.src = URL.createObjectURL(file);
  }).then(function (rawImage: HTMLImageElement) {
    // Convert image to webp ObjectURL via a canvas blob
    return new Promise(function (resolve, reject) {
      const maxDimensions = {
        width: maxUploadDimW,
        height: maxUploadDimH,
      };
      let width = rawImage.width;
      let height = rawImage.height;
      let isTooLarge = false;

      if (width > height && width > maxDimensions.width) {
        // width is the largest dimension, and it's too big.
        height *= maxDimensions.width / width;
        width = maxDimensions.width;
        isTooLarge = true;
      } else if (height > maxDimensions.height) {
        // either width wasn't over-size or height is the largest dimension
        // and the height is over-size
        width *= maxDimensions.height / height;
        height = maxDimensions.height;
        isTooLarge = true;
      }
      let canvas = document.createElement("canvas");

      canvas.width = isTooLarge ? width : rawImage.width;
      canvas.height = isTooLarge ? height : rawImage.height;
      let ctx = canvas.getContext("2d");
      if (ctx !== null) {
        ctx.drawImage(rawImage, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(function (blob) {
          resolve({ data: blob!, width: canvas.width, height: canvas.height });
        }, "image/webp");
      } else {
        reject();
      }
    });
  });
}
