import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { User } from "../core/User";
import { PayloadAction } from "@reduxjs/toolkit";
import { displayError, getTokenAndId, handleResponseErrors } from "./helpers";

type UserState = {
  [index: string]: User;
};

const initialState: UserState = {};

var baseUrl = "/.netlify/functions/";

/*
 * Async
 */

export const addUser = createAsyncThunk<any, User>(
  "users/addUser",
  async (userToAdd, thunkApi) => {
    const userData = await getTokenAndId();

    const data = {
      ...userToAdd,
      accountId: userData.id,
      created: Date.now().toString(),
      updated: Date.now().toString(),
    };

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "user-create", requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

export const getUsers = createAsyncThunk<any>(
  "users/getUsers",
  async (data, thunkApi) => {
    const userData = await getTokenAndId();

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    const response = await fetch(baseUrl + `user-read`, requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

export const updateUser = createAsyncThunk<any, [string, keyof User, any]>(
  "users/updateUser",
  async ([userId, propertyName, propertyValue], thunkApi) => {
    const userData = await getTokenAndId();
    const data = {
      id: userId,
      propertyName,
      propertyValue,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "user-update", requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return data;
  }
);

export const deleteUser = createAsyncThunk<any, [string]>(
  "users/deleteUser",
  async ([deleteId], thunkApi) => {
    const userData = await getTokenAndId();

    const data = {
      id: deleteId,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "user-delete", requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return data.id;
  }
);

export const getUserName = createAsyncThunk<any, [string, string]>(
  "users/getUserName",
  async (ids, thunkApi) => {
    const data = {
      accountId: ids[0],
      userId: ids[1],
    };
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + `user-get`, requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

export const updateUserMultiParams = createAsyncThunk<
  any,
  [string, Array<keyof User>, Array<any>]
>(
  "users/updateUserMultiParams",
  async ([userId, propertyName, propertyValue], thunkApi) => {
    const userData = await getTokenAndId();

    const data = {
      id: userId,
      propertyName,
      propertyValue,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "user-update", requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return data;
  }
);

export const unassignDocument = createAsyncThunk<any, string>(
  "users/unassignDocument",
  async (documentToUnassign, thunkApi) => {
    const userData = await getTokenAndId();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    const { users } = thunkApi.getState() as any;
    let newUsers = Object.assign({}, users);
    for (const index in newUsers) {
      for (var i = 0; i < newUsers[index].documents.length; i++) {
        if (newUsers[index].documents[i] === documentToUnassign) {
          let newUser = Object.assign({}, users[index]);
          let newDocs = Array.from(users[index].documents);
          newDocs.splice(i, 1);
          const data = {
            id: newUser.id,
            propertyName: "documents",
            propertyValue: newDocs,
          };
          var raw: string = JSON.stringify(data);
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
          };
          const response = await fetch(baseUrl + "user-update", requestOptions);
          if (response.status !== 200) {
            return await handleResponseErrors(thunkApi, response);
          }
          newUser.documents = newDocs;
          newUsers[index] = newUser;
        }
      }
    }
    return newUsers;
  }
);

/*
 * Reducer
 */
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addUser.fulfilled, (state, { payload }) => {
      state[payload.id] = payload;
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state = payload.map((user: any) => {
        return (state[user.id] = user);
      });
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      const key = payload.propertyName;
      const value = payload.propertyValue;
      Object.assign(state[payload.id], { [key]: value });
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      delete state[payload];
    });
    builder.addCase(getUserName.fulfilled, (state, { payload }) => {
      state = payload.map((user: any) => {
        return (state[user.id] = user);
      });
    });
    builder.addCase(updateUserMultiParams.fulfilled, (state, { payload }) => {
      payload.propertyName.map((name: string, i: number) => {
        Object.assign(state[payload.id], {
          [name]: payload.propertyValue[i],
        });
      });
    });

    builder.addCase(unassignDocument.fulfilled, (state, { payload }) => {
      state = payload;
    });

    /*
     * Rejected
     */
    builder.addCase(addUser.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(getUserName.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(updateUserMultiParams.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(unassignDocument.rejected, (state, action) => {
      displayError(action.payload as string);
    });
  },
});

/*
 * Selectors
 */

//Select all?
const selectUsers = (state: any) => state.users;
export const selectAllUsers = createSelector(selectUsers, (entities) =>
  Object.values(entities)
);

// export const selectDocumentById = createSelector(
//   [(state) => state.documents, (state: any, docId: string) => docId],
//   (items, docId) => {
//     return items[docId];
//
//   }
// );

/*
 * Exports
 */

// export const { addDocument } = documentsSlice.actions;
export default userSlice.reducer;
