import React, { useEffect, useState, useCallback } from "react";
import {
  Divider,
  Radio,
  Popover,
  Space,
  Typography,
  Select,
  InputNumber,
  Input,
  Slider,
  Row,
  Tooltip,
  Button,
  Col,
  message,
} from "antd";
import {
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  VerticalAlignTopOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignBottomOutlined,
  UploadOutlined,
  BoldOutlined,
  ItalicOutlined,
} from "@ant-design/icons";
import { ChromePicker } from "react-color";
import { selectLayerById, updateLayerLocal } from "../../../redux/layerSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { debounce } from "../../../utils/debounce";
import FontName from "fontname";
import { uploadFont } from "../../../utils/uploadFile";
import { updateDocument } from "../../../redux/documentSlice";
import { updateTemplate } from "../../../redux/templateSlice";
import { useParams } from "react-router-dom";
import { selectDocumentById } from "../../../redux/documentSlice";
import { selectTemplateById } from "../../../redux/templateSlice";
import styled from "styled-components";
import fonts from "../../../fonts.json";
const { Option, OptGroup } = Select;
let text: any;
type TypeID = {
  id: string;
};

let RemoveFont = styled.div`
  float: right;
  z-index: 10;
  padding: 0px 10px;
  &:hover {
    color: red;
  }
`;
export default function TextboxToolbox(props: any) {
  const { id } = useParams() as TypeID;
  const dispatch = useAppDispatch();
  const selectedDocument = useAppSelector((state) => {
    if (props.isTemplate) {
      return selectTemplateById(state, id);
    } else {
      return selectDocumentById(state, id);
    }
  });
  const updateTextDB = useCallback(debounce(props.updateGraphic, 200), [
    props.selected,
  ]);

  const [displayPallete, setDisplayPallete] = useState(false);
  const selectedLayer = useAppSelector((state) =>
    selectLayerById(state, props.selected)
  );
  text = selectedLayer.graphics[0];
  const [color, setColor] = useState({
    r: text.color[0],
    g: text.color[1],
    b: text.color[2],
  });
  useEffect(() => {
    setColor({
      r: text.color[0],
      g: text.color[1],
      b: text.color[2],
    });
  }, [props]);

  useEffect(() => {
    text = selectedLayer.graphics[0];
  }, [props.selected]);
  return (
    <>
      <Typography.Title level={5}> Font Family </Typography.Title>
      <Space>
        <Select
          value={text.fontFamily}
          showSearch
          style={{ width: 200 }}
          placeholder="Select Font"
          filterOption={(input, option) => {
            if (option?.options) {
              return false;
            } else {
              return (
                option?.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }
          }}
          onChange={(value) => {
            const textData = { ...text };
            textData.fontFamily = value;
            props.updateGraphic(textData, 0);
          }}
        >
          {Array.isArray(selectedDocument.fonts) &&
          selectedDocument.fonts.length ? (
            <OptGroup label="Custom Fonts">
              {selectedDocument.fonts.map(
                (element: { name: string; url: string }) => {
                  return (
                    <Option
                      key={element.url}
                      value={element.url}
                      name={element.name}
                    >
                      {element.name}{" "}
                      {text.fontFamily !== element.url ? (
                        <Tooltip title="Remove Font">
                          <RemoveFont
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              removeFontFromList(element.name, element.url);
                            }}
                          >
                            x
                          </RemoveFont>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </Option>
                  );
                }
              )}
            </OptGroup>
          ) : (
            <></>
          )}
          {Array.isArray(fonts) ? (
            <OptGroup label="Available Fonts">
              {fonts.map((element: { name: string; url: string }) => {
                return (
                  <Option
                    key={element.url}
                    value={element.url}
                    name={element.name}
                  >
                    {element.name}
                  </Option>
                );
              })}
            </OptGroup>
          ) : (
            <></>
          )}
        </Select>
        <label>
          <input
            type="file"
            id="fileuploadItem"
            style={{ display: "none" }}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.target.files?.item(0) !== null) {
                const file = e.target.files?.item(0);
                file
                  ?.arrayBuffer()
                  .then((fileBuffer) => {
                    const fileName = FontName.parse(fileBuffer)[0];
                    const extension = file.name?.split(".").pop();
                    uploadFont(file!, extension).then((url) => {
                      let fontName = fileName.fontFamily;
                      if (fileName.fontSubfamily !== undefined) {
                        fontName =
                          fileName.fontFamily + " " + fileName.fontSubfamily;
                      }
                      addFontToList(fontName, url);

                      const textData = { ...text };
                      textData.fontFamily = url;
                      props.updateGraphic(textData, 0);
                      message.success(
                        "Added Font" +
                          fileName.fontFamily +
                          " " +
                          fileName.fontSubfamily
                      );
                    });
                  })
                  .catch((e) => {
                    console.log(e);
                    message.error("Error while uploading Font");
                  });
              }
            }}
            accept=".otf, .woff, .ttf"
          />

          <Tooltip title="Upload Custom Font">
            <Button
              style={{ float: "right" }}
              icon={<UploadOutlined />}
              ghost
              onClick={(e) => {
                e.currentTarget.blur();
                document.getElementById("fileuploadItem")?.click();
              }}
            />
          </Tooltip>
        </label>
      </Space>
      <Divider></Divider>
      <Typography.Title level={5}> Value </Typography.Title>
      <Row>
        <Input
          key={text.id + "txt"}
          defaultValue={text.value}
          style={{ width: 200 }}
          onChange={(e) => {
            const textData = { ...text };
            textData.value = e.target.value;
            updateLocal(textData, selectedLayer.id);
            updateTextDB(textData, 0);
          }}
        />
      </Row>
      <Divider></Divider>
      <Typography.Text> Font Size </Typography.Text>
      <Row>
        <Col span={12}>
          <Slider
            key={text.id + "fs"}
            min={1}
            max={400}
            onAfterChange={(value: number) => {
              const textData = { ...text };
              textData.fontSize = value;
              props.updateGraphic(textData, 0);
            }}
            onChange={(value: number) => {
              const textData = { ...text };
              textData.fontSize = value;
              updateLocal(textData, selectedLayer.id);
            }}
            defaultValue={text.fontSize}
          />
        </Col>
        <Col span={4}>
          <Input.Group>
            <InputNumber
              value={text.fontSize}
              style={{ width: 65 }}
              onChange={(value) => {
                const textData = { ...text };
                textData.fontSize = value;
                updateLocal(textData, selectedLayer.id);
                updateTextDB(textData, 0);
              }}
            />
            <span className="ant-input-group-addon">px</span>
          </Input.Group>
        </Col>
      </Row>
      <Typography.Text> Letter Spacing </Typography.Text>
      <Row>
        <Col span={12}>
          <Slider
            min={-15}
            max={30}
            key={text.id + "ls"}
            onAfterChange={(value: number) => {
              const textData = { ...text };
              textData.letterSpacing = value;
              props.updateGraphic(textData, 0);
            }}
            onChange={(value: number) => {
              const textData = { ...text };
              textData.letterSpacing = value;
              updateLocal(textData, selectedLayer.id);
            }}
            defaultValue={text.letterSpacing}
          />
        </Col>
        <Col span={4}>
          <Input.Group>
            <InputNumber
              value={text.letterSpacing}
              style={{ width: 65 }}
              onChange={(value) => {
                const textData = { ...text };
                textData.letterSpacing = Math.trunc(value);
                updateLocal(textData, selectedLayer.id);
                updateTextDB(textData, 0);
              }}
            />
            <span className="ant-input-group-addon">px</span>
          </Input.Group>
        </Col>
      </Row>
      <Typography.Text> Line Height </Typography.Text>
      <Row>
        <Col span={12}>
          <Slider
            min={30}
            max={200}
            key={text.id + "ls"}
            onAfterChange={(value: number) => {
              const textData = { ...text };
              textData.lineHeight = value;
              props.updateGraphic(textData, 0);
            }}
            onChange={(value: number) => {
              const textData = { ...text };
              textData.lineHeight = value;
              updateLocal(textData, selectedLayer.id);
            }}
            defaultValue={text.lineHeight}
          />
        </Col>
        <Col span={4}>
          <Input.Group>
            <InputNumber
              value={text.lineHeight}
              style={{ width: 65 }}
              onChange={(value) => {
                const textData = { ...text };
                textData.lineHeight = value;
                updateLocal(textData, selectedLayer.id);
                updateTextDB(textData, 0);
              }}
            />
            <span className="ant-input-group-addon">%</span>
          </Input.Group>
        </Col>
      </Row>
      <Typography.Text> Rotation </Typography.Text>
      <Row>
        <Col span={12}>
          <Slider
            min={0}
            max={360}
            key={text.id + "ls"}
            onAfterChange={(value: number) => {
              const textData = { ...text };
              textData.rotate = value;
              props.updateGraphic(textData, 0);
            }}
            onChange={(value: number) => {
              const textData = { ...text };
              textData.rotate = value;
              updateLocal(textData, selectedLayer.id);
            }}
            defaultValue={text.rotate}
          />
        </Col>
        <Col span={4}>
          <Input.Group>
            <InputNumber
              value={text.rotate}
              style={{ width: 65 }}
              onChange={(value) => {
                const textData = { ...text };
                textData.rotate = value;
                updateLocal(textData, selectedLayer.id);
                updateTextDB(textData, 0);
              }}
            />
            <span className="ant-input-group-addon">%</span>
          </Input.Group>
        </Col>
      </Row>
      <Divider></Divider>
      <Space split={<Divider type="vertical" />}>
        <Typography.Text> Style </Typography.Text>
        <Space>
          {text.bold ? (
            <Button
              onClick={(e) => {
                e.currentTarget.blur();
                const textData = { ...text };
                textData.bold = false;
                updateLocal(textData, selectedLayer.id);
                updateTextDB(textData, 0);
              }}
              type="primary"
              ghost
              icon={<BoldOutlined />}
            />
          ) : (
            <Button
              onClick={(e) => {
                e.currentTarget.blur();
                const textData = { ...text };
                textData.bold = true;
                updateLocal(textData, selectedLayer.id);
                updateTextDB(textData, 0);
              }}
              icon={<BoldOutlined />}
            />
          )}
          {text.italic ? (
            <Button
              onClick={(e) => {
                e.currentTarget.blur();
                const textData = { ...text };
                textData.italic = false;
                updateLocal(textData, selectedLayer.id);
                updateTextDB(textData, 0);
              }}
              type="primary"
              ghost
              icon={<ItalicOutlined />}
            />
          ) : (
            <Button
              onClick={(e) => {
                e.currentTarget.blur();
                const textData = { ...text };
                textData.italic = true;
                updateLocal(textData, selectedLayer.id);
                updateTextDB(textData, 0);
              }}
              icon={<ItalicOutlined />}
            />
          )}
        </Space>
      </Space>
      <Divider></Divider>
      <Space>
        <Typography.Text> Align </Typography.Text>
        <Divider type="vertical" />
        <Radio.Group
          value={text.hAlign}
          onChange={(e) => {
            const textData = { ...text };
            textData.hAlign = e.target.value;
            props.updateGraphic(textData, 0);
          }}
        >
          <Tooltip title="Left">
            <Radio.Button value="left">
              <AlignLeftOutlined />
            </Radio.Button>
          </Tooltip>
          <Tooltip title="Center">
            <Radio.Button value="center">
              <AlignCenterOutlined />
            </Radio.Button>
          </Tooltip>
          <Tooltip title="Right">
            <Radio.Button value="right">
              <AlignRightOutlined />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
        <Radio.Group
          value={text.vAlign}
          onChange={(e) => {
            const textData = { ...text };
            textData.vAlign = e.target.value;
            props.updateGraphic(textData, 0);
          }}
        >
          <Tooltip title="Top">
            <Radio.Button value="top">
              <VerticalAlignTopOutlined />
            </Radio.Button>
          </Tooltip>
          <Tooltip title="Middle">
            <Radio.Button value="middle">
              <VerticalAlignMiddleOutlined />
            </Radio.Button>
          </Tooltip>
          <Tooltip title="Bottom">
            <Radio.Button value="bottom">
              <VerticalAlignBottomOutlined />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
      </Space>
      <Divider></Divider>
      <Space split={<Divider type="vertical" />}>
        <Typography.Text> Color </Typography.Text>
        <Popover
          content={
            <ChromePicker
              color={color}
              key={text.id + "cl"}
              onChangeComplete={(newColor) => {
                const color = newColor.rgb;
                const textData = { ...text };
                textData.color = [color.r, color.g, color.b];
                updateTextDB(textData, 0);
              }}
              disableAlpha={true}
              onChange={(newColor) => {
                setColor({
                  r: newColor.rgb.r,
                  g: newColor.rgb.g,
                  b: newColor.rgb.b,
                });
                const textData = { ...text };
                textData.color = [
                  newColor.rgb.r,
                  newColor.rgb.g,
                  newColor.rgb.b,
                ];
                updateLocal(textData, selectedLayer.id);
              }}
            />
          }
          trigger="click"
          visible={displayPallete}
          onVisibleChange={(visible) => {
            setDisplayPallete(visible);
          }}
        >
          <div
            style={{
              backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b},${props.opacity})`,
              width: 40,
              height: 40,
              color: "gray",
              cursor: "pointer",
              border: "1px solid #434343",
              borderRadius: 100,
            }}
            onClick={() => setDisplayPallete(true)}
          ></div>
        </Popover>
      </Space>
    </>
  );
  function updateLocal(textData: any, id: string) {
    dispatch(
      updateLayerLocal({
        id: id,
        propertyName: "graphics",
        propertyValue: [textData],
      })
    );
  }
  function addFontToList(fontName: string, fontUrl: string) {
    if (props.isTemplate) {
      if (Array.isArray(selectedDocument.fonts)) {
        let newFonts = Array.from(selectedDocument.fonts);
        newFonts.push({ name: fontName, url: fontUrl });
        dispatch(updateTemplate([id, "fonts", newFonts]));
      } else {
        dispatch(
          updateTemplate([id, "fonts", [{ name: fontName, url: fontUrl }]])
        );
      }
    } else {
      if (Array.isArray(selectedDocument.fonts)) {
        let newFonts = Array.from(selectedDocument.fonts);
        newFonts.push({ name: fontName, url: fontUrl });
        dispatch(updateDocument([id, "fonts", newFonts]));
      } else {
        dispatch(
          updateDocument([id, "fonts", [{ name: fontName, url: fontUrl }]])
        );
      }
    }
  }

  function removeFontFromList(fontName: string, fontUrl: string) {
    if (!Array.isArray(selectedDocument.fonts)) return;

    let newFonts = Array.from(selectedDocument.fonts) as {
      url: string;
      name: string;
    }[];
    let index = -1;
    for (let i = 0; i < newFonts.length; i++) {
      if (newFonts[i].name === fontName && newFonts[i].url === fontUrl) {
        index = i;
      }
    }
    if (index > -1) {
      newFonts.splice(index, 1);
      if (props.isTemplate) {
        dispatch(updateTemplate([id, "fonts", newFonts]));
      } else {
        dispatch(updateDocument([id, "fonts", newFonts]));
      }
      message.success("Font Deleted");
    }
  }
}
