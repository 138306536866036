import {
  Space,
  Typography,
  Select,
  InputNumber,
  Input,
  Button,
  Divider,
  Radio,
  Tooltip,
  Slider,
  Row,
  Col,
} from "antd";
import { uploadFile, FileType } from "../../../utils/uploadFile";
import {
  UploadOutlined,
  ExpandOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { SortableList } from "../../Components/SortableList/SortableList";
import { useParams } from "react-router-dom";

import styled from "styled-components";
import Graphic from "../../../core/Graphic/Graphic";
import { ImageFile } from "../../../core/Graphic/Image";
import TextboxToolbox from "./TextboxToolbox";
import { ShapeType } from "../../../core/Mask";

import Title from "antd/lib/typography/Title";

//Redux Imports
import layerSlice, {
  selectLayerById,
  updateLayer,
  updateLayerLocal,
  updateLayerMultiParams,
} from "../../../redux/layerSlice";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { ToolType } from "../../../core/Event/CanvasEvent";
import ExportButton from "../../Components/ExportButton";
import SizePositionToolbox from "./SizePositionToolbox";
import DropdownToolbox from "./DropdownToolbox";

type TypeID = {
  id: string;
};
type ImageData = { url: string; width: number; height: number };

const Box = styled.div`
  padding: 10px 30px;
  border-bottom: 1px solid gray;
  width: 300px;
`;

const SliderContainer = styled.div`
  width: 100px;
  border: white;
`;

const BlendModes = [
  { title: "Normal", value: "Normal" },
  { title: "Multiply", value: "Multiply" },
  { title: "Screen", value: "Screen" },
  { title: "Overlay", value: "Overlay" },
  { title: "Darken", value: "Darken" },
  { title: "Lighten", value: "Lighten" },
  { title: "Color Dodge", value: "ColorDodge" },
  { title: "Color Burn", value: "ColorBurn" },
  { title: "Hard Light", value: "HardLight" },
  { title: "Soft Light", value: "SoftLight" },
  { title: "Difference", value: "Difference" },
  { title: "Exclusion", value: "Exclusion" },
  { title: "Hue", value: "Hue" },
  { title: "Saturation", value: "Saturation" },
  { title: "Color", value: "Color" },
  { title: "Luminosity", value: "Luminosity" },
];
const Scrollable = styled.div`
  width: 100%;
  height: calc(100vh - 121px);
`;

function RightSidebar(props: any) {
  const { id } = useParams() as TypeID;
  const dispatch = useAppDispatch();

  const selectedLayer = useAppSelector((state) =>
    selectLayerById(state, props.selected)
  );
  let selectedGraphicIndex = 0;
  if (selectedLayer !== undefined) {
    if (
      selectedLayer.type === "dropdown" &&
      selectedLayer.selectedId !== "" &&
      selectedLayer.graphics.length > 0
    ) {
      for (let i = 0; i < selectedLayer.graphics.length; i++) {
        if (selectedLayer.selectedId === selectedLayer.graphics[i].id) {
          selectedGraphicIndex = i;
        }
      }
    }
  }

  let currentGraphic = selectedLayer?.graphics[selectedGraphicIndex];
  return (
    <Scrollable data-simplebar>
      <Space direction="vertical" align="center">
        <Box>
          <h4
            style={{
              textAlign: "center",
              paddingBottom: "12px",
              marginTop: "12px",
              fontSize: "20px",
            }}
          >
            Layer {selectedLayer?.name}
          </h4>
          <Space align="center" split={<Divider type="vertical" />}>
            <Typography.Text> Blend Mode </Typography.Text>
            <Select
              value={selectedLayer?.blendMode}
              onChange={(value) => {
                updateBlendMode(value);
              }}
              style={{ width: 120 }}
            >
              {BlendModes.map((option, index) => {
                return (
                  <Select.Option key={index} value={option.value}>
                    {option.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Space>
          <Space align="center" split={<Divider type="vertical" />}>
            <div style={{ width: 65 }}>
              <Typography.Text> Opacity </Typography.Text>
            </div>
            <div style={{ width: 120 }}>
              <Slider
                min={0}
                max={100}
                key={selectedLayer?.opacity}
                onAfterChange={(value: number) => {
                  updateOpacity(value / 100);
                }}
                defaultValue={Math.trunc(selectedLayer?.opacity * 100)}
              />
            </div>
          </Space>
        </Box>
        <Box>
          <SizePositionToolbox
            selected={props.selected}
            selectedLayer={selectedLayer}
            updateGraphic={updateTextGraphic}
            currentGraphic={currentGraphic}
            currentGraphicIndex={selectedGraphicIndex}
            updateLocal={updateLocal}
          />
        </Box>
        <Box>{editableByType(selectedLayer?.type)}</Box>
      </Space>
    </Scrollable>
  );

  function editableByType(type: string) {
    switch (type) {
      case "dropdown":
        return (
          <DropdownToolbox
            updateGraphic={updateTextGraphic}
            setSelectedGraphic={props.setSelectedGraphic}
            currentGraphic={currentGraphic}
            selectedTool={props.selectedTool}
            updateToolType={updateToolType}
            selectedLayer={selectedLayer}
            currentGraphicIndex={selectedGraphicIndex}
          />
        );
      case "textbox":
        return (
          <TextboxToolbox
            updateGraphic={updateTextGraphic}
            selected={props.selected}
            opacity={selectedLayer?.opacity}
            isTemplate={props.isTemplate}
          />
        );
      case "image_upload":
        return (
          <>
            <Typography.Title level={5}> Edit Type </Typography.Title>
            <Radio.Group
              onChange={(e) => {
                updateToolType(e.target.value);
              }}
              value={props.selectedTool}
            >
              <Radio.Button value={ToolType.mask}>Edit Mask</Radio.Button>
              <Radio.Button value={ToolType.selectLayer}>
                Edit Image
              </Radio.Button>
            </Radio.Group>
            <div>
              {props.selectedTool === ToolType.selectLayer ? (
                <Space direction="vertical" align="center" size="middle">
                  <Tooltip color="#1f1f1f" title="Center Image">
                    <Button
                      size="large"
                      style={{ backgroundColor: "#141414", marginTop: 20 }}
                      onClick={(e) => {
                        e.currentTarget.blur();
                        const copyLayer = Object.assign({}, selectedLayer);
                        copyLayer.graphic = [];
                        const graphic = Object.assign(
                          {},
                          copyLayer.graphics[0]
                        );
                        copyLayer.graphic[0] = graphic;
                        let { width, height, x, y } = contain(
                          copyLayer.mask.width,
                          copyLayer.mask.height,
                          graphic.srcWidth,
                          graphic.srcHeight
                        );
                        graphic.x = x + copyLayer.mask.x;
                        graphic.y = y + copyLayer.mask.y;
                        graphic.width = width;
                        graphic.height = height;
                        updateTextGraphic(graphic, 0);
                        // }
                      }}
                      icon={<ExpandOutlined />}
                    ></Button>
                  </Tooltip>

                  <SliderContainer>
                    <Title level={5}>Zoom</Title>
                    <Slider
                      key={selectedLayer.id}
                      defaultValue={100}
                      min={10}
                      max={300}
                      onChange={(val: number) => {
                        if (selectedLayer !== "") {
                          const graphic = Object.assign(
                            {},
                            selectedLayer.graphics[0]
                          );

                          const xpoint = graphic.width / 2 + graphic.x;
                          const ypoint = graphic.height / 2 + graphic.y;
                          graphic.width = graphic.srcWidth * (val / 100);
                          graphic.height = graphic.srcHeight * (val / 100);
                          graphic.x = xpoint - graphic.width / 2;
                          graphic.y = ypoint - graphic.height / 2;
                          updateLocal(graphic, selectedLayer.id);
                        }
                      }}
                      onAfterChange={() => {
                        const graphic = Object.assign(
                          {},
                          selectedLayer.graphics[0]
                        );
                        updateTextGraphic(graphic, 0);
                      }}
                    />
                  </SliderContainer>

                  <SliderContainer>
                    <Title level={5}>Rotate</Title>
                    <Slider
                      min={0}
                      max={360}
                      key={selectedLayer.graphics[0].id + "ls"}
                      onAfterChange={(value: number) => {
                        const graphic = Object.assign(
                          {},
                          selectedLayer.graphics[0]
                        );
                        graphic.rotate = value;
                        updateLocal(graphic, selectedLayer.id);
                      }}
                      onChange={(value: number) => {
                        const graphic = Object.assign(
                          {},
                          selectedLayer.graphics[0]
                        );
                        graphic.rotate = value;
                        updateLocal(graphic, selectedLayer.id);
                      }}
                      defaultValue={selectedLayer.graphics[0].rotate}
                    />
                  </SliderContainer>
                </Space>
              ) : (
                <></>
              )}
            </div>
            <div>
              {props.selectedTool === ToolType.mask ? (
                <Space
                  direction="vertical"
                  align="center"
                  size="middle"
                  style={{ marginTop: 15 }}
                >
                  <Radio.Group
                    onChange={(e) => {
                      updateMaskType(e.target.value);
                    }}
                    value={selectedLayer.mask.type}
                  >
                    <Typography.Title level={5}> Mask Type </Typography.Title>
                    <Radio.Button value={ShapeType.rectangle}>
                      Rectangle
                    </Radio.Button>
                    <Radio.Button value={ShapeType.circle}>Circle</Radio.Button>
                  </Radio.Group>
                  {/* <SliderContainer>
                    <Title level={5}>Rotate</Title>
                    <Slider
                      min={0}
                      max={360}
                      key={selectedLayer.graphics[0].id + "ls"}
                      onAfterChange={(value: number) => {
                        const mask = Object.assign({}, selectedLayer.mask);
                        mask.rotate = value;
                        updateMaskRotation(value);
                      }}
                      onChange={(value: number) => {
                        const mask = Object.assign({}, selectedLayer.mask);
                        mask.rotate = value;
                        updateMaskLocal(mask, selectedLayer.id);
                      }}
                      defaultValue={selectedLayer.mask.rotate}
                    />
                  </SliderContainer> */}
                </Space>
              ) : (
                <></>
              )}
            </div>
            <Divider></Divider>

            <label>
              <input
                type="file"
                id="fileuploadImageUpload"
                style={{ display: "none" }}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.target.files?.item(0) !== null) {
                    const file = e.target.files?.item(0);
                    uploadFile(file!, FileType.image).then((url) => {
                      addImageToImageUpload(file!, url);
                      e.target.value = "";
                    });
                  }
                }}
                accept=".jpg, .jpeg, .png"
              />

              <Button
                type="primary"
                icon={<UploadOutlined />}
                onClick={(e) => {
                  e.currentTarget.blur();
                  document.getElementById("fileuploadImageUpload")?.click();
                }}
              >
                Upload New Photo
              </Button>
            </label>
          </>
        );
      default:
        return <></>;
    }
  }

  /*
   * Update Utilities to Redux
   */

  function updateTextGraphic(graphic: Graphic, index: number) {
    const newGraphicsList = [...selectedLayer.graphics];
    newGraphicsList[index] = { ...graphic };
    dispatch(updateLayer([id, selectedLayer.id, "graphics", newGraphicsList]));
  }

  function updateMaskType(value: ShapeType) {
    const mask = { ...selectedLayer.mask };
    mask.type = value;
    dispatch(updateLayer([id, selectedLayer.id, "mask", mask]));
  }

  function updateToolType(value: ToolType) {
    const mask = { ...selectedLayer.mask };
    mask.currentTool = value;
    dispatch(updateLayer([id, selectedLayer.id, "mask", mask]));
    props.setToolType(value);
  }

  function updateBlendMode(value: string) {
    dispatch(updateLayer([id, selectedLayer.id, "blendMode", value]));
  }

  function updateOpacity(value: number) {
    dispatch(updateLayer([id, selectedLayer.id, "opacity", value]));
  }

  function updateMaskRotation(value: number) {
    const mask = { ...selectedLayer.mask };
    mask.rotate = value;
    dispatch(updateLayer([id, selectedLayer.id, "mask", mask]));
  }

  function addImageToImageUpload(file: File, data: ImageData) {
    const child = new ImageFile(
      file,
      selectedLayer.mask.x,
      selectedLayer.mask.y,
      true
    );
    Object.assign(child, {
      ...data,
      srcWidth: data.width,
      srcHeight: data.height,
    });
    let { width, height, x, y } = contain(
      selectedLayer.mask.width,
      selectedLayer.mask.height,
      child.srcWidth,
      child.srcHeight
    );
    child.x = x + selectedLayer.mask.x;
    child.y = y + selectedLayer.mask.y;
    child.width = width;
    child.height = height;
    const newGraphics = [child.toJSON()];
    props.setSelectedGraphic(child.id);
    dispatch(updateLayer([id, selectedLayer.id, "graphics", newGraphics]));
  }
  function updateLocal(textData: any, id: string) {
    dispatch(
      updateLayerLocal({
        id: id,
        propertyName: "graphics",
        propertyValue: [textData],
      })
    );
  }

  function updateMaskLocal(textData: any, id: string) {
    dispatch(
      updateLayerLocal({
        id: id,
        propertyName: "mask",
        propertyValue: textData,
      })
    );
  }

  function contain(
    parentWidth: number,
    parentHeight: number,
    childWidth: number,
    childHeight: number
  ) {
    const doRatio = childWidth / childHeight;
    const cRatio = parentWidth / parentHeight;
    let width = parentWidth;
    let height = parentHeight;

    if (doRatio > cRatio) {
      height = width / doRatio;
    } else {
      width = height * doRatio;
    }

    return {
      width,
      height,
      x: (parentWidth - width) / 2,
      y: (parentHeight - height) / 2,
    };
  }
}

export default RightSidebar;
