import React, { Component, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import {
  Button,
  Tooltip,
  Card,
  Space,
  Divider,
  message,
  Typography,
  Drawer,
  Checkbox,
  Modal,
  Input,
} from "antd";
import {
  UserOutlined,
  CopyOutlined,
  KeyOutlined,
  ReloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { deleteUser, updateUserMultiParams } from "../../redux/userSlice";
import { User } from "../../core/User";
import copy from "copy-to-clipboard";

export default function EditAccount(props: { account: User }) {
  const [showEdit, setShotEdit] = useState(false);
  const [password, setPassword] = useState(props.account.password);
  const [username, setUsername] = useState(props.account.username);
  const [notes, setNotes] = useState(props.account.notes);
  const [showPass, setShowPass] = useState(false);

  const dispatch = useAppDispatch();

  const handleOk = () => {
    dispatch(
      updateUserMultiParams([
        props.account.id,
        ["username", "password", "notes"],
        [username, password, notes],
      ])
    );
    setShotEdit(false);
  };

  const handleCancel = () => {
    setShotEdit(false);
  };
  return (
    <>
      <Modal visible={showEdit} onOk={handleOk} onCancel={handleCancel}>
        <Typography.Title level={5}>Account Name</Typography.Title>
        <Input
          key={props.account.id + "_um"}
          value={username}
          placeholder="Account Name"
          addonBefore={<UserOutlined />}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <Typography.Title level={5}>Password</Typography.Title>
        <Input
          key={props.account.id + "_pw"}
          value={password}
          placeholder="Password"
          type={showPass ? "text" : "password"}
          addonBefore={<KeyOutlined />}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          addonAfter={
            <Space size="middle">
              <Tooltip title="Copy Password">
                <CopyOutlined
                  onClick={() => {
                    copy(password);
                    message.success("Password Copied to Clipboard");
                  }}
                />
              </Tooltip>
              <Tooltip title="Show/Hide Password">
                <EyeOutlined
                  onClick={() => {
                    setShowPass(!showPass);
                  }}
                />
              </Tooltip>
              <Tooltip title="Generate New Password">
                <ReloadOutlined
                  onClick={() => {
                    setPassword(generatePassword());
                  }}
                />
              </Tooltip>
            </Space>
          }
        />
        <Typography.Title level={5}>Account Notes</Typography.Title>
        <Input.TextArea
          key={props.account.id + "_note"}
          value={notes}
          placeholder="Notes go here"
          rows={4}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
      </Modal>
      <Button
        type="primary"
        onClick={(e) => {
          e.preventDefault();
          e.currentTarget.blur();
          setShotEdit(true);
        }}
      >
        Edit Account
      </Button>
    </>
  );
}
function generatePassword() {
  return Array(10)
    .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
    .map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join("");
}
