import "simplebar";
import "simplebar/dist/simplebar.css";
import styled from "styled-components";
import { Layout, Tooltip } from "antd";
import {
  AppstoreOutlined,
  OneToOneOutlined,
  BankOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Accounts from "./Accounts";
import { Link, useLocation } from "react-router-dom";
import { Tutorials } from "./Tutorials";
import { Templates } from "./Templates";
import { DashboardList } from "./DashboardList";
import DashboardHeader from "./DashboardHeader";
import { isAdmin } from "../../utils/isAdmin";
const { Content } = Layout;

const GroupContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: center;
  gap: 70px;
  padding: 50px;
`;

const Scrollable = styled.div`
  width: 100%;
  height: calc(100vh - 190px);
`;

const SubMenuContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  gap: 80px;
`;
const IconContainer = styled.div`
  color: #4e4e4e;
  font-size: 30px;
  text-align: center;
`;
const IconTitle = styled.div`
  font-size: 12px;
  text-align: center;
`;
const HeadlineHolder = styled.div`
  width: 100%;
  text-align: center;
  margin: -25px 0px 0px 0px;
  padding: 0px 20%;
`;
const Headline = styled.div`
  color: white;
  font-size: 24px;
  font-weight: 600;
`;

const SubHeadline = styled.div`
  color: #a7a7a7;
  font-size: 20px;
`;

const SubSubHeadline = styled.div`
  color: #a7a7a7;
  font-size: 20px;
`;

export default function Dashboard() {
  const location = useLocation();
  const currentUserIsAdmin = isAdmin();

  return (
    <Layout>
      <DashboardHeader />
      <Layout>
        <Content>
          <SubMenuContainer>
            <Link to="/dashboard">
              <IconContainer
                className={location.pathname === "/dashboard" ? "white" : ""}
              >
                <AppstoreOutlined />
                <IconTitle>Dashboard</IconTitle>
              </IconContainer>
            </Link>
            {currentUserIsAdmin ? (
              <Link to="/templates">
                <IconContainer
                  className={location.pathname === "/templates" ? "white" : ""}
                >
                  <OneToOneOutlined />
                  <IconTitle>Starter Layouts</IconTitle>
                </IconContainer>
              </Link>
            ) : (
              <Tooltip title="Coming Soon!">
                <IconContainer
                  className={location.pathname === "/templates" ? "white" : ""}
                >
                  <OneToOneOutlined />
                  <IconTitle>Starter Layouts</IconTitle>
                </IconContainer>
              </Tooltip>
            )}

            <Link to="/tutorials">
              <IconContainer
                className={location.pathname === "/tutorials" ? "white" : ""}
              >
                <BankOutlined />
                <IconTitle>Tutorials</IconTitle>
              </IconContainer>
            </Link>
            <Link to="/accounts">
              <IconContainer
                className={location.pathname === "/accounts" ? "white" : ""}
              >
                <UserOutlined />
                <IconTitle>Accounts</IconTitle>
              </IconContainer>
            </Link>
          </SubMenuContainer>
          <Scrollable data-simplebar>
            <GroupContainer>
              {location.pathname === "/dashboard" ? (
                <>
                  <HeadlineHolder>
                    <Headline>Welcome to your Ignite Dashboard</Headline>
                    <SubHeadline>Tools as Creative as you!</SubHeadline>
                    <SubSubHeadline>
                      If you’re new to Ignite, click on the "Tutorials" tab to
                      get your started.
                    </SubSubHeadline>
                  </HeadlineHolder>
                  <DashboardList />
                </>
              ) : (
                <></>
              )}
              {location.pathname === "/templates" ? (
                <>
                  <HeadlineHolder>
                    <Headline>Let Us Help You Get Started</Headline>
                    <SubHeadline>
                      Choose from our wide selection of templates specifically
                      curated for your team's needs.
                    </SubHeadline>
                  </HeadlineHolder>
                  <Templates />
                </>
              ) : (
                <></>
              )}
              {location.pathname === "/tutorials" ? (
                <>
                  <HeadlineHolder>
                    <Headline>Become an Ignite Expert</Headline>
                    <SubHeadline>
                      Dig deeper into how to optimize Team Infographics Ignite
                      for your social media team.
                    </SubHeadline>
                  </HeadlineHolder>
                  <Tutorials />
                </>
              ) : (
                <></>
              )}
              {location.pathname === "/accounts" ? <Accounts /> : <></>}
            </GroupContainer>
          </Scrollable>
        </Content>
      </Layout>
    </Layout>
  );
}
