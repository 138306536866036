import Graphic from "./Graphic";

class Image extends Graphic {
  src: ArrayBuffer | null;
  url: string = "";
  type: string = "image";
  srcWidth: number = 0;
  srcHeight: number = 0;
  withMask: boolean = false;
  constructor(
    value: string = "urlhere",
    x: number = 0,
    y: number = 0,
    width: number = 100,
    height: number = 20
  ) {
    super();
    this.value = value;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.src = null;
  }

  async load() {
    const buffer = await (await fetch(this.value)).arrayBuffer();
    this.src = buffer;
  }
}

export default Image;

export class ImageBuffer extends Image {
  constructor(src: ArrayBuffer, x: number = 0, y: number = 0) {
    super();
    this.src = src;
    this.x = x;
    this.y = y;
  }
}

export class ImageFile extends Image {
  constructor(
    file: File,
    x: number = 0,
    y: number = 0,
    visible: boolean = false
  ) {
    super();
    // var reader = new FileReader();
    // reader.onload = (e) => {
    //   this.src = reader.result as ArrayBuffer;
    // };
    // reader.readAsArrayBuffer(file);
    this.x = x;
    this.y = y;
    this.visible = visible;
    this.name = file.name;
  }
}
