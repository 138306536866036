import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { Document, IDocument } from "../core/Document/Document";
import { displayError, getTokenAndId, handleResponseErrors } from "./helpers";

type TemplateState = {
  [index: string]: IDocument;
};
type TemplateEntry = {
  name: string;
  width: number;
  height: number;
};

const initialState: TemplateState = {};

var baseUrl = "/.netlify/functions/";

/*
 * Async
 */

export const addTemplate = createAsyncThunk<any, TemplateEntry>(
  "templates/addTemplate",
  async (docToAdd, thunkApi) => {
    const userData = await getTokenAndId();

    const newDoc = new Document(docToAdd.name, docToAdd.width, docToAdd.height);

    const data = {
      ...newDoc.toJSON(),
      accountId: userData.id,
      created: Date.now().toString(),
      updated: Date.now().toString(),
    };

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "template-create", requestOptions);

    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

export const getTemplates = createAsyncThunk<any>(
  "templates/getTemplates",
  async (data, thunkApi) => {
    const userData = await getTokenAndId();

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    const response = await fetch(baseUrl + "template-read", requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

export const deleteTemplate = createAsyncThunk<any, string>(
  "templates/deleteTemplate",
  async (deleteId, thunkApi) => {
    const userData = await getTokenAndId();

    const data = {
      id: deleteId,
      accountId: userData.id,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "template-delete", requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return data.id;
  }
);

export const updateTemplate = createAsyncThunk<
  any,
  [string, keyof IDocument, any]
>(
  "templates/updateTemplate",
  async ([docId, propertyName, propertyValue], thunkApi) => {
    const userData = await getTokenAndId();

    const data = {
      id: docId,
      accountId: userData.id,
      propertyName,
      propertyValue,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "template-update", requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return data;
  }
);

export const updateTemplateMultiParams = createAsyncThunk<
  any,
  [string, Array<keyof IDocument>, Array<any>]
>(
  "layers/updateTemplateMultiParams",
  async ([docId, propertyName, propertyValue], thunkApi) => {
    const userData = await getTokenAndId();

    const data = {
      id: docId,
      propertyName,
      propertyValue,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");

    var raw: string = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    const response = await fetch(baseUrl + "template-update", requestOptions);
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return data;
  }
);

/*
 * User Queries
 */

export const getUserTemplates = createAsyncThunk<any, [string, string]>(
  "templates/getUserTemplates",
  async ([accountId, userId], thunkApi) => {
    const userData = await getTokenAndId();

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userData.token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    const response = await fetch(
      baseUrl + "user-get-documents?id=" + accountId + "&userid=" + userId,
      requestOptions
    );
    if (response.status !== 200) {
      return await handleResponseErrors(thunkApi, response);
    }
    return await response.json();
  }
);

/*
 * Reducer
 */
const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    updateTemplateLocal(state, action) {
      const key = action.payload.propertyName;
      const value = action.payload.propertyValue;
      Object.assign(state[action.payload.id], { [key]: value });
    },
    updateTemplateMultiParamsLocal(state, { payload }) {
      payload.propertyName.map((name: string, i: number) => {
        Object.assign(state[payload.id], {
          [name]: payload.propertyValue[i],
        });
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addTemplate.fulfilled, (state, { payload }) => {
      state[payload.id] = payload;
    });
    builder.addCase(getTemplates.fulfilled, (state, { payload }) => {
      payload.map((document: any) => {
        state[document.id] = document;
      });
      state = payload;
    });
    builder.addCase(deleteTemplate.fulfilled, (state, { payload }) => {
      delete state[payload];
    });
    builder.addCase(updateTemplate.fulfilled, (state, action) => {
      const key = action.payload.propertyName;
      const value = action.payload.propertyValue;
      Object.assign(state[action.payload.id], { [key]: value });
    });
    builder.addCase(
      updateTemplateMultiParams.fulfilled,
      (state, { payload }) => {
        payload.propertyName.map((name: string, i: number) => {
          Object.assign(state[payload.id], {
            [name]: payload.propertyValue[i],
          });
        });
      }
    );
    builder.addCase(getUserTemplates.fulfilled, (state, { payload }) => {
      payload.map((document: any) => {
        state[document.id] = document;
      });
      state = payload;
    });

    /*
     * Rejected
     */
    builder.addCase(getTemplates.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(addTemplate.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(deleteTemplate.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(updateTemplate.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(updateTemplateMultiParams.rejected, (state, action) => {
      displayError(action.payload as string);
    });
    builder.addCase(getUserTemplates.rejected, (state, action) => {
      displayError(action.payload as string);
    });
  },
});

/*
 * Selectors
 */

//Select all?
const selectTemplates = (state: any) => state.templates;
export const selectAllTemplates = createSelector(selectTemplates, (entities) =>
  Object.values(entities)
);

export const selectTemplateById = createSelector(
  [(state) => state.templates, (state: any, docId: string) => docId],
  (items, docId) => {
    return items[docId];
  }
);

/*
 * Exports
 */

// export const { addTemplate } = documentsSlice.actions;
export default templatesSlice.reducer;
