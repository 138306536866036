import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";

import { SortableItem } from "./SortableItem";

export function SortableList(props) {
  const sensors = useSensors(useSensor(PointerSensor));
  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEndHeader}
      >
        <SortableContext
          items={props.data}
          strategy={verticalListSortingStrategy}
        >
          {props.data.map((dataRow, i) => {
            return (
              <SortableItem
                key={dataRow.id}
                id={dataRow.id}
                index={i}
                title={dataRow.name}
                selected={props.selected}
                setSelected={props.setSelected}
                visible={dataRow.visible}
                setVisible={props.setVisible}
                setTitle={props.setTitle}
                deleteLayer={props.deleteLayer}
                type={dataRow.type}
                userMode={dataRow.userMode}
                setUserMode={props.setUserMode}
                eyeOff={props.eyeOff}
                iconOff={props.iconOff}
              ></SortableItem>
            );
          })}
        </SortableContext>
      </DndContext>
    </>
  );

  function handleDragEndHeader(event) {
    const { active, over } = event;
    if (over.id !== null) {
      if (active.id !== over.id) {
        const oldIndex = props.data.findIndex((item) => item.id === active.id);
        const newIndex = props.data.findIndex((item) => item.id === over.id);

        const newData = arrayMove(props.data, oldIndex, newIndex);
        props.setData(newData);
      }
    }
  }
}
