import { Link, useLocation } from "react-router-dom";
import { Layout, Button, Space, Dropdown, Menu, Avatar, Result } from "antd";
import styled from "styled-components";
import netlifyIdentity from "netlify-identity-widget";

const { Header, Content } = Layout;

const HeaderLine = styled(Header)`
  border-bottom: 1px #ff0200 solid;
`;

const Logo = styled.div`
  float: left;
  width: 160px;
  height: 64px;
  background-image: url("/img/IGNITE_branding.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;
const GroupContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: center;
  gap: 70px;
  padding: 50px;
`;

const Scrollable = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
`;

export default function Success() {
  const { search } = useLocation() as any;
  netlifyIdentity.refresh(true);
  return (
    <>
      <Layout>
        <HeaderLine>
          <Link to="/dashboard">
            <Logo />
          </Link>
        </HeaderLine>
        <Layout>
          <Content>
            <Scrollable data-simplebar>
              <GroupContainer>
                <Result
                  status="success"
                  title="Thank you for your subscribing!"
                  subTitle={`Session id: ${new URLSearchParams(search).get(
                    "session_id"
                  )}. `}
                  extra={[
                    <Button type="primary" key="console">
                      <Link to="/dashboard">Go To Dashboard</Link>
                    </Button>,
                  ]}
                />
              </GroupContainer>
            </Scrollable>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
