import { useEffect, useState, useCallback } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getLayers, selectLayersById, undoRedo } from "../../redux/layerSlice";
import { useParams } from "react-router-dom";
import { Button, Input, Typography, InputNumber, Modal, Tooltip } from "antd";
import { ILayer } from "../../core/Layer/Layer";
import {
  selectDocumentById,
  updateDocument,
  getDocuments,
} from "../../redux/documentSlice";
import { GraphicsEngine, Font } from "../../core/GraphicsEngine";
import CanvasEvent, { ToolType } from "../../core/Event/CanvasEvent";
import { CanvasKit } from "canvaskit-wasm";
import { debounce } from "../../utils/debounce";
import { FileType, uploadScreenshotToS3 } from "../../utils/uploadFile";
import {
  getTemplates,
  selectTemplateById,
  updateTemplate,
} from "../../redux/templateSlice";
import {
  PlusOutlined,
  MinusOutlined,
  ExpandOutlined,
  QuestionOutlined,
  UndoOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import fonts from "../../fonts.json";
import styled from "styled-components";

const { info } = Modal;

type TypeID = {
  id: string;
};
export type CanvasState = {
  width: number;
  height: number;
  scale: number;
  artboardX: number;
  artboardY: number;
  selectedLayer: string;
  selectedGraphic: string;
  selectedTool: ToolType;
};

const canvasEvent: CanvasEvent = new CanvasEvent();
let canvasState: CanvasState = {
  width: 0,
  height: 0,
  scale: 1,
  artboardX: 0,
  artboardY: 0,
  selectedLayer: "",
  selectedGraphic: "",
  selectedTool: ToolType.selectLayer,
};

type Props = {
  ck: CanvasKit;
  selectedLayer: string;
  selectedGraphic: string;
  selectedTool: ToolType;
  toPng: Function;
  isTemplate?: boolean;
};

const Spacer = styled.span`
  width: 15px;
  height: 10px;
`;

const HelpButton = styled.div`
  position: fixed;
  z-index: 10;
  margin-top: -45px;
  right: 325px;
  bacground-color: black;
`;

let ge: GraphicsEngine | null = null;
let layers: ILayer[] = [];
export default function Canvas(props: Props) {
  const { id } = useParams() as TypeID;

  const [scale, setScale] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth - 600);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 66);

  const resizeWindowDebounced = useCallback(debounce(resizeWindow, 77), []);
  const dispatch = useAppDispatch();
  const selectedDocument = useAppSelector((state) => {
    if (props.isTemplate) {
      return selectTemplateById(state, id);
    } else {
      return selectDocumentById(state, id);
    }
  });
  const selectedLayers = useAppSelector((state) => {
    return selectLayersById(
      state,
      selectedDocument !== undefined ? selectedDocument.layers : []
    );
  });
  useEffect(() => {
    canvasState.selectedLayer = props.selectedLayer;
    render();
  }, [props.selectedLayer]);

  useEffect(() => {
    if (scale !== canvasState.scale) {
      zoomCenter(scale, canvasState.scale);
      render();
    }
  }, [scale]);

  useEffect(() => {
    canvasState.selectedGraphic = props.selectedGraphic;
    render();
  }, [props.selectedGraphic]);

  useEffect(() => {
    canvasState.selectedTool = props.selectedTool;
    render();
  }, [props.selectedTool]);

  const screenshotDebounced = useCallback(debounce(uploadScreenshot, 5000), [
    id,
  ]);
  /*
   * Use Effect
   */
  useEffect(() => {
    if (selectedLayers.length > 0) {
      layers = JSON.parse(JSON.stringify(selectedLayers));
      render();
      props.toPng(exportToPng);
    }
  }, [selectedLayers]);
  useEffect(() => {
    if (selectedDocument !== undefined) {
      if (
        canvasState.width !== selectedDocument.width ||
        canvasState.height !== selectedDocument.height
      ) {
        canvasState.width = selectedDocument.width;
        canvasState.height = selectedDocument.height;
        centerArtboard();
      }
      screenshotDebounced(layers, canvasState);
      loadFonts();
      render();
    }
  }, [selectedDocument]);

  useEffect(() => {
    dispatch(getLayers(id));
    ge = new GraphicsEngine(props.ck, fonts, "mainCanvas");
    if (selectedDocument === undefined) {
      if (props.isTemplate) {
        dispatch(getTemplates());
      } else {
        dispatch(getDocuments());
      }
    } else {
      loadFonts();
      centerArtboard();
      render();
    }
    addListeners();
    return removeListeners;
  }, []);

  return (
    <>
      <canvas id="mainCanvas" width={windowWidth} height={windowHeight} />
      <canvas
        id="canvasBG"
        style={{ position: "absolute", display: "none" }}
        width={selectedDocument ? selectedDocument.width * 0.25 : 0}
        height={selectedDocument ? selectedDocument.height * 0.25 : 0}
      />
      <canvas
        id="canvasFull"
        width={selectedDocument ? selectedDocument.width : 0}
        height={selectedDocument ? selectedDocument.height : 0}
        style={{ display: "none" }}
      />
      <Input.Group
        compact
        style={{
          position: "absolute",
          zIndex: 100,
          width: "fit-content",
          margin: "-45px 0px 0px 20px",
        }}
      >
        <Tooltip title="Zoom Canvas">
          <Button
            style={{
              background: "black",
            }}
            onClick={(e) => {
              e.currentTarget.blur();
              setScale(scale / 1.1);
            }}
            icon={<MinusOutlined />}
          />
          <InputNumber
            className="zoom-number"
            style={{
              width: 100,
              textAlign: "center",
              background: "black",
            }}
            value={Math.trunc(scale * 100)}
            formatter={(value) => `${value}%`}
            onChange={(value) => {
              let val = value / 100;
              if (isNaN(val)) {
                val = 1;
              }
              setScale(val);
            }}
          />
          <Button
            style={{
              background: "black",
            }}
            onClick={(e) => {
              e.currentTarget.blur();
              setScale(scale * 1.1);
            }}
            icon={<PlusOutlined />}
          />
        </Tooltip>
        <Spacer />

        <Tooltip title="Center Canvas">
          <Button
            style={{
              background: "black",
            }}
            onClick={(e) => {
              e.currentTarget.blur();
              //Center
              centerArtboard();
              render();
            }}
            icon={<ExpandOutlined />}
          />
        </Tooltip>
        <Spacer />
        <Tooltip title="Undo">
          <Button
            style={{
              background: "black",
            }}
            onClick={(e) => {
              e.currentTarget.blur();
              dispatch(undoRedo("undo"));
            }}
            icon={<UndoOutlined />}
          />
        </Tooltip>
        <Tooltip title="Redo">
          <Button
            style={{
              background: "black",
            }}
            onClick={(e) => {
              e.currentTarget.blur();
              dispatch(undoRedo("redo"));
            }}
            icon={<RedoOutlined />}
          />
        </Tooltip>
      </Input.Group>
      <HelpButton>
        <Tooltip title="Need Help?">
          <Button
            onClick={(e) => {
              e.currentTarget.blur();
              info({
                title: (
                  <Typography.Title level={3}>Need Help?</Typography.Title>
                ),
                content: (
                  <Typography.Title level={5}>
                    Contact us at:{" "}
                    <a href="mailto:helpdesk@teaminfographics.com">
                      helpdesk@teaminfographics.com
                    </a>
                  </Typography.Title>
                ),
                onOk() {},
                onCancel() {},
              });
            }}
            icon={<QuestionOutlined />}
          />
        </Tooltip>
      </HelpButton>
    </>
  );

  function addListeners() {
    const canvas = document.getElementById("mainCanvas");
    canvas?.addEventListener("pointermove", canvasMouseInteraction);
    canvas?.addEventListener("pointerdown", canvasMouseInteraction);
    canvas?.addEventListener("pointerup", canvasMouseInteraction);
    canvas?.addEventListener("pointerleave", canvasMouseInteraction);
    canvas?.addEventListener("pointerenter", canvasMouseInteraction);
    window.addEventListener("keydown", canvasKeyboardInteraction, false);
    window.addEventListener("keyup", canvasKeyboardInteraction, false);
    window.addEventListener("resize", resizeWindowDebounced);
    canvas?.addEventListener("wheel", zoomAtScroll, false);

    document.addEventListener("keydown", undo);
    document.addEventListener("keydown", redo);
  }

  function removeListeners() {
    const canvas = document.getElementById("mainCanvas");
    canvas?.removeEventListener("pointermove", canvasMouseInteraction);
    canvas?.removeEventListener("pointerdown", canvasMouseInteraction);
    canvas?.removeEventListener("pointerup", canvasMouseInteraction);
    canvas?.removeEventListener("pointerleave", canvasMouseInteraction);
    canvas?.removeEventListener("pointerenter", canvasMouseInteraction);
    window.removeEventListener("keydown", canvasKeyboardInteraction, false);
    window.removeEventListener("keyup", canvasKeyboardInteraction, false);
    window.removeEventListener("resize", resizeWindowDebounced);
    canvas?.addEventListener("wheel", zoomAtScroll, false);

    document.removeEventListener("keydown", undo);
    document.removeEventListener("keydown", redo);
    ge?.delete();
    ge = null;
    layers = [];
    //Cancel Screenshot
    screenshotDebounced(layers, canvasState, true);
  }

  function canvasMouseInteraction(e: PointerEvent) {
    let type = e.type;
    const rerender = canvasEvent.newMouseEvent(
      type,
      e.offsetX,
      e.offsetY,
      e.pressure,
      layers,
      canvasState.scale,
      [canvasState.artboardX, canvasState.artboardY],
      canvasState.selectedTool,
      canvasState.selectedLayer,
      canvasState.selectedGraphic,
      (newX: number, newY: number) => {
        canvasState.artboardX = newX;
        canvasState.artboardY = newY;
      }
    );
    if (rerender) {
      render();
    }
  }
  function canvasKeyboardInteraction(e: KeyboardEvent) {
    let type = e.type;
    canvasEvent.newKeyboardEvent(type, e.key, e);
  }
  function render() {
    ge?.renderLayers(layers, canvasState);
    screenshotDebounced(layers, canvasState);
  }
  function resizeWindow() {
    setWindowWidth(window.innerWidth - 600);
    setWindowHeight(window.innerHeight - 66);
    if (ge === null) {
      return;
    }
    ge.resize();
    render();
  }
  function centerArtboard() {
    const spaceW = window.innerWidth - 670;
    const spaceH = window.innerHeight - 150;
    const scale = Math.min(
      spaceW / selectedDocument.width,
      spaceH / selectedDocument.height
    );
    const trueWidth = windowWidth / scale;
    const trueHeight = windowHeight / scale;
    canvasState.artboardX = (trueWidth - canvasState.width) / 2;
    canvasState.artboardY = (trueHeight - canvasState.height) / 2;
    canvasState.scale = scale;

    setScale(scale);
  }

  function zoomCenter(scale: number, OldScale: number) {
    const trueWidth = windowWidth / OldScale;
    const trueHeight = windowHeight / OldScale;

    const trueWidthNew = windowWidth / scale;
    const trueHeightNew = windowHeight / scale;

    const newArtboardX = canvasState.artboardX - (trueWidth - trueWidthNew) / 2;
    const newArtboardY =
      canvasState.artboardY - (trueHeight - trueHeightNew) / 2;

    canvasState.artboardX = newArtboardX;
    canvasState.artboardY = newArtboardY;
    canvasState.scale = scale;
  }

  function zoomAtScroll(event: WheelEvent) {
    if (event.altKey) {
      const speed = 1.05;
      if (event.deltaY < 0) {
        zoomCenter(canvasState.scale * speed, canvasState.scale);
        setScale(canvasState.scale * speed);
      }
      if (event.deltaY > 0) {
        zoomCenter(canvasState.scale / speed, canvasState.scale);
        setScale(canvasState.scale / speed);
      }
    }
  }

  function exportToPng() {
    return ge?.exportToPNG(selectedLayers, canvasState);
  }

  async function uploadScreenshot(
    layerList: ILayer[],
    canvasState: CanvasState,
    cancel?: boolean
  ) {
    if (cancel || id === undefined) {
      return;
    }
    const imgBuff = ge?.exportToPNG(layerList, canvasState, true);
    if (imgBuff) {
      const blob = new Blob([imgBuff!], { type: "image/png" });
      const file = new File([blob], "screenshot.png");
      await uploadScreenshotToS3(file, FileType.image, id);
      if (props.isTemplate) {
        dispatch(updateTemplate([id, "screenshotTimestamp", Date.now()]));
      } else {
        dispatch(updateDocument([id, "screenshotTimestamp", Date.now()]));
      }
    }
  }

  /*
   * Undo Redo
   */

  function undo(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === "z") {
      dispatch(undoRedo("undo"));
    }
  }

  function redo(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === "Z") {
      dispatch(undoRedo("redo"));
    }
  }
  function loadFonts() {
    //Check for font Change
    for (var i = selectedDocument.fonts.length - 1; i >= 0; i--) {
      const newFont = selectedDocument.fonts[i];
      ge?.addNewFont(newFont.name, newFont.url);
    }
  }
}
