import React, { Component } from "react";
import { Layer } from "../../core/Layer/Layer";
import { CanvasKit } from "canvaskit-wasm";

import { Layout } from "antd";
import RightSidebar from "./RightSideBar/RightSidebar";
import LeftSidebar from "./LeftSidebar/LeftSidebar";
import { EditableHeader } from "./EditableHeader";
import Canvas from "./Canvas";
import UserMode from "../UserMode";
import { ToolType } from "../../core/Event/CanvasEvent";
import ExportButton from "../Components/ExportButton";
import styled from "styled-components";

const { Content, Sider } = Layout;

type AppState = {
  layerData: Layer[];
  selectedLayer: string;
  selectedGraphic: string;
  selectedTool: ToolType;
  um: boolean;
};

type Props = {
  ck: CanvasKit;
  isTemplate?: boolean;
};
const ButtonsBot = styled.div`
  padding: 10px 30px;
  border-top: 1px solid gray;
  position: fixed;
  width: 300px;
  background-color: #141414;
  bottom: 0;
  height: 54px;
`;

class DesignerMode extends Component<Props, AppState> {
  toPng: Function;
  constructor(props: Props) {
    super(props);
    this.state = {
      layerData: [],
      selectedLayer: "",
      selectedGraphic: "",
      selectedTool: ToolType.selectLayer,
      um: false,
    };
    this.toPng = () => {};
  }
  componentDidMount() {
    if (document === null) {
      return;
    }
  }

  setSelected(id: string) {
    this.setState({
      selectedLayer: id,
    });
  }
  setSelectedGraphic(id: string) {
    this.setState({
      selectedGraphic: id,
    });
  }

  setToolType(newTool: ToolType) {
    this.setState({
      selectedTool: newTool,
    });
  }
  setUm(set: boolean) {
    this.setState({
      um: set,
    });
  }

  render() {
    return (
      <Layout>
        <EditableHeader
          setUm={this.setUm.bind(this)}
          um={this.state.um}
          isTemplate={this.props.isTemplate}
        />
        {this.state.um ? (
          <UserMode ck={this.props.ck} dm={true} />
        ) : (
          <Layout>
            <Sider width={300}>
              <LeftSidebar
                selected={this.state.selectedLayer}
                setSelected={this.setSelected.bind(this)}
                setSelectedGraphic={this.setSelectedGraphic.bind(this)}
                setToolType={this.setToolType.bind(this)}
                isTemplate={this.props.isTemplate}
              />
            </Sider>
            <Layout>
              <Content
                className="site-layout-background"
                style={{
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <Canvas
                  selectedLayer={this.state.selectedLayer}
                  selectedGraphic={this.state.selectedGraphic}
                  selectedTool={this.state.selectedTool}
                  toPng={(newfunc: Function) => {
                    this.toPng = newfunc;
                  }}
                  {...this.props}
                />
              </Content>

              <Sider width={300}>
                {this.state.selectedLayer !== "" ? (
                  <RightSidebar
                    selected={this.state.selectedLayer}
                    selectedTool={this.state.selectedTool}
                    setSelectedGraphic={this.setSelectedGraphic.bind(this)}
                    setToolType={this.setToolType.bind(this)}
                    isTemplate={this.props.isTemplate}
                  />
                ) : (
                  <></>
                )}
                <ButtonsBot>
                  <ExportButton exportToPNG={() => this.toPng()} />
                </ButtonsBot>
              </Sider>
            </Layout>
          </Layout>
        )}
      </Layout>
    );
  }
}

export default DesignerMode;
